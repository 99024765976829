import React, { useEffect, useState, useRef, forwardRef } from 'react'
import {
  Alert, Button, Spinner,
  CardTitle, CardHeader, CardBody, ModalHeader, Modal, ModalBody,
  Nav, NavItem, NavLink,
  Input, Col, Row, Card, ModalFooter, Badge, CardFooter
} from 'reactstrap'
import { Prompt } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom'
import moment from 'moment';
import { Header, Footer } from '../header';
import 'react-block-ui/style.css';
import { push } from 'connected-react-router';
import RialeTimelineViewer from '../components/RialeTimelineViewer';
import { tracks } from '../components/Timeline/FakeItems';
import classnames from 'classnames';
import ReactTooltip from "react-tooltip";
import ReactJson from 'react-json-view'
import { VscJson } from "react-icons/vsc";
import { FaMobileAlt } from "react-icons/fa";
import { BsInfoCircle, BsRecordCircleFill } from "react-icons/bs";
import { BsSunglasses } from "react-icons/bs";
import { RiSlideshow3Line } from "react-icons/ri";
import { CiViewTimeline } from "react-icons/ci";
import { LiaCloneSolid } from "react-icons/lia"
import { FaPause, FaSave } from "react-icons/fa";
import { IconContext } from "react-icons";
import IconButton from '@material-ui/core/IconButton';
import _ from 'lodash'
import { selectors as ExperimentsSelector, actions as ExperimentsActions } from '../store/slices/experiments'
import { selectors as AuthSelectors } from '../store/slices/auth'
import { selectors as PassCodeSelector, actions as PassCodeAction } from '../store/slices/passCodeUsers'
import { selectors as ProfileSelectors } from '../store/slices/profile'
import { Content } from '../components/Content';
import { isValid, RecordingPasscode } from '../utils/passcodeGenerator';
import { useTranslation } from 'react-i18next';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import RialeScreenRecording, { RecordingRequest } from '../components/Timeline/RialeScreenRecording'
import LightTimeline from '../components/Timeline/LightTimeline';
import { useDeviceType } from '../components/Timeline/Utils';
import MobileTimeline from '../components/Timeline/MobileTimeline';
import CookieConsent from 'react-cookie-consent';


const Mailto: React.FC<{ email: string, subject: string, body: string }>
  = ({ email, subject, body, ...props }) => {
    return (
      <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
        {props.children}
      </a>
    );
  }

export const PublicExperiment: React.FC = () => {
  const isLogged = useSelector(AuthSelectors.isLogged)
  const isDirector = useSelector(ProfileSelectors.isDirector);
  const timelineRef = useRef();
  const [recordingRequest, setRecordingRequest] = useState(RecordingRequest.IDLE);
  const [screenRecordingStatus, setScreenRecordingStatus] = useState("idle");
  const [watcherIsPaused, setWatcherIsPaused] = useState(true);
  const isLoggedWithPassCode = useSelector(PassCodeSelector.isLoggedWithPassCode)
  const sessionSavingError = useSelector(PassCodeSelector.getSessionSavingError)
  const currentSessionRecordingPath = useSelector(PassCodeSelector.getCurrentSessionRecordingPath)
  const [isPasscodeModalOpen, setPasscodeModalOpen] = useState(false);
  const [passCode, setPassCode] = useState(null as any);
  const [isSessionViewerModalOpen, setSessionViewerModalOpen] = useState(false);
  const [currentPassCode, setCurrentPassCode] = useState(null as any);

  const [invalidPassCode, setInvalidPassCode] = useState(false);
  const [sessionTrace, setSessionTrace] = useState(null as any);

  const [windowIsActive, setWindowIsActive] = useState(false);
  const [sessionExitConfirmed, setSessionExitConfirmed] = useState(false);
  const [sessionSavingPending, setSessionSavingPending] = useState(false);
  const [sessionSavedWithoutError, setSessionSavedWithoutError] = useState(false);
  const [timelineStart, setTimelineStart] = useState(null) as any;
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  const checkLoginTimeoutRef = useRef() as any;

  const [activeTab, setActiveTab] = useState('0');
  const DEBUG = isDirector;
  //const STAGE: string = process.env.REACT_APP_STAGE != undefined ? process.env.REACT_APP_STAGE : "dev"

  let { id } = useParams() as any;
  const query = new URLSearchParams(window.location.search);
  const startTimeQueryString = query.get("start_time");
  const activeTabQueryString = query.get("active_tab");
  const presentationModeQueryString = query.get("lite");
  const [presentationMode, setPresentationMode] = useState(presentationModeQueryString === "true");
  //console.log("start_time query string:", startTimeQueryString);
  //console.log("params:", id);
  const experiment = useSelector(ExperimentsSelector.getCurrentPublicExperiment);
  const [localizedExperimentTitle, setLocalizedExperimentTitle] = useState("")
  const [localizedExperimentDescription, setLocalizedExperimentDescription] = useState("")

  //console.log("Valore di current experiment:", experiment);
  // ---------------------------------------------
  const dispatch = useDispatch();


  const handleActivity = React.useCallback(
    _.debounce(
      (e: { type: string }) => {

        if (e?.type === 'focus') {
          //console.log(`WINDOW IS FOCUS: ${moment(moment.now()).format("HH:mm:ss")})`, true);
          return setWindowIsActive(true)
        }
        if (e?.type === 'blur') {
          //console.log(`WINDOW IS BLUR: ${moment(moment.now()).format("HH:mm:ss")})`, false);

          //return setWindowIsActive(false)
        }


        if (e?.type == 'visibilitychange') {
          if (document.hidden) {
            //console.log(`WINDOW IS VISIBILITY CHANGE: ${moment(moment.now()).format("HH:mm:ss")})`, false);

            return setWindowIsActive(false)
          } else {
            //console.log(`WINDOW IS VISIBILITY CHANGE: ${moment(moment.now()).format("HH:mm:ss")})`, true);

            return setWindowIsActive(true)
          }
        }
      },
      100,
      { leading: false },
    ),
    [],
  )

  //const isTablet = useIsTablet();
  const deviceType = useDeviceType();

  useEffect(() => {
    console.log("DEVICE TYPE da COMPONENT aggiornato:", deviceType);
  }, [deviceType])



  React.useEffect(() => {
    //console.log("PublicExperiment Page on id:", id);
    dispatch(ExperimentsActions.willGetPublicExperiment(id));
    let startTimeQuery = null;

    try {
      if (startTimeQueryString != null) startTimeQuery = parseInt(startTimeQueryString);
      if (activeTabQueryString != null) setActiveTab(activeTabQueryString);
      if (presentationModeQueryString != null) setPresentationMode(presentationModeQueryString === "true");
    } catch (err) {
      console.error("errore nel parsing della query url:", err);
    }

    if (moment(startTimeQuery).isValid())
      setTimelineStart(moment(startTimeQuery));
    //console.log(`TLStart: passo da ${startTimeQuery} timelinestart:`, timelineStart);
  }, [])


  useEffect(() => {
    //console.log(`WINDOW IS ACTIVE: ${moment(moment.now()).format("HH:mm:ss")})`, windowIsActive);

  }, [windowIsActive, setWindowIsActive])

  React.useEffect(() => {
    if (isLoggedWithPassCode) {
      //window.onbeforeunload = () => ""
      setWindowIsActive(true);
      window.onunload = () => { setWindowIsActive(false) }
    } else {
      //window.onbeforeunload = null
      window.onunload = null;
      if (sessionSavingPending && sessionSavingError == null) {
        setSessionSavedWithoutError(true);
        setRecordingRequest(RecordingRequest.STOP);
      }

      // nel caso risulti che l'alunno si sia sloggato resetto i dati del passcode 
      setInvalidPassCode(false);
      setPassCode(null);

      setCurrentPassCode("");
      setSessionSavingPending(false);
      setSessionExitConfirmed(false);
    }
    return (() => {
      //window.onbeforeunload = null
      window.onunload = null;
    })
  }, [isLoggedWithPassCode])



  useEffect(() => {

    document.addEventListener('visibilitychange', handleActivity)
    document.addEventListener('blur', handleActivity)
    window.addEventListener('blur', handleActivity)
    window.addEventListener('focus', handleActivity)
    document.addEventListener('focus', handleActivity)

    // Verifico di avere qualcosa nello storage e nel caso loggo l'utente tramite passCode

    const sessionTrace = JSON.parse(localStorage.getItem("RialeSessionTrace") as any);
    setSessionTrace(sessionTrace);
    //console.log("LStorage sessionTrace:", sessionTrace);
    const lsPassCode = sessionTrace ? sessionTrace["passCode"] : null;
    //console.log("LStorage passcode:", lsPassCode);
    //console.log("User is Logged:", (isLogged));
    if (lsPassCode != null) {
      // aspetto 2 secondi prima di loggare l'utente per dare il tempo
      // alla piattaforma di capire se l'utente è per caso loggato
      // Il tracciamento infatti deve avvenire in forma anonima senza che
      // ci sia alcun utente loggato
      checkLoginTimeoutRef.current = setTimeout(() => { if (!isLogged) loginWithPassCode(lsPassCode); }, 4000)
    }

    return () => {
      window.removeEventListener('blur', handleActivity)
      document.removeEventListener('blur', handleActivity)
      window.removeEventListener('focus', handleActivity)
      document.removeEventListener('focus', handleActivity)
      window.removeEventListener('visibilitychange', handleActivity)
      document.removeEventListener('visibilitychange', handleActivity)
      clearTimeout(checkLoginTimeoutRef.current);
    }
  }, [])


  useEffect(() => {
    //console.log("User is Logged Effect:", isLogged)
    if (isLogged) { clearTimeout(checkLoginTimeoutRef.current); }
  }, [isLogged])

  // melle timeline pubbliche la visualizzazione in forma di presentazione è solo
  // per le sezioni contrassegnate come isLite
  React.useEffect(() => {
    if (!experiment?.sections[activeTab]?.isLite === true) {
      setPresentationMode(false);
    }
    else if (presentationModeQueryString === "true") {
      setPresentationMode(true);
    }
    console.log(`activeTab:${activeTab}, experiment:`, experiment);
  }, [experiment, activeTab])

  React.useEffect(() => {
    if (experiment != null) {
      const i18nTitles = experiment["title"].split("||")
      if (i18n.language == "it-IT" || i18nTitles[1] == null)
        setLocalizedExperimentTitle(i18nTitles[0].trim())
      else
        setLocalizedExperimentTitle(i18nTitles[1].trim())

      const i18nDescriptions = experiment["description"].split("||")
      if (i18n.language == "it-IT" || i18nDescriptions[1] == null)
        setLocalizedExperimentDescription(i18nDescriptions[0].trim())
      else
        setLocalizedExperimentDescription(i18nDescriptions[1].trim())
    }
  }, [experiment, i18n.language])


  const loginWithPassCode = (passCode: any) => {
    // i passcode con 10 caratteri sono quelli con la registrazione video dello schermo, quelli da 8 senza...
    const passcodeRecordingType = passCode.trim().length == 10 ? RecordingPasscode.RECORDING : RecordingPasscode.NO_RECORDING;
    if (isValid(passCode, passcodeRecordingType)) {
      setInvalidPassCode(false);
      setPassCode(passCode.trim());
      dispatch(PassCodeAction.didLoginWithPassCode(passCode.trim()))
      setPasscodeModalOpen(false);
      // faccio una richiesta di partenza della registrazione dello schermo a seconda del tipo di codice utente
      if (passcodeRecordingType == RecordingPasscode.RECORDING) setRecordingRequest(RecordingRequest.START)
    }
    else {
      setInvalidPassCode(true);
    }
  }

  const logoutWithPassCode = () => {
    // si fa una richiesta di stop in modo che la registrazione
    // abbia termine (se ancora in corso) e conseguentemente
    // abbia luogo il download dell'ultimo video
    setRecordingRequest(RecordingRequest.STOP)
    setSessionSavingPending(true);
  }

  const renderSessionJsonButton = () => {
    return <IconButton className="pull-right"
      style={{
        height: 34, marginRight: 6, marginBottom: 10, borderWidth: 1,
        backgroundColor: "#007bff",
        borderColor: 'white', borderStyle: 'solid', borderRadius: 4
      }}

      onClick={() => {
        setSessionTrace(JSON.parse(localStorage.getItem("RialeSessionTrace") as any));
        setSessionViewerModalOpen(true)
      }
      }
    >
      <IconContext.Provider
        value={{ color: `white`, size: "1em" }}>
        <VscJson data-place="top"
          data-for="sessionTraceTooltip" data-tip="Visualizza JSON degli eventi tracciati"
        />
      </IconContext.Provider>
    </IconButton>
  }

  const renderLoggedWithPasscodeButtons = () => {
    const loggedWithoutRecording = (isLoggedWithPassCode && passCode.length == 8)
    return (
      <div className="pull-right">
        <span style={{
          marginRight: 6, marginBottom: "10", padding: 10, borderWidth: 0,
          borderColor: 'white', borderStyle: 'solid', borderRadius: 2
        }}>
          <Badge color="info">{`Utente: ${passCode}`}</Badge>
        </span>
        {/* 
        <Button color="primary" onClick={() => {
          window.open(`${configuration[STAGE]["rialenetBaseUrl"]}?mypasscode=${passCode}`)
        }}
          style={{
            height: 34, marginRight: 6, marginBottom: 10, borderWidth: 1, borderColor: 'white',
            borderStyle: 'solid', borderRadius: 4
          }}>Chat</Button>
        */}

        {
          !loggedWithoutRecording && screenRecordingStatus == "recording" && <Button color="primary"
            style={{
              height: 34, marginRight: 6, marginBottom: 10, borderWidth: 1, borderColor: 'white',
              borderStyle: 'solid', borderRadius: 4
            }}

            onClick={() => {
              setRecordingRequest(RecordingRequest.STOP);
            }}><FaPause color="white" style={{ marginRight: "10px", marginBottom: "4px" }}
            />{t(`Metti in pausa la registrazione`)}</Button>
        }


        {
          !loggedWithoutRecording && (screenRecordingStatus == "stopped" || screenRecordingStatus == "idle") &&
          <>
            <IconButton
              style={{
                height: 34, marginRight: 6, marginBottom: 10, borderWidth: 1, borderColor: 'white',
                borderStyle: 'solid', borderRadius: 4
              }}
              color="primary"
              data-for="recSessionButton"
              data-tip={t(`Avvia la registrazione`)}
              onClick={() => {
                //console.log("Click on riprendi la registrazione con RecordingRequest:", recordingRequest)
                setRecordingRequest(RecordingRequest.START);
              }}>
              <IconContext.Provider value={{ color: 'white' }}>
                <BsRecordCircleFill color="white" style={{ marginRight: "10px", marginBottom: "3px" }} />
              </IconContext.Provider>
            </IconButton>
            <ReactTooltip id="recSessionButton" />
          </>
        }



        <Button color="primary" onClick={() => {
          logoutWithPassCode()
        }}
          style={{
            height: 34, marginRight: 6, marginBottom: 10, borderWidth: 1, borderColor: 'white',
            borderStyle: 'solid', borderRadius: 4
          }}><FaSave color="yellow" style={{ marginRight: "10px", marginBottom: "3px" }}
          />{t("Salva ed esci")}</Button>

      </div>
    )
  }

  const renderSessionViewerModal = () => {

    return (sessionTrace && <Modal isOpen={isSessionViewerModalOpen}>
      <ModalHeader style={{
        display: "flex",
        justifyContent: "center",
      }}>{`Utente:${sessionTrace["passCode"]} - Session:${sessionTrace["id"].slice(8)}`}
      </ModalHeader>

      <ModalBody>
        <ReactJson style={{ padding: "10px" }} theme="monokai" displayDataTypes={false} collapsed={3}
          src={(JSON.parse(localStorage.getItem("RialeSessionTrace") as any))} />
      </ModalBody>
      <ModalFooter style={{
        display: "flex",
        justifyContent: "space-between",
      }}>

        <Mailto email="riale@crs4.it"
          subject={`RIALENET ** Sessione Utente:${sessionTrace["passCode"]} - Session:${sessionTrace["id"].slice(8)}`}
          body={JSON.stringify(sessionTrace, null, '  ').replace(/,/g, "%2c")}>
          Invia Mail
        </Mailto>

        <Button onClick={() => { setSessionViewerModalOpen(false); }}>{t("close")}</Button>

      </ModalFooter>
    </Modal>)
  }

  const renderSessionExitConfirmedModal = () => {
    return (<Modal size="lg" isOpen={sessionSavingPending}>
      <ModalHeader>{sessionExitConfirmed ? t(`Salvataggio e chiusura della sessione in corso...`) :
        t(`Richiesta di terminazione della sessione`)}
      </ModalHeader>
      <ModalBody style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {
          sessionExitConfirmed ? <Spinner style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} animation="border" variant="primary" /> :
            <Alert style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} color="info">
              <div style={{ flex: 1 }}>
                {renderRecordingFilenames()}
                {t(`alert_end_session`)}

              </div>

            </Alert>
        }
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => { setSessionSavingPending(false); }}>{t(`Annulla`)}</Button>
        <Button color="primary" onClick={() => {
          const finalSessionTrace = JSON.parse(localStorage.getItem("RialeSessionTrace") as any)
          //console.log(`RECORDING ULID: ${finalSessionTrace?.recording_id}`)
          setSessionExitConfirmed(true);
          dispatch(PassCodeAction.willLogoutWithPassCode({ passCode }))
        }}>{t(`Salva e termina la sessione`)}</Button>
      </ModalFooter>
    </Modal>)
  }

  const renderSessionSavingErrorModal = () => {
    return (<Modal isOpen={sessionSavingError != null && sessionExitConfirmed}>
      <ModalHeader>{`Errore di salvataggio della sessione`}
      </ModalHeader>
      <ModalBody style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {
          <Alert style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} color="danger">
            {`${t("alert_error_saving_session")}:\n${sessionSavingError}`}</Alert>
        }
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => {
          //dispatch(PassCodeAction.resetSessionSavingError());
          setSessionExitConfirmed(true);
          dispatch(PassCodeAction.didLogoutWithPassCode())
        }}>{`Esci dalla sessione (Tutti i dati saranno persi!)`}</Button>

        <Button color="primary" onClick={() => {
          setSessionExitConfirmed(false);
          dispatch(PassCodeAction.resetSessionSavingError());
        }}>{`Ok`}</Button>
      </ModalFooter>
    </Modal>)
  }

  const renderRecordingFilenames = () => {
    const sessionTrace = JSON.parse(localStorage.getItem("RialeSessionTrace") as any);
    const recording_id = sessionTrace["recording_id"]
    const expected_recordings = sessionTrace["expected_recordings"]
    if (!expected_recordings) return null;
    return (<p>
      {t("screen_recording_file_info")}<br></br><br></br>

      {Array.from(Array(expected_recordings).keys()).map(index => {
        return (
          <>{`${index + 1}) RIALE_session_${recording_id}_${index + 1}.mp4`}<br></br></>
        )
      })}
    </p>)
  }

  const renderSessionSavedModal = () => {
    return (<Modal isOpen={sessionSavedWithoutError}>
      <ModalHeader>{t(`Sessione utente terminata`)}
      </ModalHeader>
      <ModalBody>
        <Spinner size="sm" color="light" />
        <Alert style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} color="info">
          {t(`Grazie, la sessione è stata registrata con successo`)}
        </Alert>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => {
          setSessionSavedWithoutError(false);
        }}>Ok</Button>
      </ModalFooter>
    </Modal>)
  }


  const renderPasscodeEditorModal = () => {
    return (<Modal isOpen={isPasscodeModalOpen}>
      <ModalHeader>{t('enter_code')}
      </ModalHeader>

      <ModalBody>
        <Input onChange={event => setCurrentPassCode(event.target.value)} />
        {invalidPassCode && <Alert color="danger">{t("invalid_code")}</Alert>}
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => { setPasscodeModalOpen(false); }}>{t("cancel")}</Button>
        <Button color="primary" onClick={() => { loginWithPassCode(currentPassCode) }}>{"Ok"}</Button>
      </ModalFooter>
    </Modal>)
  }

  const renderPassCodeButton = () => {
    return (
      <>
        <IconButton className="pull-right"
          style={{
            height: 34, marginRight: 6, marginBottom: 10, borderWidth: 1,
            backgroundColor: "#007bff",
            borderColor: 'white', borderStyle: 'solid', borderRadius: 4
          }}
          data-for="passcodeButton"
          data-tip={t('entra_con_codice')}

          onClick={() => {
            setCurrentPassCode("");
            setInvalidPassCode(false);
            setPasscodeModalOpen(true);
          }}
        >
          <IconContext.Provider value={{ color: 'white' }}>
            <BsSunglasses style={{ color: 'white' }} size={'0.8em'} />
          </IconContext.Provider>
        </IconButton>
        <ReactTooltip id="passcodeButton" />
      </>
    )
  }




  // ---------------------------------------------







  const toggleNav = (tab: React.SetStateAction<string>) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }


  const dropDownExperiments = experiment && experiment.sections.map((value: any, i: number) => {
    return (
      <React.Fragment key={i}>
        <NavItem key={i} data-for={`tab_${i}`} data-tip={`${value.description}`}>
          <NavLink
            className={classnames({ active: activeTab === `${i}` })}
            style={activeTab === `${i}` ?
              { cursor: "arrow", color: "#007bff", fontWeight: "bold", background: "#EEEEEE" } : { cursor: "pointer", fontWeight: "normal" }}

            onClick={() => { toggleNav(`${i}`); }}
          >
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center"
            }}>
              {value?.isLite &&
                <FaMobileAlt style={{ marginRight: "6px" }}
                  color={`${"#007bff"}`} />}
              {value.title}{'   '}
            </div>

          </NavLink>
        </NavItem>
        <ReactTooltip id={`tab_${i}`} />
      </React.Fragment>
    )
  })


  function getTranslation(msg: string): string { return t(msg); }

  return (
    deviceType == "mobile" ?
    <>
      <MobileTimeline
        experiment={experiment}
        activeTab={activeTabQueryString && activeTab}
        startDateTime={timelineStart} />
    </>
     
      :
      <>
        <Prompt
          when={isLoggedWithPassCode}
          message={(e: any) =>
            isLoggedWithPassCode ?
              getTranslation(`Sei sicuro di voler mettere in pausa la sessione corrente?`)
              :
              getTranslation(`Sei sicuro di voler abbandonare la timeline?`)
          }
        />

        <div>
          <Header className="mb-0 text-white" section="Timeline" showMenu={false} />
          <Content fluid={true} section={"public_timeline"} className="pt-3">
            <Row className="m-0" style={{ height: 'calc(100% - 22px)' }}>
              <Col xs="12" style={{ height: '100%' }}>
                <Card className="mb-4" style={{ height: '100%', border: "0px solid #000", borderColor: "#007bff" }}>
                  <CardHeader style={{
                    backgroundColor: "#007bff",
                    borderColor: "#007bff",
                    paddingBottom: 0,
                    color: 'white'

                  }}>
                    <CardTitle tag="h5"> {experiment == null ?
                      <span style={{ marginBottom: 10 }} className="pull-left">{t("Caricamento in corso...")}</span> :
                      <div>
                        <span style={{ marginRight: "8px" }}>{`${localizedExperimentTitle}`}</span>

                        <IconContext.Provider value={{ color: 'white' }}>
                          <BsInfoCircle style={{ marginBottom: "4px" }} data-for="timelineInfo" data-place="right"
                            data-tip={localizedExperimentDescription} size={'1.0em'} />
                        </IconContext.Provider>

                        <ReactTooltip
                          type="light"
                          id="timelineInfo" />

                      </div>


                    }
                      {/** Il <RialeScreenRecording> non viene inserito nel caso di utente non loggato
                      *   con un accesso con un codice personale che non preveda la acquisizione video!
                      */}
                      {!(isLoggedWithPassCode && passCode.length == 8) && deviceType == "desktop" && false &&
                        <div className='pull-right' style={{ float: 'right', marginTop: '-8px', marginRight: '-30px' }}>
                          <RialeScreenRecording
                            screen={true}
                            audio={!isLoggedWithPassCode}
                            video={false}
                            downloadRecordingPath={isLoggedWithPassCode ? currentSessionRecordingPath : "RIALE_timeline_recording"}
                            recordingRequest={recordingRequest}
                            downloadRecordingType="mp4"
                            isLoggedWithPassCode={isLoggedWithPassCode}
                            hiddenButtons={isLoggedWithPassCode || !isLogged}
                            onScreenRecordingStatusChange={(status: any, mediablobUrl: any) => {
                              //console.log(`PEXP status change: ${status} blob:${mediablobUrl}  watcherInPaused?:${watcherIsPaused} recordingRequest:${recordingRequest?.name}`);
                              setScreenRecordingStatus(status);
                              // Nel caso ci si ritrovi in una sessione di tracciamento, viene messo in pausa/riattivato
                              // coerentemente con lo stato di registrazione dello schermo
                              if (isLoggedWithPassCode) {
                                setWatcherIsPaused(status != "recording");
                                // se ho terminato il video e la url è disponibile per il download
                                // inoltro subito la richiesta di scaricamento in locale
                                // altrimenti imposto la richiesta a IDLE per indicare che
                                // la mia richiesta precedente è stata già recepita
                                if (mediablobUrl != null && status == "stopped")
                                  setRecordingRequest(RecordingRequest.DOWNLOAD);
                                else {
                                  setRecordingRequest(RecordingRequest.IDLE);
                                }
                              }
                              else
                                // la richiesta di cambio di stato dovrebbe aver avuto luogo 
                                // e cambio la stato richiesta in modo da poterne effettuare una in seguito
                                setRecordingRequest(RecordingRequest.IDLE);
                            }}
                          />
                        </div>
                      }


                      {experiment != null && experiment.clonable &&
                        <>

                          <IconButton className="pull-right"
                            style={{
                              height: 34, marginRight: 6, marginBottom: 10, borderWidth: 1,
                              backgroundColor: "#007bff",
                              borderColor: 'white', borderStyle: 'solid', borderRadius: 4
                            }}
                            color="primary"
                            data-for="cloneButton"
                            data-tip={t('clona')}
                            onClick={() => {
                              if (isLogged) {
                                const titles = experiment.title.split("||")
                                const cloneTitle = (titles.length>1 ?
                                
                                `Copia di ${titles[0]}||Copy of ${titles[1]}`:
                               `Copia di ${titles[0]}||Copy of ${titles[0]}`)
                                dispatch(ExperimentsActions.willClonePublicExperiment(
                                  {
                                    experiment: id, owner: experiment.owner,
                                    title: `${cloneTitle}`, description: `${experiment.description}`
                                  }))
                                dispatch(push(`/timeline/`));
                              }
                              else {
                                dispatch(push(`/login/?public_resource=${id}`));
                              }
                            }
                            }>
                            <IconContext.Provider value={{ color: `white` }}>
                              <LiaCloneSolid size={'0.8em'} />
                            </IconContext.Provider>
                          </IconButton>
                          <ReactTooltip id="cloneButton" />
                        </>
                      }
                      {
                        sessionSavedWithoutError && renderSessionSavedModal()
                      }
                      {
                        sessionSavingPending && renderSessionExitConfirmedModal()
                      }
                      {
                        sessionSavingError != null && renderSessionSavingErrorModal()
                      }
                      {
                        (experiment?.sections && experiment.sections[activeTab]?.isLite === true) &&
                        (
                          <IconButton className="pull-right"
                            style={{
                              height: 34, paddingTop: "15px", marginRight: 6, marginBottom: 10, borderWidth: 1,
                              backgroundColor: "#007bff",
                              borderColor: 'white', borderStyle: 'solid', borderRadius: 4
                            }}

                            onClick={(ev) => { setPresentationMode(!presentationMode) }}
                            data-place="top" data-for="viewModeExperiment"
                            data-tip={`${presentationMode ? t("editing_mode") : t("presentation_mode")}`}
                          >
                            <IconContext.Provider value={{ color: `${presentationMode ? 'white' : 'white'}` }}>
                              {
                                (presentationMode ?
                                  <CiViewTimeline style={{ paddingBottom: "5px", marginTop: "0px" }} size={'1.4em'} />
                                  :
                                  <RiSlideshow3Line style={{ paddingBottom: "5px", marginTop: "0px" }} size={'1.2em'} />
                                )
                              }

                            </IconContext.Provider>
                          </IconButton>
                        )
                      }

                      <ReactTooltip id="viewModeExperiment" />

                      {
                        experiment != null && (!isLogged || DEBUG) &&
                        (passCode ?

                          //renderPassCodeDropDown()
                          renderLoggedWithPasscodeButtons()
                          :
                          renderPassCodeButton())
                      }
                      {DEBUG && renderSessionJsonButton()}


                    </CardTitle>
                  </CardHeader>
                  <CardBody>


                    <div>
                      <Nav tabs>
                        {dropDownExperiments}
                      </Nav>
                    </div>
                    {isSessionViewerModalOpen && renderSessionViewerModal()}
                    {isPasscodeModalOpen && renderPasscodeEditorModal()}
                    {(activeTab && experiment && experiment.sections[activeTab]) ? (

                      <div>
                        <Row>
                          <Col>
                            {/* 
          <Card body className="text-center">
          <CardHeader tag="h4">{sections[activeTab].title}: {sections[activeTab].description}</CardHeader>
          </Card>
          */}
                          </Col>
                        </Row>
                        {experiment != null &&
                          (presentationMode ?

                            <LightTimeline 
                             id={experiment.experiment}
                              startDateTime={timelineStart}
                              mobileSection={experiment.sections[activeTab]} />
                            :
                            <RialeTimelineViewer ref={timelineRef}
                              id={experiment.experiment}
                              liteCompliantSections={{}}
                              sections={experiment.sections}
                              tracks={tracks}
                              startDateTime={timelineStart}
                              title={experiment.sections[activeTab].title}
                              activeTab={activeTab}
                              windowIsActive={windowIsActive}
                              isLoggedWithPasscode={isLoggedWithPassCode}
                              watcherIsPaused={watcherIsPaused && !(isLoggedWithPassCode && passCode.length == 8)}
                              canEdit={false}
                              onItemChanged={() => { }}
                              onUndo={() => { }}
                              onRedo={() => { }}
                              onTimelineEditingEnabled={(ev: boolean) => { }}
                              canUndo={false}
                              canRedo={false}
                              isPublic={true}
                            />
                          )

                        }
                      </div>
                    ) : (<></>)}
                    <div>
                      <CookieConsent style={{ background: "#00AA20" , marginBottom:"10px" }}
                        buttonText={t("cookieAccept")}
                        buttonStyle={{ color: "#4e503b", background: "white", fontSize: "13px" }}
                        cookieName="rialeSubtitlesWarning">{t("cookieSubtitlesWarning")}</CookieConsent>
                      <Footer />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Content>

        </div>
      </>
  );
}