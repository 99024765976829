import { useState, useEffect, useRef, forwardRef } from 'react';
import moment from 'moment';
import { selectors as PassCodeSelector, actions as PassCodeAction } from '../../store/slices/passCodeUsers'
import { selectors as AuthSelectors } from '../../store/slices/auth'
import { selectors as ProfileSelectors } from '../../store/slices/profile'
import { selectors as UsersSelectors, actions as UsersActions } from '../../store/slices/users'
//https://www.npmjs.com/package/activity-logger
import { useSelector, useDispatch } from "react-redux";
import beautify from 'js-beautify'
import { ulid } from 'ulid'
import { configuration } from '../../config';

// see https://docs.google.com/document/d/1wlVgmcy9jMj9KjtEI8_lWhGn9X_A8ZHyULo5y13A2yo/edit#

export class TraceChangePosition {
    static GOTO_POSITION_BY_PLAYER = "GOTO_POSITION_BY_PLAYER" // cambio di posizione imposto dal player
    static GOTO_POSITION_BY_REWIND = "GOTO_POSITION_BY_REWIND" // l'utente riavvolge il video corrente 
    static GOTO_POSITION_BY_TAG_MENU = "GOTO_POSITION_BY_TAG_MENU" // l'utente salta in un paragrafo dal menu
    static GOTO_POSITION_BY_TAG_INDEX = "GOTO_POSITION_BY_TAG_INDEX" // l'utente salta in un paragrafo cliccando sul pulsante
    static GOTO_POSITION_BY_SLIDER = "GOTO_POSITION_BY_SLIDER" // l'utente si sposta in una nuova posizione con lo slider
}

export class TraceEvent {
    // IMPLEMENTATI
    static CLICK_ATTACHMENT = new TraceEvent("CLICK_ATTACHMENT", "l'utente clicca su un allegato")
    static CLICK_TIMELINE = new TraceEvent("CLICK_TIMELINE",
        "l'utente cambia intenzionalmente la posizione sulla timeline")
    static ATTACHMENTS_PANEL_VISIBILITY_CHANGE = new TraceEvent("ATTACHMENTS_PANEL_VISIBILITY_CHANGE",
        "l'utente rende visibile o nasconde il pannello degli allegati audio/video")

    static ATTACHMENTS_PANEL_RESIZE_CHANGE = new TraceEvent("ATTACHMENTS_PANEL_RESIZE_CHANGE",
        "l'utente ridimensiona il pannello degli allegati audio/video")

    static NAVIGATOR_PANEL_VISIBILITY_CHANGE = new TraceEvent("NAVIGATOR_PANEL_VISIBILITY_CHANGE",
        "l'utente rende visibile o nasconde il pannello degli allegati audio/video")

    static ZOOM_CHANGE = new TraceEvent("ZOOM_CHANGE", "cambio di stato dello zoom")

    static PIP_STATUS_CHANGE = new TraceEvent("PIP_STATUS_CHANGE", "Cambio di stato del Picture in Picture (true|false)")
    static FULLSCREEN_VIDEO_STATUS_CHANGE = new TraceEvent("FULLSCREEN_VIDEO_STATUS_CHANGE",
        "Cambio di stato del video in fullscreen del video (true|false)")
    static PLAYING_STATUS_CHANGE_REQUEST = new TraceEvent("PLAYING_STATUS_CHANGE_REQUEST",
        "Richiesta di cambio di stato del player della timeline true|false")
    static VOLUME_STATUS_CHANGE = new TraceEvent("VOLUME_STATUS_CHANGE",
        "Cambio di stato del volume del video true|false")
    static AUDIO_SOLO_STATUS_CHANGE = new TraceEvent("AUDIO_SOLO_STATUS_CHANGE",
        "Cambio di stato del volume del video true|false")
    static NAVIGATE_TO_ITEM = new TraceEvent("NAVIGATE_TO_ITEM",
        "l'utente seleziona un item dal navigatore dei contenuti")


    static ATTACHMENTS_CURRENT_STATUS = new TraceEvent("ATTACHMENTS_CURRENT_STATUS",
        "Evento triggerato ogni 30 secondi di sessione attiva per fotografare gli item correntemente attivi")

    static SESSION_VISIBILITY_CHANGE = new TraceEvent("SESSION_VISIBILITY_CHANGE",
        "Evento triggerato quando l'utente mostra o nasconde la finestra con la sessione di lavoro")

    static SESSION_PAUSED = new TraceEvent("SESSION_PAUSE",
        "Evento triggerato quando l'utente mette intenzionalmente in pausa la sessione di lavoro")

    static SESSION_RESUMED = new TraceEvent("SESSION_RESUME",
        "Evento triggerato quando l'utente riprende intenzionalmente la sessione di lavoro")

    // BOOKMARKS TRACE * // 

    static ADD_BOOKMARK = new TraceEvent("ADD_BOOKMARK",
        "L'utente aggiunge un nuovo bookmark personale")

    static UPDATE_BOOKMARK = new TraceEvent("UPDATE_BOOKMARK",
        "L'utente aggiorna un bookmark personale")

    static REMOVE_BOOKMARK = new TraceEvent("REMOVE_BOOKMARK",
        "L'utente rimuove bookmark personale")

    static NAVIGATE_TO_BOOKMARK = new TraceEvent("NAVIGATE_TO_BOOKMARK",
        "l'utente seleziona un bookmark dal navigatore dei contenuti")

    // CONTEST 2024 TRACE
    static TIMELINE_LITE_OPEN = new TraceEvent("TIMELINE_LITE_OPEN", "l'utente apre una pagina contenente una timeline lite")
    static JUMP_TO_POSITION = new TraceEvent("JUMP_TO_POSITION", "l'utente cambia deliberatamente la posizione del video")
    static PLAY_CHANGE = new TraceEvent("PLAY_CHANGE", "Il player si avvia o si arresta")

    constructor(name, description) {
        this.name = name
        this.description = description
    }
}

class WatcherEvent {

    constructor(traceEvent, payload) {
        //console.log("TIMELINE WATCHER: traceEvent", traceEvent, payload)
        this.traceEvent = traceEvent
        this.payload = this.processPayload(payload);
    }

    isValid = () => {
        return this.payload != null;
    }

    processPayload = (payload) => {
        let filteredPayload = {}
        // tutti gli eventi devono contenere un payload valido
        if (payload == null) return null;

        switch (this.traceEvent) {
            case TraceEvent.SESSION_VISIBILITY_CHANGE:
                {
                    filteredPayload = { ...payload }
                    break;
                }

            case TraceEvent.ATTACHMENTS_CURRENT_STATUS:
                {
                    filteredPayload = { ...payload }
                    break;
                }
            case TraceEvent.NAVIGATE_TO_ITEM:
                {
                    filteredPayload = { ...payload }
                    break;
                }
            case TraceEvent.CLICK_TIMELINE:
                {
                    filteredPayload = { ...payload }
                    break;
                }
            case TraceEvent.FULLSCREEN_VIDEO_STATUS_CHANGE:
                {
                    filteredPayload = { ...payload }
                    break;
                }
            case TraceEvent.PIP_STATUS_CHANGE:
                {
                    filteredPayload = { ...payload }
                    break;
                }
            case TraceEvent.ATTACHMENTS_PANEL_VISIBILITY_CHANGE:
                {
                    filteredPayload = { ...payload }
                    break;
                }

            case TraceEvent.ATTACHMENTS_PANEL_RESIZE_CHANGE:
                {
                    filteredPayload = { ...payload }
                    break;
                }

            case TraceEvent.NAVIGATOR_PANEL_VISIBILITY_CHANGE:
                {
                    filteredPayload = { ...payload }
                    break;
                }
            case TraceEvent.ZOOM_CHANGE:
                {
                    filteredPayload = { ...payload }
                    break;
                }
            case TraceEvent.CLICK_ATTACHMENT:
                {
                    filteredPayload = { ...payload };
                    filteredPayload["item"] = {
                        "id": payload["item"]["id"],
                        "title": payload["item"]["title"], "type": payload["item"]["type"]
                    }
                    break;
                }

            case TraceEvent.PLAYING_STATUS_CHANGE_REQUEST:
                {
                    filteredPayload = { ...payload }
                    break;
                }

            case TraceEvent.VOLUME_STATUS_CHANGE:
                {
                    filteredPayload = { ...payload }
                    break;
                }
            case TraceEvent.AUDIO_SOLO_STATUS_CHANGE:
                {
                    filteredPayload = { ...payload }
                    break;
                }
            case TraceEvent.SESSION_PAUSED:
                {
                    filteredPayload = { ...payload }
                    break;
                }
            case TraceEvent.SESSION_RESUMED:
                {
                    filteredPayload = { ...payload }
                    break;
                }
            case TraceEvent.ADD_BOOKMARK:
                {
                    filteredPayload = { ...payload }
                    break;
                }
            case TraceEvent.UPDATE_BOOKMARK:
                {
                    filteredPayload = { ...payload }
                    break;
                }
            case TraceEvent.REMOVE_BOOKMARK:
                {
                    filteredPayload = { ...payload }
                    break;
                }
            case TraceEvent.NAVIGATE_TO_BOOKMARK:
                {
                    filteredPayload = { ...payload }
                    break;
                }
            case TraceEvent.TIMELINE_LITE_OPEN:
                {
                    filteredPayload = { ...payload }
                    break;
                }
            case TraceEvent.PLAY_CHANGE:
                {
                    filteredPayload = { ...payload }
                    break;
                }
            /*
            case TraceEvent.JUMP_TO_POSITION:
                    {
                        filteredPayload = { ...payload }
                        break;
                    }
            */
            default: {
                // prevengo l'invio di eventi non previsti dal watcher
                filteredPayload = null
            }
        }
        return filteredPayload;
    }


    toJson = () => {
        const eventDate = moment.now()
        const formattedDate = moment(eventDate).format("YYYY/MM/DD - HH:mm:ss")
        return {
            "type": this.traceEvent.name, "timestamp": eventDate,
            "formattedDatetime": formattedDate, "payload": this.payload
        }
    }

}

const withWatcher = (WrappedComponent) => {
    return forwardRef((props, ref) => {
        const currentRegistrationProfile = useSelector(AuthSelectors.getRegistrationProfile)
        const userAttributes = useSelector(ProfileSelectors.getProfile);
        const [trace, setTrace] = useState(null);
        const contest2024TraceRef = useRef(null);
        const contest2024tracenameRef = useRef(null);
        const [isPaused, setPaused] = useState(false);
        const currentPassCode = useSelector(PassCodeSelector.getCurrentPassCode);
        const dispatch = useDispatch();

        const isContest2024studentLogged = () => {
            console.log(`TLW: currentRegistrationProfile: ${currentRegistrationProfile?.contest2024} , user:${userAttributes?.sub}`)
            const res = (currentRegistrationProfile?.type == "student" &&
                currentRegistrationProfile?.contest2024 == true && userAttributes?.sub != null)
            console.log("TLW: isStudentContest:", res)
            return res
        }

        /*
        useEffect(() => {
            return (() => {
               // console.log("TLW: CLEANUP CONTEST TRACE:", beautify(JSON.stringify(contest2024TraceRef.current)));
                if (contest2024TraceRef.current == null) {
                    //alert("FINE TRACCIAMENTO con TRACE NULL:")
                }

                else {
                    if (isContest2024studentLogged()) {
                        alert("FINE TRACCIAMENTO con TRACE NON NULLO")
                        const crf = currentRegistrationProfile
                        const passcode = `${crf.teacherCode}_${crf.schoolGrade}${crf.schoolSection}`
                        console.log(`TLW: Fine tracciamento passcode:${passcode}`)
                        stopContest2024Trace(passcode, userAttributes.sub)
                    }
                }
            })
        }, [])
         */

        
       
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      
      if (userAttributes?.sub) {
        // Dispatcha l'azione Redux per salvare i dati
        dispatch(PassCodeAction.willSaveContest2024StudentSessions(
            {
                "userId": userAttributes?.sub 
            }
        ));
      }

      // Opzionalmente, mostra un messaggio di avviso all'utente
      event.preventDefault();
      event.returnValue = ''; // Alcuni browser richiedono che returnValue sia una stringa vuota
    };

    // Aggiungi l'event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Rimuovi l'event listener quando il componente viene smontato
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dispatch]);




        useEffect(() => {
            console.log("TLW: userAttributes:", userAttributes)
            if (userAttributes != null)
                dispatch(UsersActions.willGetUser(userAttributes.sub))
        }, [userAttributes])
        /*
            useEffect(() => {
                console.log(`TLW: current registration profile sub: ${userAttributes?.sub}:`, currentRegistrationProfile)
                if (isContest2024studentLogged()) {
                    alert("INIZIO TRACCIAMENTO")
                    const crf = currentRegistrationProfile
                    //const passcode = userAttributes["email"]
                    contest2024PasscodeRef.current = `${crf.teacherCode}_${crf.schoolGrade}${crf.schoolSection}`
                    console.log(`TLW: tracciamento passcode:${contest2024PasscodeRef.current }`)
                    startContest2024Trace()
                }
            }, [currentRegistrationProfile, userAttributes])
            */
        useEffect(() => {
            //console.log("TLW: PASSCODE CURRENT PASSCODE:", currentPassCode);
            if (currentPassCode != null) {
                // N.B il passcode con 10 caratteri è per convenzione l'unico
                // che prevede la acquisizione video... per questo motivo
                // il recordig_id è settato solo in quel caso!
                const storageTrace = JSON.parse(localStorage.getItem("RialeSessionTrace"));
                if (storageTrace == null) {
                    // aggiorno nel localstorage il valore del nuovo passCode nel caso sia nullo quello corrente
                    const creationDate = moment.now()

                    const newTrace = {
                        "tracedResource": WrappedComponent.name,
                        "username": userAttributes?.email || "",
                        "passCode": currentPassCode,
                        "id": `session_${creationDate}`,
                        "expected_recordings": 0,
                        "recording_id": `${currentPassCode.length == 10 ? ulid() : "-"}`,
                        "startDate": creationDate,
                        "formattedStartDatetime": moment(creationDate).format("YYYY/MM/DD - HH:mm:ss"),
                        "actions": []
                    }
                    setTrace(newTrace);
                    // salvo subito la nuova traccia nel localStorage, in modo da avere
                    // da subito a disposizione le informazioni disponibili anche per gli 
                    // altri componenti
                    localStorage.setItem("RialeSessionTrace", JSON.stringify(newTrace));
                }
                else {
                    setTrace(storageTrace);
                }
            }
            else {
                // la rimozione dal LocalStorage avviene in sede di didLogoutWithPasscode
                setTrace(null);
            }
            //console.log("TIMELINE WATCHER: PASSCODE CURRENT SESSION TRACE:", JSON.parse(localStorage.getItem("RialeSessionTrace")))

        }, [currentPassCode])


        const startContest2024Trace = (timelineId) => {
            if (!isContest2024studentLogged()) {
                console.log("TLW: Utente non loggato. Tracciamento ignorato")
                return;
            }

            ///alert("INIZIO TRACCIAMENTO")
            console.log("INIZIO TRACCIAMENTO")
            const crf = currentRegistrationProfile
            const passcode = `${crf.teacherCode}_${crf.schoolGrade}${crf.schoolSection}`
            console.log(`TLW: tracciamento passcode:${passcode}`)

            if (passcode != null && userAttributes?.sub != null) {
                const contest2024_tracename = `contest2024trace__${passcode}_${timelineId}_${userAttributes.sub}`
                contest2024tracenameRef.current = contest2024_tracename
                console.log("TLW: contest2024_tracename:", contest2024_tracename)
                const storageTrace = JSON.parse(localStorage.getItem(contest2024_tracename));
                if (storageTrace == null) {
                    // aggiorno nel localstorage il valore del nuovo passCode nel caso sia nullo quello corrente
                    const creationDate = moment.now()

                    const newTrace = {
                        "tracedResource": WrappedComponent.name,
                        "group": configuration[process.env.REACT_APP_STAGE || "beta"]["contest2024Group"],
                        "timelineId": timelineId,
                        "username": userAttributes?.email || "",
                        "given_name": userAttributes?.given_name || "",
                        "family_name": userAttributes?.family_name || "",
                        "passCode": passcode,
                        "id": `session_${creationDate}`,
                        //"expected_recordings": 0,
                        "startDate": creationDate,
                        "formattedStartDatetime": moment(creationDate).format("YYYY/MM/DD - HH:mm:ss"),
                        "actions": []
                    }
                    contest2024TraceRef.current = newTrace;
                    // salvo subito la nuova traccia nel localStorage, in modo da avere
                    // da subito a disposizione le informazioni disponibili anche per gli 
                    // altri componenti
                    localStorage.setItem(contest2024_tracename, JSON.stringify(newTrace));
                }
                else {
                    contest2024TraceRef.current = storageTrace;
                }
            }
        }

        const stopContest2024Trace = (timelineId) => {

            if (!isContest2024studentLogged()) {
                return;
            }
            //const crf = currentRegistrationProfile
            //const passcode = `${crf.teacherCode}_${crf.schoolGrade}${crf.schoolSection}`
            //const contest2024_tracename = `contest2024trace__${passcode}_${timelineId}_${userAttributes.sub}`
            console.log(`TLW:  stopContest2024Trace ${contest2024tracenameRef.current}`)
            dispatch(PassCodeAction.willSaveContest2024Session(
                {
                    "passCode": contest2024tracenameRef.current,
                    "contest2024storageTrace": contest2024tracenameRef.current
                }
            ));
            // se stoppo la sessione non devo lasciare traccia in memoria
            contest2024TraceRef.current = null;
        }

        const traceEventByContest2024 = (traceEvent, payload) => {
            console.log(`TLW: onEventToWatch called on: ${traceEvent.name} isPaused:${isPaused} payload`, payload)

            // se sono in stato di pausa e l'evento non è quello di RESUME non faccio nulla
            if ((isPaused && traceEvent != TraceEvent.SESSION_RESUMED)) return;

            if (traceEvent == TraceEvent.SESSION_PAUSED) { setPaused(true); }
            else if (traceEvent == TraceEvent.SESSION_RESUMED) { setPaused(false); }
            
            if (traceEvent == TraceEvent.TIMELINE_LITE_OPEN) {
                console.log("TLW: Avvio il trace causa TIMELINE_LITE_OPEN ")
                startContest2024Trace(payload["timelineId"]);
            }
            else
                if (traceEvent == TraceEvent.SESSION_VISIBILITY_CHANGE) {
                    if (payload["visible"] == false) {
                        console.log("TLW: Arresto il trace causa SESSION_VISIBILITY_CHANGE = false")
                        stopContest2024Trace(payload["timelineId"])
                    }
                    else if (payload["visible"] == true) {
                        console.log("TLW: Avvio il trace causa SESSION_VISIBILITY_CHANGE = true")
                        startContest2024Trace(payload["timelineId"]);
                    }
                    return;
                }
            // se a questo punto il trace è nullo devo ancora aspettare che parta...
            if (contest2024TraceRef.current == null) return;

            let newTrace = { ...contest2024TraceRef.current }
            const watcherEvent = new WatcherEvent(traceEvent, payload);
            if (watcherEvent.isValid()) {
                newTrace["actions"].push(watcherEvent.toJson())
                contest2024TraceRef.current = newTrace;
                //console.log(`onEventToWatch: Aggiorno la sessione dello store:`, newTrace)
                localStorage.setItem(contest2024tracenameRef.current, JSON.stringify(newTrace));
            }
        }

        const traceEventByPasscode = (traceEvent, payload) => {
            console.log(`TLW: onEventToWatch (by Passcode) called on: ${traceEvent.name} isPaused:${isPaused} currentTrace: ${trace} payload`, payload)
            // se la traccia corrente è nulla, significa che non c'è nessun utente loggato
            // con traccia valida e non faccio nulla
            // se sono in stato di pausa e l'evento non è quello di RESUME non faccio nulla
            if (trace == null || (isPaused && traceEvent != TraceEvent.SESSION_RESUMED)) return;

            if (traceEvent == TraceEvent.SESSION_PAUSED) { setPaused(true); }
            else if (traceEvent == TraceEvent.SESSION_RESUMED) { setPaused(false); }


            let newTrace = { ...trace }
            const watcherEvent = new WatcherEvent(traceEvent, payload);
            if (watcherEvent.isValid()) {
                newTrace["actions"].push(watcherEvent.toJson())

                if (traceEvent == TraceEvent.SESSION_RESUMED) {
                    //console.log(`SESSION RESUMED: increasing expected recording to:${newTrace["expected_recordings"]+1}`);
                    newTrace["expected_recordings"] += 1;
                    const newSessionRecordingPath = `RIALE_session_${newTrace.recording_id}_${newTrace["expected_recordings"]}`
                    dispatch(PassCodeAction.setCurrentSessionRecordingPath(newSessionRecordingPath))
                }

                setTrace(newTrace);
                //console.log(`onEventToWatch: Aggiorno la sessione dello store:`, newTrace)
                localStorage.setItem("RialeSessionTrace", JSON.stringify(newTrace));
            }

        }

        const onEventToWatch = (traceEvent, payload) => {
            if (isContest2024studentLogged())
                traceEventByContest2024(traceEvent, payload)
            else
                traceEventByPasscode(traceEvent, payload)
        }
        return <WrappedComponent ref={ref} onEventToWatch={(traceEvent, payload) => onEventToWatch(traceEvent, payload)} {...props} />
    }
    )
}

export default withWatcher;