import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import { selectors as ProfileSelectors } from '../store/slices/profile'
import { useEffect, useState } from 'react'
import { actions as UsersActions, selectors as UsersSelector } from '../store/slices/users'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { InteractiveSessionsDashboard } from './Dashboards/InteractiveSessionsDashboard';
import { FaCopy } from 'react-icons/fa';
import { TooltipButton } from './ActivityButton';
import { Card, CardText, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { IconContext } from "react-icons";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import useStudents from './useStudents';

const ClassroomInteractiveSessionsDashboard = (props: any) => {

    const userProfile = useSelector(ProfileSelectors.getProfile)
    const { registeredStudents: myStudents} = useStudents() as any;
    const [selectedStudent, setSelectedStudent] = useState(null) as any;
    const { t } = useTranslation('frontend', { useSuspense: false });

    const booleanFormatter = (cell: any, row: any) => {
        //console.log("Passo la riga:", row);
        //console.log("Passo la cella:", cell);
        return (

            (cell == true) ?
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <IconContext.Provider value={{ color: "green", size: "20", className: "global-class-name" }}>
                        <BsFillCheckCircleFill />
                    </IconContext.Provider>
                </div>
                :
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <IconContext.Provider value={{ color: "red", size: "24", className: "global-class-name" }}>
                        <MdCancel />
                    </IconContext.Provider>
                </div>
        )
    }

    const paginationOptions = {
        sizePerPageList: [{
            text: '3th', value: 3
        }
        ], pageStartIndex: 1
    }

    const selectRow = {
        mode: 'radio', bgColor: "#DAF994",
        onSelect: (row: any, isSelect: any, rowIndex: any, e: any) => {
            //console.log("Selezionato studente:", row);
            setSelectedStudent(row)
        }
    } as any;

    const columns = [
        {
            dataField: 'id',
            text: 'id',
            hidden: true
        },
        {
            dataField: 'classroom',
            text: t("classroom"),
            sort: true
        },
        {
            dataField: 'family_name',
            text: t('Surname'),
            sort: true
        },
        {
            dataField: 'given_name',
            text: t('Name'),
            sort: true
        },
        {
            dataField: 'email',
            text: t('Email'),
            sort: true
        },

        {
            dataField: 'contest2024',
            text: t('contest_2024_partecipant'),
            formatter: booleanFormatter,
            headerStyle: () => {
                return { justifyContent: "center", width: '10%' };
            },
            sort: true
        }
    ]


    return (
        <div style={{ display: "flex", flexDirection: "column" }}>

            <div style={{ "fontSize": "20px", "margin": "20px", "display": "flex", "justifyContent": "flex-start" }}>
                <b>{`${t("Codice Docente")}:`}&nbsp;</b>{userProfile?.sub}
                <TooltipButton duration={2500}
                    tooltipMessage={`${t("Copia il codice docente")}`}
                    onClickMessage={t('Copiato')}
                    onChildClick={async () => {
                        await navigator.clipboard.writeText(userProfile?.sub);
                    }}
                >
                    <FaCopy cursor="pointer" fontSize={"20px"}
                        style={{ marginLeft: "5px", marginRight: "5px", marginTop: "5px", border: '1px solid #007bff"', }} color="#007bff" />
                </TooltipButton>
            </div>

            <Card className="m-4" style={{
                marginTop: "-10px",
                borderColor: "#007bff"
            }}>
                <CardHeader data-tip={t("Area tematica")} style={{
                    backgroundColor: "#007bff",
                    borderColor: "#007bff",
                    paddingBottom: 0,
                    color: 'white'

                }}>
                    <CardTitle tag="h5">{`${t("I miei studenti")} (${myStudents?.length || '0'})`}</CardTitle>
                </CardHeader>
                <CardBody>
                    {
                        myStudents && (
                            <BootstrapTable bootstrap4 selectRow={selectRow} keyField='id' data={
                                myStudents
                            }
                                columns={columns} pagination={paginationFactory(paginationOptions)} />
                        )
                    }

                    {selectedStudent &&
                        (<InteractiveSessionsDashboard userId={selectedStudent["id"]} />)}

                </CardBody>
            </Card>



        </div>

    )
}

export default ClassroomInteractiveSessionsDashboard;