import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef } from "react"
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { Comparator, selectFilter, textFilter, dateFilter, customFilter, FILTER_TYPES } from 'react-bootstrap-table2-filter';

import paginationFactory from 'react-bootstrap-table2-paginator';
import UsersReportDownloader from './UsersExcelReport'
import { FaFilterCircleXmark } from "react-icons/fa6";
import ReactTooltip from "react-tooltip";
import {
  Card, Alert, CardBody, 
  CardHeader, CardFooter, CardTitle,  Form, FormGroup, Label, Input, FormText
} from "reactstrap";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { selectors as UsersSelectors, actions as UsersActions } from '../store/slices/users'
import { BsFillCheckCircleFill } from "react-icons/bs";
import { MdFreeCancellation } from "react-icons/md";

import { MdCancel } from "react-icons/md";
import { IconContext } from "react-icons";
import IconButton from '@material-ui/core/IconButton';
import "react-datetime/css/react-datetime.css";
import DateTime from 'react-datetime';
import CumulativeRegistrationsChart from './Analytics/DateChartViewer';

export const RegisteredUsersViewer = (props: any) => {
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  const [usersRows, setUsersRows] = useState([])
  const users = useSelector(UsersSelectors.getRegisteredUsers);
  const [selectedRows, setSelectedRows] = useState([]) as any;
  const [exportSelectedOnly, setExportSelectedOnly] = useState(false) as any;
  const [data, setData] = useState(usersRows || []);
  const [filteredData, setFilteredData] = useState([]);
  const afterFilterData = useRef([]);
  const [filteredDataSize, setFilteredDataSize] = useState(data.length)
  const [fromFilter, setFromFilter] = useState<String>("")
  const [toFilter, setToFilter] = useState<String>("")
  const DATE_FORMAT = "DD/MM/YYYY";
  const tableRef = useRef(null);

  // Componente personalizzato con due campi data e pulsante Reset
  const DateRangeFilter = ({ fromDate, toDate, onFromDateChange, onToDateChange }: any) => {

    const resetFilter = () => {
      onToDateChange("");
      onFromDateChange("");
    };

    return (
      <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
        <span style={{ marginTop: "8px", fontWeight: "bold" }}>{t("Da")}</span>
        <DateTime initialValue={fromDate} inputProps={{ disabled: false, 
          placeholder: t("gg/mm/aaaa") }}
          onChange={(newDate) => onFromDateChange(newDate)}
          dateFormat={DATE_FORMAT}

          timeFormat={false}
        />
        <span style={{ marginTop: "8px", fontWeight: "bold" }}>{t("A")}</span>

        <DateTime initialValue={toDate} inputProps={{ disabled: false, 
        placeholder: t("gg/mm/aaaa") }}
          onChange={(newDate) => onToDateChange(newDate)}
          dateFormat={DATE_FORMAT}

          timeFormat={false}
        />

        <IconButton onClick={() => { resetFilter() }}
          style={{ float: 'right', marginTop: '-10px', marginRight: '-10px' }}
        >
          <IconContext.Provider
            value={{ color: "green", size: "1em" }}>
            <MdFreeCancellation data-place="top"
              data-for="dateRangeTooltip" data-tip={t("Rimuovi il filtro delle date")}
            />
          </IconContext.Provider>
        </IconButton>
        <ReactTooltip id="dateRangeTooltip" />
      </div>
    );
  };

  // Funzione di filtro personalizzato
  const filterUsersByDateRange = () => {
    console.log("dateRangeFilter data:")
    if (!fromFilter && !toFilter) {
      setFilteredData(data as any);
    }

    const filteredDataL = data.filter((row: any) => {
      const rowDate = new Date(row.create_date);
      const startDate = fromFilter ? new Date(fromFilter as any) : null;
      const endDate = toFilter ?  moment(toFilter as any).set({ h: 23, m: 59 }).toDate() : null; //    new Date(toFilter as any) : null;

      return (!startDate || rowDate >= startDate) && (!endDate || rowDate <= endDate);
    });
    setFilteredData(filteredDataL as any);
  };

  useEffect(() => {
    filterUsersByDateRange()
  }, [fromFilter, toFilter, data])

  useEffect(() => {
    console.log("Filtered data:", filteredData)
  }, [filteredData])

  useEffect(() => {
    console.log("afterFilterData.current:", afterFilterData.current)
  }, [afterFilterData.current])


  const booleanOptions = {
    true: 'Si',
    false: 'No'
  };

  const roleOptions = {
    student: t('Studente'),
    teacher: t('Docente'),
    lab_supervisor: t('Ricercatore'),
    staff_riale: t("Staff di Riale")
  };

  useEffect(() => {

    if (users != null)
      setUsersRows(Object.values(users))
    else
      setUsersRows([]);

    //console.log("UsersV users:", users);
    console.log("UsersV usersRows:", usersRows);
  }, [users])

  useEffect(() => {

    if (usersRows != null) {
      setData(
        usersRows.map((row: any) => ({ ...row, fully_enabled: (row.enabled == true && row.email_verified == "true" && row.user_status == "CONFIRMED" && row.privacy == true) })) as any)
    }

    else
      setData([]);

    //console.log("UsersV users:", users);
    //console.log("UsersV usersRows:", usersRows);
  }, [usersRows])

  const paginationOptions = {
    sizePerPageList: [{
      text: `5 ${t("utenti")}`, value: 5
    }, {
      text: `10 ${t("utenti")}`, value: 10
    },
    {
      text: `20 ${t("utenti")}`, value: 20
    },
    {
      text: `50 ${t("utenti")}`, value: 50
    },
    {
      text: `100 ${t("utenti")}`, value: 100
    },
    {
      text: t('Tutti gli utenti'), value: (usersRows ? usersRows.length : 100)
    }
    ], pageStartIndex: 1
  }

  const dateFormatter = (cell: any, row: any) => {
    return moment(cell).format('DD/MM/YYYY - HH:mm');
  }
  const booleanFormatter = (cell: any, row: any) => {
    //console.log("Passo la riga:", row);
    //console.log("Passo la cella:", cell);
    return (
      (cell == true) ?
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconContext.Provider value={{ color: "green", size: "20", className: "global-class-name" }}>
            <BsFillCheckCircleFill />
          </IconContext.Provider>
        </div>
        :
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconContext.Provider value={{ color: "red", size: "24", className: "global-class-name" }}>
            <MdCancel />
          </IconContext.Provider>
        </div>
    )
  }

  const userStateFormatter = (cell: any, row: any) => {
    //console.log("Passo la riga:", row);
    //console.log("Passo la cella:", cell);
    return (
      (row.fully_enabled == true) ?
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconContext.Provider value={{ color: "green", size: "20", className: "global-class-name" }}>
            <BsFillCheckCircleFill />
          </IconContext.Provider>
        </div>
        :
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconContext.Provider value={{ color: "red", size: "24", className: "global-class-name" }}>
            <MdCancel />
          </IconContext.Provider>
        </div>
    )
  }
  const idFilter = useRef(null) as any;
  const surnameFilter = useRef(null) as any;
  const nameFilter = useRef(null) as any;
  const emailFilter = useRef(null) as any;
  const roleFilter = useRef(null) as any;
  const countryFilter = useRef(null) as any;;
  const schoolFilter = useRef(null) as any;
  const schoolTypeFilter = useRef(null) as any;
  const schoolLevelFilter = useRef(null) as any;
  const schoolMatterFilter = useRef(null) as any;
  const fullyEnabledFilter = useRef(null) as any;
  const newsletterFilter = useRef(null) as any;
  const contest2024Filter = useRef(null) as any;

  const clearAllFilters = () => {
    idFilter.current("");
    surnameFilter.current("");
    nameFilter.current("");
    emailFilter.current("");
    roleFilter.current("");
    countryFilter.current("");
    schoolFilter.current("");
    schoolTypeFilter.current("");
    schoolLevelFilter.current("");
    schoolMatterFilter.current("");
    fullyEnabledFilter.current("");
    newsletterFilter.current("");
    contest2024Filter.current("");
    setSelectedRows([]);
  }

  const renderToolbar = () => {
    return (<div style={{ display: "flex" }}>
      <DateRangeFilter fromDate={fromFilter} toDate={toFilter} onFromDateChange={(startDate: any) => {
        setFromFilter(startDate);
        console.log("startDate:", startDate)
      }} onToDateChange={(endDate: any) => {
        setToFilter(endDate);
        console.log("endDate:", endDate)
      }} />

      <IconButton onClick={() => { clearAllFilters() }}
        style={{ float: 'right', marginTop: '-10px', marginRight: '-10px' }}
      >
        <IconContext.Provider
          value={{ color: "green", size: "1em" }}>
          <FaFilterCircleXmark data-place="top"
            data-for="tbTooltip" data-tip={t("Rimuovi tutti i filtri della tabella")}
          />
        </IconContext.Provider>
      </IconButton>
      <CumulativeRegistrationsChart data={afterFilterData}  style={{marginBottom:"8px"}}/>
      <Form inline>
      <FormGroup style={{marginTop: "-8px", marginLeft:"10px"}} className="mb-2 mr-sm-2 mb-sm-0">
       
        <Label style={{ margin: "8px", fontWeight: "bold" }}>
          {`${t("Limita la esportazione ai soli utenti selezionati")} (${selectedRows.length})`}
        </Label>
        <Input style={{marginTop:"2px"}} type="checkbox" onClick={(event: any) => {
          setExportSelectedOnly(event.target.checked);
        }} />
      </FormGroup>

      </Form>
     
      <ReactTooltip id="tbTooltip" />
    </div>)
  }

  const usersColumns = [
    {
      dataField: 'id',
      text: t("id"),
      filter: textFilter({
        getFilter: (filter) => {
          idFilter.current = filter;
        },
        style: {
          fontSize: '12px',
          fontStyle: 'normal',
        },
        caseSensitive: false,
        placeholder: t('Filtra per cognome')
      }),
      hidden: false,
      sort: true
    },
    {
      dataField: 'family_name',
      text: t("Surname"),

      filter: textFilter({
        getFilter: (filter) => {
          surnameFilter.current = filter;
        },
        style: {
          fontSize: '12px',
          fontStyle: 'normal',
        },
        caseSensitive: false,
        placeholder: t('Filtra per cognome')
      }),
      sort: true
    },
    {
      dataField: 'given_name',
      text: t("Name"),
      filter: textFilter({
        getFilter: (filter) => {
          nameFilter.current = filter;
        },
        style: {
          fontSize: '12px',
          fontStyle: 'normal',
        },
        caseSensitive: false,
        placeholder: t('Filtra per nome')
      }),
      sort: true
    },
    {
      dataField: 'type',
      filter: selectFilter({
        getFilter: (filter) => {
          roleFilter.current = filter;
        },
        style: {
          fontSize: '12px',
          fontStyle: 'normal',
        },
        options: roleOptions,
        placeholder: t("Filtra per tipo"),
        defaultValue: '' // Opzionale: imposta un filtro predefinito
      }),
      text: t("Tipo"),
      sort: true
    },
    {
      dataField: 'email',
      text: t("Email"),
      filter: textFilter({
        getFilter: (filter) => {
          emailFilter.current = filter;
        },
        style: {
          fontSize: '12px',
          fontStyle: 'normal',
        },
        caseSensitive: false,
        placeholder: t('Filtra per email')
      }),
      sort: true
    },
    {
      dataField: 'country',
      text: t("Nazione"),
      filter: textFilter({
        getFilter: (filter) => {
          countryFilter.current = filter;
        },
        style: {
          fontSize: '12px',
          fontStyle: 'normal',
        },

        caseSensitive: false,
        placeholder: t('Filtra per nazione')
      }),
      sort: true
    },
    {
      dataField: 'school',
      text: t("Scuola"),
      filter: textFilter({
        getFilter: (filter) => {
          schoolFilter.current = filter;
        },
        style: {
          fontSize: '12px',
          fontStyle: 'normal',
        },
        caseSensitive: false,
        placeholder: t('Filtra per scuola')
      }),
      sort: true
    },
    {
      dataField: 'schoolType',
      text: t("Tipo di scuola"),
      filter: textFilter({
        getFilter: (filter) => {
          schoolTypeFilter.current = filter;
        },
        style: {
          fontSize: '12px',
          fontStyle: 'normal',
        },
        caseSensitive: false,
        placeholder: t('Filtra per tipo di scuola')
      }),
      sort: true
    },
    {
      dataField: 'schoolLevel',
      filter: textFilter({
        getFilter: (filter) => {
          schoolLevelFilter.current = filter;
        },
        style: {
          fontSize: '12px',
          fontStyle: 'normal',
        },
        caseSensitive: false,
        placeholder: t('Filtra per grado scolastico')
      }),
      text: t("Grado"),
      sort: true
    },
    {
      dataField: 'schoolMatter',
      text: t("Materia"),
      filter: textFilter({
        getFilter: (filter) => {
          schoolMatterFilter.current = filter;
        },
        style: {
          fontSize: '12px',
          fontStyle: 'normal',
        },
        caseSensitive: false,
        placeholder: t('Filtra per materia')
      }),
      headerStyle: () => {
        return { width: '10%' };
      },
      sort: true
    },
    {
      dataField: 'create_date',
      headerStyle: () => {
        return { width: '10%' }; // 14%
      },
      text: t("Data di creazione"),
      formatter: dateFormatter,
      sort: true
    },

    {
      dataField: 'fully_enabled',
      text: t("Attivo"),
      filter: selectFilter({
        getFilter: (filter) => {
          fullyEnabledFilter.current = filter;
        },
        style: {
          fontSize: '12px',
          fontStyle: 'normal',
        },
        options: booleanOptions,
        placeholder: "-",
        defaultValue: '' // Opzionale: imposta un filtro predefinito
      }),

      formatter: userStateFormatter,
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
      sortFunc: (a: any, b: any, order: any, dataField: any, rowA: any, rowB: any) => {
        const rowAval = rowA.enabled == true && rowA.email_verified == "true" && rowA.user_status == "CONFIRMED" && rowA.privacy == true
        const rowBval = rowB.enabled == true && rowB.email_verified == "true" && rowB.user_status == "CONFIRMED" && rowB.privacy == true
        if (order === 'asc') {
          return (rowAval && !rowBval ? 1 : -1)
        }
        else return (rowAval && !rowBval ? -1 : 1)
      }
    },


    {
      dataField: 'newsletter_accepted',
      filter: selectFilter({
        getFilter: (filter) => {
          newsletterFilter.current = filter;
        },
        style: {
          fontSize: '12px',
          fontStyle: 'normal',
        },
        options: booleanOptions,
        placeholder: "-",
        defaultValue: '' // Opzionale: imposta un filtro predefinito
      }),
      headerStyle: () => {
        return { width: '6%' };
      },
      text: t("Newsletter"),
      formatter: booleanFormatter,
      sort: true
    },
    {
      dataField: 'contest2024',
      filter: selectFilter({
        getFilter: (filter) => {
          contest2024Filter.current = filter;
        },
        style: {
          fontSize: '12px',
          fontStyle: 'normal',
        },
        options: booleanOptions,
        placeholder: "-",
        defaultValue: '' // Opzionale: imposta un filtro predefinito
      }),
      headerStyle: () => {
        return { width: '6%' };
      },
      text: t("Contest 2024"),
      formatter: booleanFormatter,
      sort: true
    },
  ]

  const convertArrayToObject = (array: any, key: any) => {
    const initialValue = {}
    return array.reduce((obj: any, item: any) => {
      return { ...obj, [item[key]]: item, }
    }, initialValue);
  }

  const handleTableChange = (type: any, newState: any) => {

    console.log("Tabella cambiata:", type)

    const { sortField, sortOrder } = newState;
    if (sortField && sortOrder) {
      const sortedData = [...data].sort((a, b) => {
        if (sortOrder === 'asc') {
          return a[sortField] > b[sortField] ? 1 : -1;
        } else {
          return a[sortField] < b[sortField] ? 1 : -1;
        }
      });
      
      // aggiorno i dati sulla base del nuovo ordinamento
      // tale aggiornamento provoca anche l'aggiornamento di filteredData
      // sulla base del range di date selezionate
      setData(sortedData);

      console.log("Tabella cambiata new sortedData:", sortedData)
      // Aggiorna l'ordine di selectedRows in base al nuovo ordinamento
      const updatedSelectedRows = sortedData.filter((row) =>
        selectedRows.some((selected: any) => selected.id === (row as any).id)
      );
      setSelectedRows(updatedSelectedRows);
    }
  };


  const handleRowSelect = (row: any, isSelected: any) => {
    const updatedSelection = isSelected
      ? [...selectedRows, row]
      : selectedRows.filter((r: any) => r.id !== row.id);
    setSelectedRows(updatedSelection);
  };

  const handleSelectAll = (isSelected: any, rows: any) => {
    setSelectedRows(isSelected ? rows : []);
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: handleRowSelect,
    onSelectAll: handleSelectAll,
    selected: selectedRows.map((row: any) => row.id)
  } as any;
  const isReady = (users != null && Object.keys(users).length > 0)
  const titleHeader = isReady ?
    `${t("Elenco degli utenti registrati")}  (${t("Totale")}: ${filteredDataSize} / ${users ? users.length : 0})` :
    `${t("Elenco degli utenti registrati")}`
  return (
    <Card className="mb-4" style={{ padding: "10px", borderColor: "#007bff" }}>
      <CardHeader style={{
        backgroundColor: "#007bff", borderColor: "#007bff",
        paddingBottom: 0, color: 'white'
      }}>
        <CardTitle tag="h5">{titleHeader}
          {isReady &&
            <UsersReportDownloader filteredDataRef={afterFilterData}  
                                   selectedRows={selectedRows}
                                   exportSelectedOnly = {exportSelectedOnly}
                                    />
          }

        </CardTitle>
      </CardHeader>
      <CardBody>

        <div style={{ display: "flex", flexDirection: "column" }}>
          {isReady && renderToolbar()}
          <>
            {
              isReady ?
                <BootstrapTable bootstrap4 keyField='id'
                  ref={tableRef}
                  data={filteredData || []}
                  columns={usersColumns}
                  selectRow={selectRow}
                  remote={{ sort: true }}
                  onTableChange={handleTableChange}
                  onDataSizeChange={(size) => {
                    console.log("TABLE FILTER SIZE:", size)
                    setFilteredDataSize(size["dataSize"])
                    setSelectedRows([])
                  }
                  }
                  filter={filterFactory({
                    afterFilter: (filteredData: any) => {
                      console.log("After filter:", filteredData); // Aggiorna lo stato con i dati filtrati
                      afterFilterData.current = filteredData;
                      //setSelectedRows([]); // provova Maximum deep exceptio
                    },
                  })}
                  filterPosition='top'
                  pagination={paginationFactory(paginationOptions)} />
                :
                <Alert>{t("Caricamento utenti in corso...")}</Alert>
            }
          </>
        </div>


      </CardBody>
    </Card>
  )
}