
import { Progress, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {useEffect} from "react";

export const Contest2024TraceAnalyzer = (props) => {
     const timelineInfo = props.timelineInfo;
    const { t } = useTranslation('frontend', { useSuspense: false });

    const renderTimelineIntervals = () => {
        if (!timelineInfo || !timelineInfo.durata) return null;

        return (
            <div>
                <Progress multi>
                    {timelineInfo.videoIntervals.map((segment, index) => (
                        <Progress
                            bar
                            key={index}
                            color={segment.color}
                            value={segment.percentage}
                        />
                    ))}
                </Progress>
            </div>
        );
    };

    useEffect(()=>{
        console.log("TimelineInfo:;", props.timelineInfo)
    }, [])
    if (!timelineInfo) return null;

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Label>Timeline: {timelineInfo.id}</Label>
            {renderTimelineIntervals()}
            <Label>{`${t('Durata')}: ${timelineInfo.durata.toFixed(2)} secs.`}</Label>
            <Label>{`${t('Copertura video')}: ${timelineInfo.coperturaVideo.info}`}</Label>
            <Label>{`${t('Allegati aperti')}: ${timelineInfo.numeroAllegatiAperti} ${t('di')} ${timelineInfo.numeroAllegati} (${timelineInfo.percentualeAllegatiAperti.toFixed(2)}%)`}</Label>
        </div>
    );
};

 