import { applyMiddleware, createStore, compose } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension' ///developmentOnly'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import runAllSagas from './sagas'
import { configuration } from '../config'
// import monitorReducersEnhancer from './enhancers/monitorReducers'
// import loggerMiddleware from './middleware/logger'
import { createRootReducer } from './reducers'
export const history = createBrowserHistory()

export const configureStore2 = (preloadedState: any) => {
  // const middlewares = [loggerMiddleware, thunkMiddleware]

  // Verifica la tua variabile d'ambiente personalizzata
  const useDevTools = ((configuration[(process.env.REACT_APP_STAGE as string)]["devToolsEnabled"] as boolean)===true)

  // Configura lo store con compose
//const composeEnhancers = useDevTools
//? (composeWithDevTools as any)
//: compose;

const composeEnhancers = useDevTools
  ? composeWithDevTools({
      maxAge: 50, // Mantiene solo le ultime 50 azioni
      trace: false, // Disabilita il tracciamento delle chiamate di stack
      traceLimit: 0, // Evita la memorizzazione eccessiva degli stack
      shouldRecordChanges: false, // Riduce l'uso di memoria evitando il salvataggio di ogni cambio di stato
      shouldCatchErrors: false, // Evita di registrare errori nel DevTools
    }) as any
  : compose;

  
  const sagaMiddleware = createSagaMiddleware()
  const middlewares: any = [routerMiddleware(history), sagaMiddleware]

  const middlewareEnhancer = applyMiddleware(...middlewares)

  // const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
  const enhancers = [middlewareEnhancer]
  //const composedEnhancers = composeWithDevTools(...enhancers)
  const composedEnhancers =  composeEnhancers(...enhancers)
  const rootReducer = createRootReducer(history)
  const store = createStore(rootReducer, preloadedState, composedEnhancers)
 
  if (process.env.NODE_ENV !== 'production' && (module as any).hot) {
    (module as any).hot.accept('./reducers', () => store.replaceReducer(rootReducer))
  }
 

  // then run the saga
  sagaMiddleware.run(runAllSagas)

  return store
}