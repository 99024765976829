
import { IT, GB } from 'country-flag-icons/react/3x2'
import { Form, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';
import { useState, useEffect } from "react";
import { END } from 'redux-saga';

export const I18nTextInput = (props) => {

    const [itValue, setItValue] = useState(props.itValue || "")
    const [enValue, setEnValue] = useState(props.enValue || "")

    useEffect(() => {
        props.onValueChanged &&
            props.onValueChanged({ itValue, enValue })
    }, [itValue, enValue])

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <IT style={{ "margin": "5px", border: "0px solid #555" }} width="25px" title="Italiano" />
                <Input invalid={props.required && itValue.length<1} id="itField" style={{ 'width': '100%' }} type={props.type || "text"}
                    value={itValue} onChange={(ev) => { setItValue(ev.target.value) }} />
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <GB style={{ "margin": "5px", border: "0px solid #555" }} width="25px" title="English" />
                <Input invalid={props.required && enValue.length<1} id="enField" style={{ 'width': '100%' }} type={props.type || "text"}
                    value={enValue} onChange={(ev) => { setEnValue(ev.target.value) }} />
            </div>
        </div>
    )
}