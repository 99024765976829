import * as React from 'react'
import { Formik, Form, Field, ErrorMessage, setNestedObjectValues } from 'formik';
import * as Yup from 'yup';
import {
  ButtonToolbar, ButtonGroup, Button, CardBody, CardHeader, CardTitle, FormGroup,
  Alert, Modal, ModalHeader, ModalBody, ModalFooter, Navbar, Nav, NavLink, CardSubtitle, NavbarBrand, Container, Col, Row, InputGroup, InputGroupAddon, InputGroupText, CardImg,
  Card, CardFooter, Spinner
} from 'reactstrap'
import { useSelector, useDispatch } from "react-redux";
import { push } from 'connected-react-router';
import * as ContainerActions from '../store/actions/container';
import * as UiActions from '../store/actions/ui';
import { Link } from 'react-router-dom'
import { Header, Footer } from '../header'
import { Content } from '../components/Content';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { RouteConst } from '../consts/common';
import 'react-block-ui/style.css';
import { selectors as ProfileSelectors } from '../store/slices/profile'
import { selectors as ExperimentsSelector, actions as ExperimentsActions } from '../store/slices/experiments'
import { selectors as AuthSelectors } from '../store/slices/auth'
import { selectors as CatalogSelectors} from '../store/slices/catalog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from "react-tooltip";
import { FaTrashAlt, FaClone, FaEdit, FaLink } from "react-icons/fa";
import { TooltipButton } from '../components/ActivityButton';
import { useTranslation } from 'react-i18next';
import {
  StyledLabel as Label,
  StyledInput as Input,
  StyledInlineErrorMessage,
  StyledButton,
} from "../styles/styles";
import moment from 'moment';
import { IFrameCatalogPage } from './IFrameCatalog';
import { IT, GB } from 'country-flag-icons/react/3x2'

var _ = require('lodash');

export const TextAreaField: React.FC = (props) => {
  return (
    <Field component="textarea" rows={3} {...props} />
  )
}


const PublicExperimentsModalViewer = (props: any) => {
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });


  const paginationOptions = {
    sizePerPageList: [{
      text: '5', value: 5
    }, {
      text: '10', value: 10
    },
    {
      text: '20', value: 20
    }
    ], pageStartIndex: 1
  }

  const publishDateFormatter = (cell: any, row: any) => {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        {moment(cell).format('YYYY/MM/DD, HH:mm:ss')}
      </div>
    )
  }

  const linkFormatter = (cell: any, row: any) => {
     //console.log("Cliccato su esperimento con id:", row.id);
     const localizedTitles = cell.split("||")
     let localizedTitle = localizedTitles[0].trim()
     if (localizedTitles.length>1)
        {
         if (i18n.language!="it-IT")
           localizedTitle = localizedTitles[1].trim()
        }
 
        const localizedDescriptions = row.description.split("||") 
        let localizedDescription = (i18n.language=="it-IT" ?  localizedDescriptions[0].trim() : "");
        if (localizedDescriptions.length>1)
           {
            if (i18n.language!="it-IT")
             localizedDescription = localizedDescriptions[1].trim()
           }
    return (
      <>
        <a data-for="linkExperiment" data-tip={localizedDescription}
          href={`/public/${row.experiment}`}>{localizedTitle} </a>

        <ReactTooltip id="linkExperiment" />
      </>)
  }

  const publicExperimentColumns = [
    {
      dataField: 'experiment',
      text: '',
      hidden: true,
      headerAlign: 'center'
    },

    {
      dataField: 'title',
      text: t('titolo'),
      formatter: linkFormatter,
      sort: true,
      headerAlign: 'center'
    },

    {
      dataField: 'creationDate',
      text: t("Data di pubblicazione"),
      formatter: publishDateFormatter,
      headerAlign: 'center',
      sort: true
    }
  ];


  return (<Modal isOpen={props.isOpen} toggle={props.toggle}>
    <ModalHeader>
      {`${t("Versioni pubbliche di")} "${props.publicExperiments.parent?.title}" (${props.publicExperiments.items?.length || 0})`}
    </ModalHeader>
    <ModalBody>
      <BootstrapTable bootstrap4 keyField='id' 
        key={`publictimelineTable_${i18n.language}`}
        data={props.publicExperiments.items}
        columns={publicExperimentColumns}
        pagination={paginationFactory(paginationOptions)}

      />
    </ModalBody>
    <ModalFooter style={{
      display: "flex",
      justifyContent: "right"
    }}>
      <Button color="primary" onClick={() => {
        props.toggle();
      }}>{t("Chiudi")}</Button>

    </ModalFooter>
  </Modal>
  )
}

export const TimelineHome: React.FC = () => {
  const experimentsSel = useSelector(ExperimentsSelector.getExperiments)
  const publicExperiments = useSelector(ExperimentsSelector.getPublicExperiments)
  const catalogExperiments = useSelector(CatalogSelectors.getExperiments)
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });

  const ExperimentsSchema = Yup.object().shape({
    title_IT: Yup.string()
      .min(4, t("Campo troppo corto!"))
      .max(50, t("Campo troppo lungo!"))
      .required(t('Campo obbligatorio')),
      title_EN: Yup.string()
      .min(4, t("Campo troppo corto!"))
      .max(50, t("Campo troppo lungo!"))
      .required(t('Campo obbligatorio')),
    description_IT: Yup.string(),
    description_EN: Yup.string()
  });

  const dispatch = useDispatch();
  const [experimentsLoaded, setExperimentsLoaded] = React.useState<boolean>(false);
  const [currentPublicExperiments, setCurrentPublicExperiments] = React.useState<any>({
    "parent": null,
    "items": []
  });
  const [isPEModalOpen, setPEModalOpen] = React.useState<boolean>(false);

  const togglePEM = () => {
    setPEModalOpen(!isPEModalOpen);
  };

  const loadPublicExperiments = () => {
    if (experimentsSel == null) return;
    for (let i = 0; i < experimentsSel.length; i++) {
      //console.log("PEXP: loading for experiment:", experimentsSel[i].experiment)
      dispatch(ExperimentsActions.willLoadPublicExperiments(experimentsSel[i].experiment));
    }
  }

  React.useEffect(() => {

    //console.log("PUBLIC EXPERIMENTS:", publicExperiments)
  }, [publicExperiments])

  React.useEffect(() => {
    if (experimentsSel != null) {
      loadPublicExperiments();
      setExperimentsLoaded(true)
    };
  }, [experimentsSel])

  React.useEffect(() => {
    dispatch(UiActions.saveButtonAction("NO CHANGES"))
    dispatch(ExperimentsActions.willLoadExperiments());
    //console.log("catalog experiments:", catalogExperiments)
  }, [])
  //const userID = useSelector(ProfileSelectors.getProfileData).profile.sub
  const userProfile = useSelector(ProfileSelectors.getProfile)
  const isEditor = useSelector(ProfileSelectors.isEditor)
  const userID = userProfile.sub

  //console.log("Timeline Container userID:", userID);
  const [modalEdit, setModalEdit] = React.useState(false);
  const [modalParams, setModalParams] = React.useState<any>({})

  const toggleEdit = (value: any) => {
    setModalEdit(!modalEdit);
    setModalParams(value);
    console.log('in modal edit', value);
  };
  const [modalDelete, setModalDelete] = React.useState(false);
  const [modalDeleteExperiment, setModalDeleteExperiment] = React.useState<any>({})
  const [publicUrlCopied, setPublicUrlCopied] = React.useState(false);

  const toggleModalDelete = (value: any) => {
    setModalDelete(!modalDelete);
    setModalDeleteExperiment(value);
  }


  const [modalClone, setModalClone] = React.useState(false);
  const [modalCloneExperiment, setModalCloneExperiment] = React.useState<any>({})

  const toggleModalClone = (value: any) => {
    setModalClone(!modalClone);
    setModalCloneExperiment(value);
  }

  const paginationOptions = {
    sizePerPageList: [{
      text: '5', value: 5
    }, {
      text: '10', value: 10
    },
    {
      text: '20', value: 20
    },
    {
      text: '50', value: 50
    },
    {
      text: '100', value: 100
    }
    ], pageStartIndex: 1
  }

  const actionsFormatter = (cell: any, value: any) => {
    //console.log("Passo value:", value);
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>

        <ButtonToolbar>
          <ButtonGroup>
            <FaEdit data-for="editExperiment" data-tip={t("modifica")} cursor="pointer"
              style={{ margin: "10px" }} color="#007bff" onClick={() => { toggleEdit(value.experiment) }}>{t('modifica')}</FaEdit>

            <FaClone data-for="cloneExperiment" data-tip={t("clona")} cursor="pointer"
              style={{ margin: "10px" }} color="#007bff"
              onClick={() => { toggleModalClone(value); }}>{t('clona')}</FaClone>

            <FaTrashAlt data-for="deleteExperiment" data-tip={t("elimina")} cursor="pointer"
              style={{ margin: "10px" }} color='red'
              onClick={() => { toggleModalDelete(value.experiment.experiment) }} />


          </ButtonGroup>

        </ButtonToolbar>
        <ReactTooltip id="editExperiment" />
        <ReactTooltip id="cloneExperiment" />
        <ReactTooltip id="deleteExperiment" />
      </div>

    )
  }

  const linkFormatter = (cell: any, row: any) => {
    //console.log("Cliccato su esperimento con id:", row.id);
    const localizedTitles = cell.split("||")
    let localizedTitle = localizedTitles[0].trim()
    if (localizedTitles.length>1)
       {
        if (i18n.language!="it-IT")
          localizedTitle = localizedTitles[1].trim()
       }

       const localizedDescriptions = row.experiment.description.split("||") 
       let localizedDescription = (i18n.language=="it-IT" ?  localizedDescriptions[0].trim() : "");
       if (localizedDescriptions.length>1)
          {
           if (i18n.language!="it-IT")
            localizedDescription = localizedDescriptions[1].trim()
          }
    return (
      <>
        { /* le seguenti tecniche provocano dei caricamenti della timeline in loop!
<Link data-for="linkExperiment" to={`/timeline/${row.id}`}>{cell}</Link> 
  <span onClick={(ev)=>{dispatch(push(`/timeline/${row.id}`));}} data-for="linkExperiment" data-tip={row.experiment.description}  
  >{cell} </span> 
*/}

        <a data-for="linkExperiment" data-tip={localizedDescription}
          href={`/timeline/${row.id}`}>{localizedTitle} </a>

        <ReactTooltip id="linkExperiment" />
      </>)
  }

  React.useEffect(() => {
    //console.log("publicUrlCopied:",publicUrlCopied);
    if (publicUrlCopied == true) {
      setTimeout(() => { setPublicUrlCopied(false) }, 5000)
    }

  }, [publicUrlCopied])

  const lastChangeFormatter = (cell: any, row: any) => {
    return moment(cell).format('YYYY/MM/DD, HH:mm:ss');
  }

  const publishedVersionsFormatter = (cell: any, row: any) => {
    if (cell > 0) {
      return (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button color="link"
              data-for="showPublicTimelines"
              data-tip={t("mostra tutte le versioni pubblicate")}
              onClick={(ev) => {
                setCurrentPublicExperiments({ "parent": row.experiment, "items": publicExperiments[row.id] });
                setPEModalOpen(true);
              }}>
              {cell}
            </Button>
            <TooltipButton duration={3000}
              tooltipMessage={`${t("Copia link alla ultima versione della timeline pubblica")}`}
              onClickMessage={t('Copiato')}
              onChildClick={async () => {
                const baseUrl = window.location.href;
                const publicLinkUrl = `${baseUrl.slice(0, baseUrl.lastIndexOf("/"))}${RouteConst.Public}/${row.id}/latest`;
                //alert(publicLinkUrl);
                await navigator.clipboard.writeText(publicLinkUrl);
              }}
            >
              <FaLink cursor="pointer"
                style={{ margin: "15px", border: '1px solid #007bff"', }} color="#007bff" />
            </TooltipButton>
          </div>
          <ReactTooltip id="showPublicTimelines" />
        </>
      )

    }
    else
      return <span>{cell}</span>
  }

  const startEndFormatter = (cell: any, row: any) => {
    return moment(cell).format("LL")
  }

  const defaultSortedBy = [{
    dataField: "lastChange",
    order: "desc"  // asc or desc
  }];

  const experimentColumns = [
    {
      dataField: 'experiment',
      text: '',
      hidden: true
    },

    {
      dataField: 'id',
      text: 'id',
      hidden: true
    },
    {
      dataField: 'title',
      text: t('titolo'),
      formatter: linkFormatter,
      sort: true
    },
    /*
     {
       dataField: 'startDate',
       text: t('inizio'),
       formatter: startEndFormatter,
       headerStyle: () => {
         return { width: '15%'};
       },
       sort: true
     },
   
     {
       dataField: 'endDate',
       text: t('fine'),
       formatter: startEndFormatter,
       headerStyle: () => {
         return { width: '15%'};
       },
       sort: true
     },
   
     */

    {
      dataField: 'creationDate',
      text: t("Data di creazione"),
      formatter: lastChangeFormatter,
      sort: true,
      sortFunc: (a: any, b: string, order: string) => {
        const isBefore = (order == "asc") && moment(a).isBefore(moment(b)) || (order != "asc" && moment(b).isBefore(moment(a)))
        return (isBefore ? -1 : 1)
      }
    },

    {
      dataField: 'lastChange',
      text: t('Ultima modifica'),
      formatter: lastChangeFormatter,
      sort: true,
      sortFunc: (a: any, b: string, order: string) => {
        const isBefore = (order == "asc") && moment(a).isBefore(moment(b)) || (order != "asc" && moment(b).isBefore(moment(a)))
        return (isBefore ? -1 : 1)
      }
    },

    {
      dataField: 'publishedVersions',
      text: t('Versioni pubbliche'),
      formatter: publishedVersionsFormatter,
      sort: true
    },
    {
      dataField: 'actions',
      text: t('azioni'),
      formatter: actionsFormatter,
      headerStyle: () => {
        return { width: '10%' };
      },
      headerAlign: 'center'
    },
  ];

  const orderedExperiments = _.orderBy(experimentsSel, [(experiment: any) => experiment.title.toUpperCase()], ["asc"]);
  //belongsToOrganization(userProfile, value.owner) && 
  const experimentRows = orderedExperiments.filter((value: any, i: number) => {
    return value.status !== "DELETED"
  }).map((value: any, index: number) => {
    const publishedVersions = publicExperiments[value.experiment]?.length || 0;
    const lastChange = value.updateDate != null ? value.updateDate : value.creationDate;
    return {
      "experiment": {...value, "catalogExperiment" : catalogExperiments[value.catalogExperimentId]}, 
      "id": value.experiment, 
      "title" :  value.title,
      "title_IT": value.title.split("||")[0],
      "title_EN": value.title.split("||")[1] || value.title.split("||")[0],
      "startDate": value.experimentStartTime,
      "creationDate": value.creationDate,
      "endDate": value.experimentStopTime,
      "publishedVersions": publishedVersions,
      "lastChange": lastChange
    }
  });

  const [modal, setModal] = React.useState(false);
  const isStudent = useSelector(AuthSelectors.isStudent);
  const toggle = () => setModal(!modal);



  return (
    // <BlockUi tag="div" loader={<div><h1>Loading...</h1></div>} blocking={(uiStatus === true)}>
    <>
      <Header className="mb-0 text-white" section="Timeline" showMenu={false} />
      <Content active="timeline" section={"my_timelines"} className="pt-3">


        <Row className="m-0" style={{ height: 'calc(100% - 22px)' }}>
          <Col xs="12" style={{ height: '100%' }}>
            <Card className="mb-4" style={{ height: '100%', borderColor: "#007bff" }}>
              <CardHeader style={{ padding: 0, backgroundColor: "#007bff", borderColor: "#007bff", paddingBottom: 0, color: 'white', display: 'flex' }}>
                <CardTitle tag="h5" style={{ marginLeft: "20px", paddingTop: "10px", paddingBottom: "10px", flexGrow: 1, marginTop: 'auto', marginBottom: 'auto' }}>{t("MyTimelines")}</CardTitle>

                <ButtonToolbar>
                  <ButtonGroup >
                    {isEditor &&
                      <Button onClick={toggle} style={{ height: 34, marginRight: 12, marginTop: 6, marginBottom: 6, borderWidth: 1, borderColor: 'white', borderStyle: 'solid', borderRadius: 4 }} color="primary"><FontAwesomeIcon icon={faPlus as any} /> {t("AGGIUNGI")}</Button>
                    }
                  </ButtonGroup>
                </ButtonToolbar>

              </CardHeader>
              <CardBody>

                <div className="App">
                  <Container fluid>
                    {experimentsLoaded ?
                      <Row>
                        <Col sm="12">
                          <Row>
                            <Col sm={12}>
                              {
                                //isStudent ? (<p>{t("Accesso negato")}</p>) :
                                (experimentRows.length > 0 ?
                                  <BootstrapTable bootstrap4
                                    key={`timelineTable_${i18n.language}`}
                                    keyField='id'
                                    data={experimentRows}
                                    columns={experimentColumns}
                                    defaultSorted={defaultSortedBy as any}
                                    pagination={paginationFactory(paginationOptions)}
                                  />
                                  :
                                  <Alert color="info">
                                    {t("Non è presente alcuna Timeline")}
                                  </Alert>
                                )
                              }
                            </Col>
                          </Row>
                        </Col>
                      </Row> :

                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <Spinner />
                      </div>

                    }
                  </Container>

                  <PublicExperimentsModalViewer
                    isOpen={isPEModalOpen}
                    toggle={togglePEM}
                    publicExperiments={currentPublicExperiments as any} />


                  <Modal isOpen={modal} toggle={toggle} >
                    <ModalHeader toggle={toggle}>{t('aggiungi_esperimenti')}</ModalHeader>
                    <ModalBody>
                      <Formik
                        initialValues={{
                          title_IT: '',
                          title_EN: '',
                          description_IT: '',
                          description_EN: '',
                          catalogExperiment: (null as any)
                          
                        }}
                        validationSchema={ExperimentsSchema}
                        validateOnBlur={true}
                        onSubmit={values => {

                          //console.log("willAddExperiment values: ", values);
                          dispatch(ExperimentsActions.willAddExperiment({
                            title: `${values.title_IT}||${values.title_EN}`,
                            catalogExperimentId: values.catalogExperiment?.id,
                            description: `${values.description_IT}||${values.description_EN}`,
                            experimentStartTime: moment(new Date()).format("YYYY-MM-DD"),
                            experimentStopTime: moment(new Date()).add(1, "hours").format("YYYY-MM-DD"),
                            sections: [{ "title_IT": values.title_IT, 
                              "title_EN": values.title_EN, 
                              "description_IT": values.description_IT, "description_EN": values.description_EN,  "items": [] }]
                          }

                          ));
                          setModal(!modal)
                        }}
                      >
                        {({ errors, touched, setFieldValue, values }) => (
                          <Container>
                            <Form name="create_quote" method="post">
                            <Row>
                              <Label sm={3}><b>{t('titolo')}</b></Label>
                            </Row>
                              <FormGroup row>
          
                                <Col>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                  <IT style={{ "margin": "5px", border: "0px solid #555" }} width="25px" title="Italiano" />
                                  <Input name="title_IT" type="text" placeholder={t('titolo')} tag={Field} />
                                </div>
                                  {errors.title_IT && touched.title_IT ?
                                    <StyledInlineErrorMessage>{errors.title_IT}</StyledInlineErrorMessage> : null}
                                </Col>
                              </FormGroup>

                              <FormGroup row>
                              <Col >
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                  <GB style={{ "margin": "5px", border: "0px solid #555" }} width="25px" title="English" />
                                  <Input name="title_EN" type="text" placeholder={t('titolo')} tag={Field} />
                                </div>
                                {errors.title_EN && touched.title_EN ?
                                  <StyledInlineErrorMessage>{errors.title_EN}</StyledInlineErrorMessage> : null}
                              </Col>
                            </FormGroup>
                            <Row>
                              <Label sm={3}><b>{t('descrizione')}</b></Label>
                            </Row>
                            <FormGroup row>
                              <Col >
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                  <IT style={{ "margin": "5px", border: "0px solid #555" }} width="25px" title="Italiano" />
                                  <Input name="description_IT" type="textarea" placeholder={t('descrizione')} tag={TextAreaField} />
                                </div>
                                {errors.description_IT && touched.description_IT ?
                                  <StyledInlineErrorMessage>{errors.description_IT}</StyledInlineErrorMessage> : null}
                              </Col>
                            </FormGroup>

                            <FormGroup row>
                              <Col >
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                  <GB style={{ "margin": "5px", border: "0px solid #555" }} width="25px" title="English" />
                                  <Input name="description_EN" type="textarea" placeholder={t('descrizione')} tag={TextAreaField} />
                                </div>
                                {errors.description_EN && touched.description_EN ?
                                  <StyledInlineErrorMessage>{errors.description_EN}</StyledInlineErrorMessage> : null}
                              </Col>
                            </FormGroup>

                              <FormGroup row>
                                <Label sm={3}>{t('Esperimento')}</Label>
                                <Col sm={9}>
                                  <Input name="catalogExperiment" type="textarea" disabled={true}
                                    value={values.catalogExperiment?.titolo || ""}
                                    tag={TextAreaField} />
                                 <IFrameCatalogPage title={t("Seleziona esperimento")} showAsTree
                                    onExperimentSelected={(catalogExperiment: any) => {
                                      //console.log("selected experiment:", catalogExperiment)
                                      setFieldValue("catalogExperiment", catalogExperiment);
                                    }} />
                                </Col>
                              </FormGroup>
                              
                              <FormGroup row>
                                <Col sm={9}>
                                  <StyledButton block color="primary" type="submit" >{t('conferma_aggiungi_esperimenti')}</StyledButton>{' '}
                                </Col>
                                <Col sm={3}>
                                  <Button color="secondary" onClick={toggle}>{t('cancel')}</Button>
                                </Col>
                              </FormGroup>
                            </Form>
                          </Container>
                        )}
                      </Formik>
                    </ModalBody>
                  </Modal>

                  <Modal isOpen={modalEdit} toggle={toggleEdit} >
                    <ModalHeader toggle={toggleEdit}>{t('modifica_gruppo_esperimenti')}</ModalHeader>
                    <ModalBody>
                      <Formik
                        initialValues={{
                          title_IT: modalParams.title?.split("||")[0] || "TITOLO NON TROVATO",
                          title_EN: (modalParams.title?.split("||").length>1) ?
                              modalParams.title.split("||")[1] : modalParams.title?.split("||")[0] || "",
                          description_IT: modalParams.description?.split("||")[0] || "",
                          description_EN: (modalParams.description?.split("||").length>1) ?
                                  modalParams.description.split("||")[1] : modalParams.description?.split("||")[0] || "",
                          catalogExperiment: modalParams.catalogExperiment
                        }}
                        validationSchema={ExperimentsSchema}
                        validateOnBlur={true}
                        onSubmit={values => {
                          //console.log("experiment to update values: ", values);
                          //console.log("experiment to update values (MP): ", modalParams);
                          dispatch(ExperimentsActions.willUpdateExperiment(
                            {
                              owner: modalParams.owner,
                              experiment: modalParams.experiment,
                              catalogExperimentId: values.catalogExperiment?.id,
                              title: `${values.title_IT}||${values.title_EN}`,
                              description: `${values.description_IT}||${values.description_EN}`,
                              experimentStartTime: (modalParams.experimentStartTime || moment(new Date()).format("YYYY-MM-DD")),
                              experimentStopTime: (modalParams.experimentStopTime || moment(new Date()).add(1, "hours").format("YYYY-MM-DD")),
                            }

                          ));
                          setModalEdit(!modalEdit)
                        }}
                      >
                        {({ errors, touched, setFieldValue, values }) => (
                          <Container>
                            <Form name="create_quote" method="post">
                            <Row>
                              <Label sm={3}><b>{t('titolo')}</b></Label>
                            </Row>
                              <FormGroup row>
          
                                <Col>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                  <IT style={{ "margin": "5px", border: "0px solid #555" }} width="25px" title="Italiano" />
                                  <Input name="title_IT" type="text" placeholder={t('titolo')} tag={Field} />
                                </div>
                                  {errors.title_IT && touched.title_IT ?
                                    <StyledInlineErrorMessage>{errors.title_IT}</StyledInlineErrorMessage> : null}
                                </Col>
                              </FormGroup>

                              <FormGroup row>
                              <Col >
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                  <GB style={{ "margin": "5px", border: "0px solid #555" }} width="25px" title="English" />
                                  <Input name="title_EN" type="text" placeholder={t('titolo')} tag={Field} />
                                </div>
                                {errors.title_EN && touched.title_EN ?
                                  <StyledInlineErrorMessage>{errors.title_EN}</StyledInlineErrorMessage> : null}
                              </Col>
                            </FormGroup>
                            <Row>
                              <Label sm={3}><b>{t('descrizione')}</b></Label>
                            </Row>
                            <FormGroup row>
                              <Col >
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                  <IT style={{ "margin": "5px", border: "0px solid #555" }} width="25px" title="Italiano" />
                                  <Input name="description_IT" type="textarea" placeholder={t('descrizione')} tag={TextAreaField} />
                                </div>
                                {errors.description_IT && touched.description_IT ?
                                  <StyledInlineErrorMessage>{errors.description_IT}</StyledInlineErrorMessage> : null}
                              </Col>
                            </FormGroup>

                            <FormGroup row>
                              <Col >
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                  <GB style={{ "margin": "5px", border: "0px solid #555" }} width="25px" title="English" />
                                  <Input name="description_EN" type="textarea" placeholder={t('descrizione')} tag={TextAreaField} />
                                </div>
                                {errors.description_EN && touched.description_EN ?
                                  <StyledInlineErrorMessage>{errors.description_EN}</StyledInlineErrorMessage> : null}
                              </Col>
                            </FormGroup>

                              <FormGroup row>
                                <Label sm={3}>{t('Esperimento')}</Label>
                                <Col sm={9}>
                                  <Input name="catalogExperiment" type="textarea" disabled={true}
                                    value={values.catalogExperiment?.titolo || ""}
                                    tag={TextAreaField} />
                                 <IFrameCatalogPage title={t("Seleziona esperimento")} showAsTree
                                    onExperimentSelected={(catalogExperiment: any) => {
                                      console.log("selected experiment:", catalogExperiment)
                                      setFieldValue("catalogExperiment", catalogExperiment);
                                    }} />
                                </Col>
                              </FormGroup>

                              <FormGroup row>
                                <Label sm={3}>{t('Esperimento')}</Label>
                                <Col sm={9}>
                                  <Input name="catalogExperiment" type="textarea" disabled={true}
                                    value={values.catalogExperiment?.titolo || ""}
                                    tag={TextAreaField} />
                                 <IFrameCatalogPage title={t("Seleziona esperimento")} showAsTree
                                    onExperimentSelected={(catalogExperiment: any) => {
                                      console.log("selected experiment:", catalogExperiment)
                                      setFieldValue("catalogExperiment", catalogExperiment);
                                    }} />
                                </Col>
                              </FormGroup>

                              <FormGroup row>
                                <Col sm={9}>
                                  <StyledButton block color="primary" type="submit" >{t('confirm_edit_container')}</StyledButton>{' '}
                                </Col>
                                <Col sm={3}>
                                  <Button color="secondary" onClick={toggleEdit}>{t('cancel')}</Button>
                                </Col>
                              </FormGroup>


                            </Form>

                          </Container>
                        )}
                      </Formik>
                    </ModalBody>
                  </Modal>

                  <Modal isOpen={modalDelete} toggle={toggleModalDelete} >
                    <ModalHeader toggle={toggleModalDelete}>{t('elimina_gruppo_esperimenti')}</ModalHeader>
                    <ModalBody>{t('confermare_elimina_gruppo_esperimenti')}</ModalBody>
                    <ModalFooter>
                      <Button color="danger" style={{ float: "right" }}
                        onClick={() => {
                          dispatch(ExperimentsActions.willDeleteExperiment(
                            { experiment: modalDeleteExperiment }));
                          setModalDelete(!modalDelete);
                        }}>{t('elimina')}</Button>{' '}
                      <Button color="info" onClick={toggleModalDelete} style={{ float: "right" }}>{t('cancel')}</Button>{' '}
                    </ModalFooter>
                  </Modal>

                  <Modal isOpen={modalClone} toggle={toggleModalClone} >
                    <ModalHeader toggle={toggleModalClone}>{t('clona_esperimento')}</ModalHeader>
                    <ModalBody>{t('confermare_clona_esperimento')}</ModalBody>
                    <ModalFooter>
                      <Button color="danger" style={{ float: "right" }}
                        onClick={() => {

                          const titles = modalCloneExperiment.experiment.title.split("||")
                                const cloneTitle = (titles.length>1 ?
                                
                                `Copia di ${titles[0]}||Copy of ${titles[1]}`:
                               `Copia di ${titles[0]}||Copy of ${titles[0]}`)
                          //console.log("PASSATO OGGETTO PER CLONE:::", modalCloneExperiment)
                          dispatch(ExperimentsActions.willCloneExperiment(
                            {
                              experiment: modalCloneExperiment.experiment.experiment, owner: modalCloneExperiment.experiment.owner,
                              title: `${cloneTitle}`, description: `${modalCloneExperiment.experiment.description}`
                            }))

                          setModalClone(!modalClone);
                        }}>{t('clona')}</Button>{' '}
                      <Button color="info" onClick={toggleModalClone} style={{ float: "right" }}>{t('cancel')}</Button>{' '}
                    </ModalFooter>
                  </Modal>



                </div>

              </CardBody>
              <CardFooter>
                <Footer />
              </CardFooter>
            </Card>
          </Col>
        </Row>

      </Content>

    </>
    // </BlockUi>

  );
}
