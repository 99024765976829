//https://medium.com/dailyjs/data-visualization-libraries-for-react-developers-in-2019-a2b9c01262f8

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { AnalyticsSessionsAnalyzer } from '../components/AnalyticsSessionsAnalyzer';
import { Content } from '../components/Content';
import { DataAnalyzer } from '../components/DataAnalyzer';
import { ContestSchoolsAnalyzer } from '../components/Contest2024/ContestSchoolsAnalyzer';
import { Header } from '../header';
import Contest2024Partecipants from '../components/Contest2024/Contest2024Partecipants';

export const DataAnalyzerPage = () => {

  const [activeTab, setActiveTab] = useState("0");
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });

 
  return (
    <>
      <Header className="mb-0 text-white" section={t("Analisi statistiche")} showMenu={false} />
      <Content active="data_analytics"  className="pt-3">
      <Nav tabs>
          <NavItem>
            <NavLink  style={activeTab === '0' ? 
            {cursor:"arrow" , fontWeight:"bold" , background:"#EEEEEE"} : {cursor:"pointer", fontWeight:"normal"}}
               
              onClick={() => { setActiveTab('0'); }}
            >
              {t("Sessioni sincrone")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink  
              style={activeTab === '1' ? 
              {cursor:"arrow" , fontWeight:"bold" , background:"#EEEEEE"} : {cursor:"pointer", fontWeight:"normal"}}
              onClick={() => { setActiveTab("1"); }}
            >
              {t("Sessioni analitiche")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink  
              style={activeTab === '2' ? 
              {cursor:"arrow" , fontWeight:"bold" , background:"#EEEEEE"} : {cursor:"pointer", fontWeight:"normal"}}
              onClick={() => { setActiveTab("2"); }}
            >
              {`${t("Contest 2024")} - (${t("Classifica")})`}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink  
              style={activeTab === '3' ? 
              {cursor:"arrow" , fontWeight:"bold" , background:"#EEEEEE"} : {cursor:"pointer", fontWeight:"normal"}}
              onClick={() => { setActiveTab("3"); }}
            >
              {`${t("Contest 2024")} - (${t("Partecipanti")})`}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
        <TabPane tabId="0">
        <DataAnalyzer />
          </TabPane>
          <TabPane tabId="1">
          <AnalyticsSessionsAnalyzer/>
          </TabPane>
          <TabPane tabId="2">
          <ContestSchoolsAnalyzer/>
          </TabPane>
          <TabPane tabId="3">
          <Contest2024Partecipants/>
          </TabPane>
          </TabContent>
          </Content>
    </>
  )
}