import React, { useEffect, useState } from 'react';
import {
 Alert
} from 'reactstrap'

import { Header } from '../header'
import { Content } from '../components/Content';
import { useTranslation } from 'react-i18next';
import { RialeGeoNet } from '../components/OS_LabsMap';
import { selectors as ProfileSelectors } from '../store/slices/profile'
import { useSelector } from 'react-redux';


export const MapsHomepage = () => {
    const userAttributes:any = useSelector(ProfileSelectors.getProfile);
    const { t, i18n } = useTranslation('frontend', { useSuspense: false });
    const [activeTab, setActiveTab] = useState("0");
    const isPageVisible = () => { 
        return true //userAttributes!=null && userAttributes.groups!=null && userAttributes.groups.includes("director")
        };
    const isDirector = () => { 
        return userAttributes!=null && userAttributes.groups!=null && userAttributes.groups.includes("director")
        };
   
        return (
        <>
            <Header className="mb-0 text-white" section={t("Mappa")} showMenu={false} />
            <Content active="map" className="pt-3">
           {isPageVisible() ? 
            <RialeGeoNet onlyLabs={isDirector() ? false : true} /> : 
            <Alert style={{"marginTop":"10px"}} className="text-center" color="danger">{t("Questa sezione è accessibile solo agli amministratori della piattaforma")}</Alert>
           }
            </Content>
        </>
    )
}