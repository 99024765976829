import {configuration} from "../config";
import { cloneDeep } from 'lodash'; 
import i18n from '../i18n';

const stage: string = process.env.REACT_APP_STAGE != undefined ? process.env.REACT_APP_STAGE : "dev"

export const getCatalog = async () => {
  //console.log("RELOAD catalog from API with language:", i18n.language);
  const catalogUrl = i18n.language.startsWith("en") ? configuration[stage].catalog_EN : configuration[stage].catalog_IT
  const response = await fetch(catalogUrl, {
    method: "GET",
    headers: {
        "Cache-Control": "no-cache", // Evita problemi di caching
        "Accept": "application/json"
    }
});

const text = await response.text(); // Leggi il file come testo
console.log("JSON RAW:", text.length, "bytes"); // Verifica la dimensione del JSON
let catalogAndExperimentsJson = JSON.parse(text);
  
  //@audit info LocalCatalog or Remote Catalog
  //let catalogAndExperimentsJson = await cJson.json();
  console.log("Json del catalog:", catalogAndExperimentsJson);
  //let catalogAndExperimentsJson = localCatalog as any;
  // catalogo ed esperimenti (json completo)
  let catalogJson = catalogAndExperimentsJson["catalog"] as any
  // dizionario degli esperimenti
  const catalogExperiments = catalogAndExperimentsJson["esperimenti"];
  //console.log("catalogexperiments:", catalogExperiments)
  let experimentTopics = {} as any;
  // catalogo con le liste degli esperimenti da riempire (id->obj)
  let filledCatalog = []
 
  for (let i=0; i<catalogJson.length;i++)
    {
      filledCatalog.push(cloneDeep(catalogJson[i]))
      for (let j=0;j< catalogJson[i]["esperimenti"].length;j++)
      { 
       
       // se ho un percorso....
        if (typeof(catalogJson[i]["esperimenti"][j])=="object")
        { 
          ////console.log("Trovato percorso di esperimenti:", catalogJson[i]["esperimenti"][j]);
        let pathExperiments = [] as any;
        let filledExperimentsPath =cloneDeep(catalogJson[i]["esperimenti"][j]);
        const pathExperimentsId = catalogJson[i]["esperimenti"][j]["esperimenti"];
        for(let p=0;p<pathExperimentsId.length;p++)
        {
          // Aggiungo il topic corrente all'esperimento
          if (experimentTopics[pathExperimentsId[p]]==null)
          {
            experimentTopics[pathExperimentsId[p]] = [catalogJson[i]["titolo"]]
          }
          else
          {
            experimentTopics[pathExperimentsId[p]].push(catalogJson[i]["titolo"])
          }
          pathExperiments.push(catalogExperiments[pathExperimentsId[p]]);
        }
        filledExperimentsPath["esperimenti"] = pathExperiments;
        filledCatalog[i]["esperimenti"][j] = filledExperimentsPath;
        }
        // se ho un esperimento semplice
        else
        {
          filledCatalog[i]["esperimenti"][j] = catalogExperiments[catalogJson[i]["esperimenti"][j]];
          // Aggiungo il topic corrente all'esperimento
          if (experimentTopics[catalogJson[i]["esperimenti"][j]]==null)
          {
            experimentTopics[catalogJson[i]["esperimenti"][j]] = [catalogJson[i]["titolo"]]
          }
          else
          {
            experimentTopics[catalogJson[i]["esperimenti"][j]].push(catalogJson[i]["titolo"])
          }
       
       
        }
        
      }
    }
    //console.log("Updated Catalog:", filledCatalog);
    //console.log("Dizionario dei topics:", experimentTopics)

    // aggiungo i topic ai vari esperimenti
    const ids = Object.keys(experimentTopics);
    for (let i=0;i<ids.length;i++)
    {
      catalogExperiments[ids[i] as any]["topic"] = experimentTopics[ids[i]]
    }

    //console.log("Dizionario degli esperimenti:", catalogExperiments)

    return {"catalog" : filledCatalog, "esperimenti" : catalogExperiments , "labs" : catalogAndExperimentsJson["labs"] || {} }
  }