import { RialeGeoNet } from "../OS_LabsMap"
import MobileContainer from "./MobileContainer"

export const MobileMap = (props) =>{
    return (
        <MobileContainer>
            <div style={{border:"1px solid black", position:"relative", height:"60vh"}}>
            <RialeGeoNet filterHidden={true} onlyLabs={true} hideContainer maxHeight={"60vh"}/>
            </div>
            
        </MobileContainer>
    )
}