// hoc/withLocalizedItems.js
import React, { forwardRef } from 'react';
import useLocalizedItems from '../hooks/useLocalizedItems';

const withLocalizedItems = () => (WrappedComponent) => {
  const ComponentWithLocalizedItems = (props, ref) => {
    const { items, ...rest } = props;
    const localizedItems = useLocalizedItems(items);

    // Propaga gli items localizzati e tutte le altre props, inclusi i ref
    return <WrappedComponent ref={ref} items={localizedItems} {...rest} />;
  };

  return forwardRef(ComponentWithLocalizedItems);
};

export default withLocalizedItems;
