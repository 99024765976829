import { useEffect, useState } from 'react';
import { MdHearing } from 'react-icons/md';
import { BsPip } from "react-icons/bs";
import { RiFullscreenLine, RiFullscreenExitLine, RiRewindFill } from "react-icons/ri";
import { FaPhotoVideo, FaVolumeMute, FaVolumeUp, FaPlay, FaPause, FaChevronUp, FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { IoPlaySkipBackSharp, IoPlaySkipForward } from "react-icons/io5";

import { BiCaptions } from 'react-icons/bi';
import { RiAttachment2 } from "react-icons/ri";
import { IconContext } from "react-icons";
import IconButton from '@material-ui/core/IconButton';
import ReactTooltip from "react-tooltip";
import { SubtitlesMode } from "./Constants";
import { MdOutlineMenuOpen } from "react-icons/md";

import { withTranslation } from 'react-i18next';
import { Label, Button, Badge, Container, Form, FormGroup, Input } from 'reactstrap';
import moment from "moment";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import "./RialeLightVideoSyncPlayerControls.css"
import { TrackType } from './Constants';
import i18n from '../../i18n';

const bgColor = "black"; //#007bff";
const disabledColor = "gray";
const fgColor = "white";
const handleColor = "white"; // "#007bff";


export const CaptionOptionsPanel = (props) => {
    const [selectedOption, setSelectedOption] = useState(props.selectedLanguage);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        props.onChange(event.target.value);
    };

    return (
        <Container style={{ marginTop: '0px' }}>
            <Form className="radio-container">
                <FormGroup check className="radio-label">
                    <Label check className="custom-radio">
                        <Input
                            type="radio"
                            name="radio1"
                            value={SubtitlesMode.OFF}
                            checked={selectedOption === SubtitlesMode.OFF}
                            onChange={handleOptionChange}
                        />
                        {SubtitlesMode.OFF}
                    </Label>
                </FormGroup>
                <FormGroup check className="radio-label">
                    <Label check className="custom-radio">
                        <Input
                            type="radio"
                            name="radio1"
                            value={SubtitlesMode.IT}
                            checked={selectedOption === SubtitlesMode.IT}
                            onChange={handleOptionChange}
                        />
                        {SubtitlesMode.IT.toUpperCase()}
                    </Label>
                </FormGroup>
                <FormGroup check className="radio-label">
                    <Label check className="custom-radio">
                        <Input
                            type="radio"
                            name="radio1"
                            value={SubtitlesMode.EN}
                            checked={selectedOption === SubtitlesMode.EN}
                            onChange={handleOptionChange}
                        />
                        {SubtitlesMode.EN.toUpperCase()}
                    </Label>
                </FormGroup>
            </Form>
        </Container>
    );
};


export const MobileCaptionOptionsPanel = (props) => {
    const [selectedOption, setSelectedOption] = useState(props.selectedLanguage);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        props.onChange(event.target.value);
    };

    return (
        <Container style={{ marginTop: '0px' }}>
            <Form className="mobile-radio-container">
                <FormGroup check className="mobile-radio-label">
                    <Label check className="mobile-custom-radio">
                        <Input
                            type="radio"
                            name="radio1"
                            value={SubtitlesMode.OFF}
                            checked={selectedOption === SubtitlesMode.OFF}
                            onChange={handleOptionChange}
                        />
                        {SubtitlesMode.OFF}
                    </Label>
                </FormGroup>
                <FormGroup check className="mobile-radio-label">
                    <Label check className="mobile-custom-radio">
                        <Input
                            type="radio"
                            name="radio1"
                            value={SubtitlesMode.IT}
                            checked={selectedOption === SubtitlesMode.IT}
                            onChange={handleOptionChange}
                        />
                        {SubtitlesMode.IT.toUpperCase()}
                    </Label>
                </FormGroup>
                <FormGroup check className="mobile-radio-label">
                    <Label check className="mobile-custom-radio">
                        <Input
                            type="radio"
                            name="radio1"
                            value={SubtitlesMode.EN}
                            checked={selectedOption === SubtitlesMode.EN}
                            onChange={handleOptionChange}
                        />
                        {SubtitlesMode.EN.toUpperCase()}
                    </Label>
                </FormGroup>
            </Form>
        </Container>
    );
};


export const TagsButtonGroup = ({ deviceType, isMenuVisible, currentTag, tags, tagFound, currentPositionDate, onPositionChange, onToggleMenu }) => {

    const renderNavigationPanel = () => {
        return <div style={{ display: "flex" }}>
            {
                (<Button style={{ background: "white", borderColor: "white" }}
                    disabled={currentTag["tagIndex"] <= 0}
                    onClick={() => {
                        if (currentTag["tagIndex"] > 0) {
                            onPositionChange(tags[currentTag["tagIndex"] - 1].start_time);
                        }
                    }}
                >
                    <FaChevronLeft color={`${currentTag["tagIndex"] > 0 ? "black" : "white"}`} size={'1.2em'} />
                </Button>

                )
            }

            <span style={{ fontSize: `${deviceType == "mobile" ? "1.0em" : "1.5em"}`, margin: "10px" }}>{`${currentTag["tagIndex"] + 1} / ${tags.length}`}</span>
            <Button style={{ background: "white", borderColor: "white" }}
                disabled={currentTag["tagIndex"] >= (tags.length - 1)}
                onClick={() => {
                    if (currentTag["tagIndex"] < (tags.length - 1)) {
                        onPositionChange(tags[currentTag["tagIndex"] + 1].start_time);
                    }
                }}
            >
                <FaChevronRight color={`${currentTag["tagIndex"] < (tags.length - 1) ? "black" : "white"}`} size={'1.2em'} />
            </Button>
        </div>
    }
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="button-row">
                {tags.map((tag, index) => (
                    <button data-for="TagsButtonGroupTips" data-tip={tag.title}
                        key={index} className={moment(currentPositionDate).isBefore(moment(tag.start_time)) ? "flex-button" : "past-flex-button"}
                        onClick={(ev) => {
                            console.log("SPOSTAMENTO SU POSIZIONE:", tag);
                            onPositionChange(tag.start_time);
                        }}>
                        {index + 1}
                    </button>
                ))}
                <ReactTooltip className='extraClass'

                    id="TagsButtonGroupTips" />
            </div>
            <div style={{ background: "white", display: "flex", justifyContent: "space-between" }}>
                <Button onClick={(ev) => onToggleMenu()}
                    style={{
                        backgroundColor: `${fgColor}`, color: `${bgColor}`
                    }}>
                    <div style={{
                        display: "flex",
                        alignContent: "center",
                        verticalAlign: "center",
                        justifyContent: "flex-start"
                    }}>
                        {
                            (isMenuVisible ?
                                <FaChevronDown style={{ marginTop: "8px", marginRight: "10px" }} color={bgColor} size={'1.2em'} />
                                :
                                <FaChevronUp style={{ marginTop: "8px", marginRight: "10px" }} color={bgColor} size={'1.2em'} />
                            )
                        }

                        <MdOutlineMenuOpen style={{
                            fontSize: "1.4em",
                            marginTop: "5px", marginRight: "5px"
                        }} color={bgColor} size={'1.2em'} />
                        <span key={`spanTag_${i18n.language}`} style={{ fontSize: `${deviceType == "mobile" ? "1.0em" : "1.5em"}`, fontStyle: `${tagFound ? "normal" : "italic"}`, marginLeft: "10px" }}>{`${currentTag["tagIndex"] + 1} - ${currentTag.title}`}</span>

                    </div>


                </Button>
                {renderNavigationPanel()}

            </div>

        </div>

    );
};

export const MobileTagsButtonGroup = ({ deviceType, deviceOrientation, isMenuVisible, currentTag, tags, tagFound, currentPositionDate, onPositionChange, onToggleMenu }) => {

    const menuButtonsStyle = { marginTop: "2px", marginRight: "2px" }
    const renderNavigationPanel = () => {
        return <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            {
                (<Button style={{ background: "white", padding: "0px", borderColor: "white" }}
                    disabled={currentTag["tagIndex"] <= 0}
                    onClick={() => {
                        if (currentTag["tagIndex"] > 0) {
                            onPositionChange(tags[currentTag["tagIndex"] - 1].start_time);
                        }
                    }}
                >
                    <FaChevronLeft color={`${currentTag["tagIndex"] > 0 ? "black" : "white"}`} size={'1.0em'} />
                </Button>

                )
            }

            <span style={{ fontSize: `${deviceType == "mobile" ? "1.0em" : "1.5em"}`, margin: "10px" }}>{`${currentTag["tagIndex"] + 1}/${tags.length}`}</span>
            <Button style={{ background: "white", padding: "0px", borderColor: "white" }}
                disabled={currentTag["tagIndex"] >= (tags.length - 1)}
                onClick={() => {
                    if (currentTag["tagIndex"] < (tags.length - 1)) {
                        onPositionChange(tags[currentTag["tagIndex"] + 1].start_time);
                    }
                }}
            >
                <FaChevronRight color={`${currentTag["tagIndex"] < (tags.length - 1) ? "black" : "white"}`} size={'1.0em'} />
            </Button>
        </div>
    }
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="button-row">
                {tags.map((tag, index) => (
                    <button data-for="TagsButtonGroupTips" data-tip={tag.title}
                        key={index} className={moment(currentPositionDate).isBefore(moment(tag.start_time)) ? "flex-button" : "past-flex-button"}
                        onClick={(ev) => {
                            console.log("SPOSTAMENTO SU POSIZIONE:", tag);
                            onPositionChange(tag.start_time);
                        }}>
                        {index + 1}
                    </button>
                ))}
                {/*<ReactTooltip id="TagsButtonGroupTips" />*/}
            </div>
            <div style={{ background: "white", display: "flex", justifyContent: "space-between" }}>
                <Button
                    onClick={(ev) => onToggleMenu()}
                    style={{
                        backgroundColor: `${fgColor}`, color: `${bgColor}`
                    }}>
                    <div style={{
                        display: "flex",
                        alignContent: "center",
                        verticalAlign: "center",
                        justifyContent: "flex-start"
                    }}>
                        <div style={{ display: "flex" }}>
                            {
                                (isMenuVisible ?
                                    <FaChevronDown style={menuButtonsStyle} color={bgColor} size={'1.0em'} />
                                    :
                                    <FaChevronUp style={menuButtonsStyle} color={bgColor} size={'1.0em'} />
                                )
                            }

                            <MdOutlineMenuOpen style={menuButtonsStyle} size={'1.2em'} />
                        </div>

                        <div>
                            <span style={{
                                fontSize: `${deviceType == "mobile" ? "1.0em" : "1.5em"}`, fontStyle: `${tagFound ? "normal" : "italic"}`,
                                marginLeft: "5px"
                            }}>{`${currentTag["tagIndex"] + 1} - ${currentTag.title}`}</span>
                        </div>

                    </div>
                </Button>
                <div style={{ width: "100px" }}>
                    {renderNavigationPanel()}
                </div>
            </div>
        </div>
    );
};


const ControlsPanelNT = (props) => {

    const { item, showAttachmentsButton, showPipButton, muted, audioSolo, t, playedSeconds, duration, deviceType,
        isFullscreen, timelineIsPlaying, onToggleAttachments,
        collidingItems, onPlayTimeline, onPauseTimeline, subtitlesAvailable } = props;
    const audioSoloColor = (!audioSolo ? "white" : (muted ? "red" : "green"));


    const formattedPlayerSeconds = (playedSeconds) => {
        return (playedSeconds < 10 ?
            `00:0${parseInt(playedSeconds)} / ${moment.duration(props.duration, "seconds").format("hh:mm:ss")}` :
            (
                playedSeconds < 60 ?
                    `00:${parseInt(playedSeconds)} / ${moment.duration(props.duration, "seconds").format("hh:mm:ss")}` :
                    `${moment.duration(playedSeconds, "seconds").format("hh:mm:ss")} / ${moment.duration(props.duration, "seconds").format("hh:mm:ss")}`
            )
        )
    }

    const sliderPos = duration != 0 ? (100 * playedSeconds / duration) : 0;
    const [sliderValue, setSliderValue] = useState(sliderPos)
    const [sliderPlayedSeconds, setSliderPlayedSeconds] = useState(formattedPlayerSeconds(props.playedSeconds))
    const buttonSize = "1.0em";

    useEffect(() => {
        setSliderValue(sliderPos)
    }, [sliderPos])

    useEffect(() => {
        const newPLayedSecond = (sliderValue / 100) * props.duration;
        //console.log("SLIDER NEW PLAYED SECONDS:", newPLayedSecond)
        setSliderPlayedSeconds(formattedPlayerSeconds(newPLayedSecond));
    }, [sliderValue])

    return (<div style={{
        display: "flex",
        padding: "5px",
        //bottom: `${isFullscreen ? 10 : 2}px`,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: bgColor,
        width: "100%",
        zIndex: 200
    }} >

        <IconButton onClick={() => {
            timelineIsPlaying ? onPauseTimeline() : onPlayTimeline()

        }

        } style={{
            backgroundColor: bgColor,
            opacity: '1',
            margin: '3px', padding: "10px"

        }}>
            <IconContext.Provider value={{ color: "white", size: `${buttonSize}`, padding: "0px" }}>
                {timelineIsPlaying ?
                    <FaPause data-place="top" data-tip={t("tl:tip_timeline_pause")} /> :
                    <FaPlay data-place="top" data-tip={t("tl:tip_timeline_play")} />}


            </IconContext.Provider>
        </IconButton>

        <IconButton onClick={() => { item != null && props.onDatetimeChangeRequest(moment(item.start_time)) }} style={{
            backgroundColor: bgColor,
            opacity: '1',
            margin: '3px', padding: "10px"

        }}>
            <IconContext.Provider value={{ color: "white", size: `${buttonSize}`, padding: "0px" }}>
                <RiRewindFill data-place="top" data-tip={t("")} />

            </IconContext.Provider>
        </IconButton>

        <IconButton disabled={props.isFirstVideo} onClick={() => { item != null && props.onGotoPreviousVideo() }} style={{
            backgroundColor: bgColor,
            opacity: '1',
            margin: '3px', paddingRight: "0px"

        }}>
            <IconContext.Provider value={{ color: `${props.isFirstVideo ? disabledColor : "white"}`, size: `${buttonSize}`, padding: "0px" }}>
                <IoPlaySkipBackSharp data-place="top" data-tip={t("Go to previous video")} />

            </IconContext.Provider>
        </IconButton>

        <IconButton disabled={props.isLastVideo} onClick={() => { item != null && props.onGotoNextVideo() }} style={{
            backgroundColor: bgColor,
            opacity: '1',
            margin: '3px', paddingLeft: "5px"

        }}>
            <IconContext.Provider value={{ color: `${props.isLastVideo ? disabledColor : "white"}`, size: `${buttonSize}`, padding: "0px" }}>
                <IoPlaySkipForward data-place="top" data-tip={t("Go to next video")} />

            </IconContext.Provider>
        </IconButton>

        <IconButton onClick={() => { props.onToggleMute() }} style={{
            backgroundColor: bgColor,
            opacity: '1',
            margin: '3px', padding: "10px"

        }}>
            <IconContext.Provider value={{ color: "white", size: `${buttonSize}`, padding: "0px" }}>
                {muted ?
                    <FaVolumeMute data-place="top" data-tip={t("tl:tip_audioOff")} /> :
                    <FaVolumeUp data-place="top" data-tip={t("tl:tip_audioOn")} />}
            </IconContext.Provider>
        </IconButton>
        {audioSolo != null &&
            <IconButton onClick={() => props.onToggleAudioSolo()} style={{
                backgroundColor: `${audioSoloColor}`,
                opacity: '1',
                margin: '3px', padding: "10px",
            }}>
                <IconContext.Provider value={{ color: "black", size: `${buttonSize}`, padding: "0px" }}>
                    <MdHearing data-place="top" data-tip={t("tl:tip_muteOthers")} />
                </IconContext.Provider>
            </IconButton>
        }

        {
            <IconButton onClick={() => props.onFullScreenRequest()} style={{
                backgroundColor: bgColor,
                opacity: '1',
                margin: '3px', padding: "10px"
            }}>
                <IconContext.Provider value={{ color: "white", size: `${buttonSize}`, padding: "0px" }}>
                    {deviceType != "mobile" &&
                        (isFullscreen ?
                            <RiFullscreenExitLine data-place="top" data-tip={t("tl:tip_fullscreen_exit")} /> :
                            <RiFullscreenLine data-place="top" data-tip={t("tl:tip_fullscreen")} />
                        )
                    }
                </IconContext.Provider>
            </IconButton>
        }
        {showPipButton &&
            <IconButton onClick={() => props.onTogglePip()} style={{
                backgroundColor: bgColor,
                margin: '3px', padding: "10px"

            }}>
                <IconContext.Provider value={{ color: "white", size: `${buttonSize}`, padding: "0px" }}>
                    <BsPip data-place="top" data-tip={t("tl:tip_pip")} />
                </IconContext.Provider>
            </IconButton>
        }
        <Slider style={{ marginLeft: "40px", marginRight: "30px" }}
            handleStyle={{
                borderColor: 'white',
                height: 25,
                width: 25,
                marginLeft: 0,
                marginTop: -9,
                borderRadius: "5px",
                backgroundColor: handleColor,
            }}
            min={0} max={100} value={sliderValue}
            onBeforeChange={(value) => {
                console.log("SLIDER ONBEFORE:", value);
                props.onStartSliderChange(value);
            }}
            onChange={(value) => {
                console.log("SLIDER ONCHANGE:", value);
                setSliderValue(value)
                const newPLayedSecond = (value / 100) * props.duration;
                const newPosition = moment(item.start_time).add(newPLayedSecond, "seconds");
                props.onSliderChange(newPosition);
            }}
            onAfterChange={(value) => {
                console.log("SLIDER ONAFTERCHANGE:", value)
                const newPLayedSecond = (value / 100) * props.duration;
                const newPosition = moment(item.start_time).add(newPLayedSecond, "seconds");
                props.onEndSliderChange(newPosition);
            }}
        />
        <Badge style={{ color: "white", background: "black", margin: "10px", fontSize: "1.2em" }}>
            {
                sliderPlayedSeconds
            }

        </Badge>
        {showAttachmentsButton &&
            (
                <Button onClick={(ev) => onToggleAttachments()}
                    data-place="top" data-tip={t("tl:attachments")}
                    style={{
                        marginLeft: "10px",
                        backgroundColor: `${(collidingItems[TrackType.DOC].length > 0 ? "#007bff" : "black")}`
                    }}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <RiAttachment2 color='white' size={'1.4em'} />
                        <span style={{ marginLeft: "10px", fontSize: "1.2em" }}>{`${collidingItems[TrackType.DOC].length}`}</span>

                    </div>
                </Button>
            )}


        {
            subtitlesAvailable &&
            (<div style={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={() => props.onToggleSubtitlesPanel()} style={{
                    backgroundColor: bgColor,
                    marginLeft: '5px', padding: "8px"

                }}>
                    <IconContext.Provider value={{ color: "white", size: `${"1.2em"}`, padding: "0px" }}>
                        <BiCaptions data-place="top" data-tip={t("tl:tip_subtitles")} />
                    </IconContext.Provider>
                    <span style={{ color: "white", marginLeft: "10px", marginRight: "5px" }}> {props.selectedLanguage.toUpperCase()} </span>
                </IconButton>

            </div>
            )
        }

        <ReactTooltip place="top" />
    </div>)
}

//
// MOBILE CONTROLS PANEL
//

const MobileControlsPanelNT = (props) => {

    const { item, showAttachmentsButton, showPipButton, muted, audioSolo, t, playedSeconds,
        duration, deviceType, deviceOrientation,
        isFullscreen, timelineIsPlaying, onToggleAttachments,
        collidingItems, onPlayTimeline, onPauseTimeline, subtitlesAvailable } = props;
    const audioSoloColor = (!audioSolo ? "white" : (muted ? "red" : "green"));

    const formattedPlayerSeconds = (playedSeconds, showDuration) => {
        const durationInfo = (showDuration==true ? ` / ${moment.duration(props.duration, "seconds").format("hh:mm:ss")}` : "")
        return (playedSeconds < 10 ?
            `00:0${parseInt(playedSeconds)}${durationInfo}` :
            (
                playedSeconds < 60 ?
                    `00:${parseInt(playedSeconds)}${durationInfo}` :
                    `${moment.duration(playedSeconds, "seconds").format("hh:mm:ss")}${durationInfo}`
            )
        )
    }

    const sliderPos = duration != 0 ? (100 * playedSeconds / duration) : 0;
    const [sliderValue, setSliderValue] = useState(sliderPos)
    const [sliderPlayedSeconds, setSliderPlayedSeconds] = useState(formattedPlayerSeconds(props.playedSeconds, (deviceOrientation=="landscape" && isFullscreen)))
    const buttonSize = deviceOrientation == "landscape" ? "0.8em" : "0.7em";

    useEffect(() => {
        setSliderValue(sliderPos)
    }, [sliderPos])

    useEffect(() => {
        const newPLayedSecond = (sliderValue / 100) * props.duration;
        //console.log("SLIDER NEW PLAYED SECONDS:", newPLayedSecond)
        setSliderPlayedSeconds(formattedPlayerSeconds(newPLayedSecond, 
            (deviceOrientation=="landscape" && isFullscreen)));
    }, [sliderValue, deviceOrientation, isFullscreen])

    const mobileButtonStyle = {
        backgroundColor: bgColor,
        opacity: '1',
        margin: '3px'
    }
    return (<div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: "2px",
        paddingRight: "2px",
        backgroundColor: bgColor,
        zIndex: 200
    }} >

        <IconButton onClick={() => {
            timelineIsPlaying ? onPauseTimeline() : onPlayTimeline()

        }

        } style={{
            backgroundColor: bgColor,
            opacity: '1'
        }}>
            <IconContext.Provider value={{ color: "white", size: `${buttonSize}` }}>
                {timelineIsPlaying ?
                    <FaPause data-place="top" data-tip={t("tl:tip_timeline_pause")} /> :
                    <FaPlay data-place="top" data-tip={t("tl:tip_timeline_play")} />}
            </IconContext.Provider>
        </IconButton>

        <IconButton disabled={props.isFirstVideo} onClick={() => { item != null && props.onGotoPreviousVideo() }} style={{
            backgroundColor: bgColor,
            opacity: '1',
            margin: '3px', paddingRight: "0px",  paddingLeft: "0px"

        }}>
            <IconContext.Provider value={{ color: `${props.isFirstVideo ? disabledColor : "white"}`, size: `${buttonSize}`, padding: "0px" }}>
                <IoPlaySkipBackSharp data-place="top" data-tip={t("Go to previous video")} />

            </IconContext.Provider>
        </IconButton>

        <IconButton disabled={props.isLastVideo} onClick={() => { item != null && props.onGotoNextVideo() }} style={{
            backgroundColor: bgColor,
            opacity: '1',
            margin: '3px', paddingLeft: "5px",
            paddingRight: "0px"

        }}>
            <IconContext.Provider value={{ color: `${props.isLastVideo ? disabledColor : "white"}`, size: `${buttonSize}`, padding: "0px" }}>
                <IoPlaySkipForward data-place="top" data-tip={t("Go to next video")} />

            </IconContext.Provider>
        </IconButton>

        {showPipButton &&
            <IconButton onClick={() => props.onTogglePip()} style={{
                backgroundColor: bgColor,
                margin: '3px', padding: "6px"

            }}>
                <IconContext.Provider value={{ color: "white", size: `${buttonSize}`, padding: "0px" }}>
                    <BsPip data-place="top" data-tip={t("tl:tip_pip")} />
                </IconContext.Provider>
            </IconButton>
        }

        <Badge style={{ color: "white", background: "black", fontSize: "1.0em" }}>
            {
                `${sliderPlayedSeconds}`
            }

        </Badge>
        <Slider style={{ width: "50%", marginLeft: "15px", marginRight: "10px" }}
            handleStyle={{
                borderColor: 'white',
                height: 20,
                width: 20,
                marginLeft: 0,
                marginTop: -7,
                borderRadius: "5px",
                backgroundColor: handleColor,
            }}
            min={0} max={100} value={sliderValue}
            onBeforeChange={(value) => {
                console.log("SLIDER ONBEFORE:", value);
                props.onStartSliderChange(value);
            }}
            onChange={(value) => {
                console.log("SLIDER ONCHANGE:", value);
                setSliderValue(value)
                const newPLayedSecond = (value / 100) * props.duration;
                const newPosition = moment(item.start_time).add(newPLayedSecond, "seconds");
                props.onSliderChange(newPosition);
            }}
            onAfterChange={(value) => {
                console.log("SLIDER ONAFTERCHANGE:", value)
                const newPLayedSecond = (value / 100) * props.duration;
                const newPosition = moment(item.start_time).add(newPLayedSecond, "seconds");
                props.onEndSliderChange(newPosition);
            }}
        />

        {
            (
                <IconButton onClick={() => { props.onToggleMute() }} style={
                    mobileButtonStyle
                }>
                    <IconContext.Provider value={{ color: "white", size: `${buttonSize}`, padding: "0px" }}>
                        {muted ?
                            <FaVolumeMute data-place="top" data-tip={t("tl:tip_audioOff")} /> :
                            <FaVolumeUp data-place="top" data-tip={t("tl:tip_audioOn")} />}
                    </IconContext.Provider>
                </IconButton>
            )
        }
        {
            <IconButton onClick={() => props.onFullScreenRequest()} style={{
                backgroundColor: bgColor,
                opacity: '1',
                margin: '3px', paddingRight: "0px",  paddingLeft: "0px"
            }}>
                <IconContext.Provider value={{ color: "white", size: `${buttonSize}`, padding: "0px" }}>
                    {isFullscreen ? (
                        <RiFullscreenExitLine data-place="top" data-tip={t("tl:tip_fullscreen_exit")} />
                    ) : (
                        <RiFullscreenLine data-place="top" data-tip={t("tl:tip_fullscreen")} />
                    )}

                </IconContext.Provider>
            </IconButton>
        }





        {showAttachmentsButton && false &&
            (
                <Button onClick={(ev) => onToggleAttachments()}
                    data-place="top" data-tip={t("tl:attachments")}
                    style={{
                        marginLeft: "10px",
                        backgroundColor: `${(collidingItems[TrackType.DOC].length > 0 ? "#007bff" : "black")}`
                    }}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <RiAttachment2 color='white' size={'1.4em'} />
                        <span style={{ marginLeft: "10px", fontSize: "1.2em" }}>{`${collidingItems[TrackType.DOC].length}`}</span>

                    </div>
                </Button>
            )}


        {
            subtitlesAvailable &&
            (<div style={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={() => props.onToggleSubtitlesPanel()} style={{
                    backgroundColor: bgColor,
                    marginLeft: '5px', padding: "8px"

                }}>
                    <IconContext.Provider value={{
                        color: "white",

                        size: `${buttonSize}`, padding: "0px"
                    }}>
                        <BiCaptions data-place="top" data-tip={t("tl:tip_subtitles")} />
                    </IconContext.Provider>
                    <span style={{ fontSize: "0.8em", color: "white", marginLeft: "5px", marginRight: "5px" }}> {props.selectedLanguage.toUpperCase()} </span>
                </IconButton>

            </div>
            )
        }

        {/*  <ReactTooltip place="top" /> */}
    </div>)
}

const ControlsPanel = withTranslation()(ControlsPanelNT);
export const MobileControlsPanel = withTranslation()(MobileControlsPanelNT);
export default ControlsPanel;