import moment from 'moment';

const testData = [
  ["contest2024trace__T01_C01_TL01_S01",
    JSON.stringify({ "startDate": 1234, "id": "session1" })],  // studente1 - 2 sessioni con T01
  ["contest2024trace__T01_C01_TL02_S01",
    JSON.stringify({ "startDate": 1235, "id": "session2" })],
  ["contest2024trace__T01_C02_TL01_S02",
    JSON.stringify({ "startDate": 1236, "id": "session3" })],// studente2 - 3 sessioni con T01
  ["contest2024trace__T01_C02_TL02_S02", JSON.stringify({ "actions": ["a11", "a12"], "startDate": 1236, "id": "session4" })],
  ["contest2024trace__T01_C02_TL01_S02", JSON.stringify({ "actions": ["a21", "a22", "a23"], "startDate": 1236, "id": "session5" })],
  ["contest2024trace__T01_C02_TL02_S02", JSON.stringify({ "actions": ["a31"], "startDate": 1236, "id": "session6" })],
  ["contest2024trace__T02_C01_TL01_S03", JSON.stringify({ "actions": ["a41", "a42"], "startDate": 1236, "id": "session7" })],// studente3 - 1 sessione con T02
  ["contest2024trace__T03_C01_TL01_S04", JSON.stringify({ "actions": ["a51", "a52", "a53"], "startDate": 1236, "id": "session8" })],// studente3 - 1 sessione con T02
  ["contest2024trace__T03_C01_TL01_S05", JSON.stringify({ "actions": ["a61", "a62"], "startDate": 1236, "id": "session9" })]
]


const testTraceOveriews = {
  "timeline0": {
      "score": {
          "coperturaMediaVideoPerc": 61.36514960739634,
          "coperturaMediaAllegatiPerc": 40
      },
      "students": []
  },
  
   "timeline2": {
      "score": {
          "coperturaMediaVideoPerc": 20,
          "coperturaMediaAllegatiPerc": 47
      },
      "students": []
  },
  
  "timeline3": {
      "score": {
          "coperturaMediaVideoPerc": 3,
          "coperturaMediaAllegatiPerc": 80
      },
      "students": []
  }
}

export
const calcolaPunteggioFinale = (dati) => {
  //console.log("calcola punteggio finale su:", dati)
  // Trova la timeline con la coperturaMediaVideoPerc maggiore
  let maxCoperturaVideoPerc = -Infinity;
  let timelineMax = null;

  Object.keys(dati).forEach(timeline => {
      const coperturaVideoPerc = dati[timeline].score.coperturaMediaVideoPerc;
      if (coperturaVideoPerc > maxCoperturaVideoPerc) {
          maxCoperturaVideoPerc = coperturaVideoPerc;
          timelineMax = timeline;
      }
  });

  if (timelineMax==null) {
    // se non viene trovata alcuna timeline analizzata il punteggio non puo' che 
    // essere nullo
    return 0
  }

  // Calcola il punteggio per la timeline con il massimo coperturaMediaVideoPerc
  //const punteggioMax = 1.00 * (dati[timelineMax].score.mediaSecondiVisti/60.0)
  //const punteggioMax = 1.00 * dati[timelineMax].score.coperturaMediaVideoPerc
    // ignoro gli allegati nel conteggio                   
    //+ 0.25 * dati[timelineMax].score.coperturaMediaAllegatiPerc;

  // Somma il 20% delle altre timeline
  let punteggioFinale = 0;
  Object.keys(dati).forEach(timeline => {
        const punteggioTimeline = 1.00 * (dati[timeline].score.mediaSecondiVisti/60.0)
        //const punteggioAltraTimeline = 1.00 * dati[timeline].score.coperturaMediaVideoPerc
          //ignoro gli allegati nel conteggio
          // + 0.25 * dati[timeline].score.coperturaMediaAllegatiPerc;
          punteggioFinale += punteggioTimeline;  // EQUIPARATE (prima era 0.2*)
  });

  return punteggioFinale;
}

// Funzione per ottenere l'insieme unico di studenti
const getUniqueStudents = (data) => {
  const studentSet = new Set();

  // Itera attraverso l'oggetto principale
  Object.values(data).forEach(course => {
    // Itera attraverso ogni livello (TL01, TL02, ecc.)
    Object.values(course).forEach(level => {
      // Aggiungi ogni identificatore studente al set
      Object.keys(level).forEach(student => {
        studentSet.add(student);
      });
    });
  });

  // Converti il set in un array se hai bisogno di una lista
  return Array.from(studentSet);
}

// Funzione per ottenere l'insieme unico di studenti per una specifica categoria
export 
const getStudentsByCategory = (data, category) => {
  const studentSet = new Set();

  // Controlla se la categoria esiste nei dati
  if (data[category]) {
    // Itera attraverso ogni livello (TL01, TL02, ecc.) della categoria specificata
    Object.values(data[category]).forEach(level => {
      // Aggiungi ogni identificatore studente al set
      Object.keys(level).forEach(student => {
        studentSet.add(student);
      });
    });
  } else {
    console.warn(`La categoria ${category} non esiste nei dati.`);
  }

  // Converti il set in un array se hai bisogno di una lista
  return Array.from(studentSet);
}

const mergeActionsByTimestamp = (data, from, to) => {
  // Prima parsiamo i JSON e li trasformiamo in oggetti
  // ordino le sessioni in ordine cronologico, eventualmente escludendo quelle che 
  // non rientrano nella finestra temporale [from, to]

  // moment("12/13/24 12:37").isSameOrBefore(moment("12/13/24 12:34"), "day") -> true
  const parsedData = data.map(item => JSON.parse(item)).filter(item => {
    return (!from || moment(item.startDate).isSameOrAfter(moment(from), "day")) && 
        (!to ||  moment(item.stopDate).isSameOrBefore(moment(to), "day"))  
  });

  // Ordiniamo gli oggetti in base al campo 'startDate' (timestamp)
  parsedData.sort((a, b) => a.startDate - b.startDate);
  //("parsedData:::", parsedData)
  // Combiniamo tutte le 'actions' in un unico array rispettando l'ordine
  const mergedActions = parsedData.reduce((acc, item) => {
    return acc.concat([{"startDate" : item.startDate, "formattedStartDatetime" : item.formattedStartDatetime},...item.actions, {"stopDate" : item.stopDate, "formattedStopDatetime" : item.formattedStopDatetime}]);
  }, []);

  // Creiamo l'oggetto risultato con tutte le azioni combinate
  return { actions: mergedActions };
}

export 
const getContest2024Trace = (contest2024data, from, to) => {
  console.time('getContest2024Trace Execution:');
  console.log("getContest2024Trace Data:", contest2024data);

  const sessionTimestamps = {}
  //console.log(`getContest2024Trace from ${from} to ${to}`)
  // Estrai le combinazioni e crea un Set per le combinazioni distinte
  const stage = process.env.REACT_APP_STAGE != undefined ? process.env.REACT_APP_STAGE : "beta"
  const disabledTeachers = stage=="beta" ? [] : [
    "e17c4496-ad06-481b-b86c-9eb4f274b2e7", //mancaras@tin.it
    "cc0a21fe-79bc-43e8-89d3-341b9c3fa762" //stefano.monni+teacher1@gmail.com
  ]
       
  const combinationsSet = new Set(
    contest2024data.filter(item => 
      !disabledTeachers.some(disabilitato => item[0].includes(disabilitato))
    ).map(item => {
      // Estrai la parte Txx_Cxx
      const parts = item[0].split('__')[1].split('_');
      const ts_key = item[0].split('__')[1]
      if (sessionTimestamps[ts_key]==null)
        sessionTimestamps[ts_key] = [item[2]]
      else sessionTimestamps[ts_key].push(item[2])
      return parts[0] + '_' + parts[1]; // estrae Txx_Cxx
    })
  );
  console.log("sessionTimestamps:", sessionTimestamps)
  // Crea un oggetto vuoto per memorizzare il risultato
  const result = {};

  
  // Itera su ciascun elemento del Set
  combinationsSet.forEach(combination => {
    // Trova tutte le coppie che corrispondono a questa combinazione
    const relevantData = contest2024data
      .filter(item => item[0].includes(combination)) // Filtra le stringhe con la combinazione corrente
      .map(item => {
        // Estrai la parte finale dopo il secondo underscore e il nome della sessione
        const parts = item[0].split('__')[1].split('_');
        const sessionCode = parts[2]; // estrae Sxx
        const timelineId = parts[3]
        //console.log("timeline id:", timelineId)
        const sessionName = item[1];  // nome della sessione (es. "session1")
        return { sessionCode, sessionName, timelineId };
      });

    // Inizializza l'oggetto interno per la combinazione
    result[combination] = {};

    // Popola l'oggetto interno con chiavi come le sessioni e valori come liste contenenti i nomi delle sessioni
    relevantData.forEach(({ sessionCode, sessionName, timelineId }) => {
      // Se la chiave `sessionCode` non esiste ancora, inizializzala come un array vuoto
      if (!result[combination][sessionCode]) {
        result[combination][sessionCode] = { [timelineId]: [] };
      }
      if (!result[combination][sessionCode][timelineId]) {
        result[combination][sessionCode][timelineId] = [];
      }
      //console.log(result)
      // Aggiungi il nome della sessione alla lista corrispondente
      result[combination][sessionCode][timelineId].push(sessionName);
    });

    // qui posso pensare di fare il merge delle sessioni
    // Itera nuovamente per ciascun `sessionCode` per fare il merge
    Object.keys(result[combination]).forEach(sessionCode => {
      Object.keys(result[combination][sessionCode]).forEach(timelineId => {
        const mergedSession = mergeActionsByTimestamp(result[combination][sessionCode][timelineId], from, to);
        result[combination][sessionCode][timelineId] = [mergedSession];
      });
    });
});
//console.log(result);
/* Output atteso
{
T01_C01: { TL01: { S01: [Array] }, TL02: { S01: [Array] } },
T01_C02: { TL01: { S02: [Array] }, TL02: { S02: [Array] } },
T02_C01: { TL01: { S03: [Array] } },
T03_C01: { TL01: { S04: [Array], S05: [Array] } }
}
result [ 'session4', 'session6' ]
*/
console.timeEnd('getContest2024Trace Execution');
return [result, sessionTimestamps];
}

/*
{   // TEACHER_CLASSROOM (passcode di AnalyticSession
    "d3deab88-60dd-4e8d-8cec-1f13e2dba31b_2fsa": {
      // TIMELINE
        "c38a72a8-00b5-4278-a871-9e1e948f934c": {
            // UTENTE
            "ed3b38fa-46ad-43fb-bd15-3e8786badd41": 
              // LIST OF JSON SESSIONS
              ["{\"tracedResource\":\"InnerLightTimeline\..."]
  */

const test = () => {
  const info = getContest2024Trace(testData)
  const result = info[0]
  console.log(result)
  console.log("result", result["T01_C02"]["TL02"]["S02"])

  const students = getUniqueStudents(result);
  console.log("Studenti:", students);
  const category = "T03_C01"
  
  const studentsT03_C01 = getStudentsByCategory(result, category)
  console.log("StudentiT03_C01:", studentsT03_C01);
}

//test()
//("punteggio finale:" , calcolaPunteggioFinale(testTraceOveriews))