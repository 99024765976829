import { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ReactPlayer from 'react-player';
import "./LightVideoPlayer.css"
import { SubtitlesMode, TrackType } from './Constants';
import { Input, Label, Card, CardBody, CardHeader, CardText, CardTitle, CardSubtitle, Spinner } from 'reactstrap';
import { tracks } from './FakeItems';
import { findDOMNode } from 'react-dom'
import screenfull, { isFullscreen } from 'screenfull';
import ControlsPanel, { CaptionOptionsPanel, TagsButtonGroup } from './RialeLightVideoSyncPlayerControls';
import { ItemPreview } from './ItemEditor';
import { IoMdCloseCircleOutline } from "react-icons/io";
import { getTimelineItems, getDurationInSeconds } from './Utils';
import { useDeviceType, useDeviceOrientation } from './Utils';
import withWatcher, { TraceEvent, TraceChangePosition } from './TimelineWatcher';
import { pick } from 'lodash';
import { selectors as AuthSelectors } from '../../store/slices/auth'
import { selectors as ProfileSelectors } from '../../store/slices/profile'
import { useDispatch, useSelector } from "react-redux";
import { selectors as PassCodeSelector, actions as PassCodeAction } from '../../store/slices/passCodeUsers'
import useLocalizedItems from './hooks/useLocalizedItems';

const InnerLightTimeline = (props) => {

  const initialLockedGroups = tracks.reduce((acc, cur) => ({ ...acc, [cur.id]: true }), {});
  const { mobileSection } = props;
  const [playerCaptionskey, setPlayerCaptionsKey] = useState("");
  const [isPlayerReady, setPlayerReady] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [fullScreenDomNode, setFullScreenDomNode] = useState(null);
  const [currentPositionDate, setCurrentPositionDate] = useState(null);
  // Secondo specifiche, la timeline mobile ha un solo tag alla volta ed n attachments che possono collidere con un video (unico)
  const [collidingItems, setCollidingItems] = useState({ tagFound: false, [TrackType.TAG]: { "title": "", tagIndex: 0 }, [TrackType.DOC]: [] });
  const [isVideoBuffering, setVideoBuffering] = useState(false);
  const [muted, setMuted] = useState(false);
  const [pip, setPip] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  const deviceType = useDeviceType();
  const deviceOrientation = useDeviceOrientation();
  const intervalIdRef = useRef(null); // Usa un ref per tenere traccia dell'intervallo
  const dispatch = useDispatch();
  const currentRegistrationProfile = useSelector(AuthSelectors.getRegistrationProfile)
  const userAttributes = useSelector(ProfileSelectors.getProfile);
  const localizedItems = useLocalizedItems(mobileSection.items);

  const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";
  const DATE_FORMAT = "DD/MM/YYYY";
  const TIME_FORMAT = "HH:mm";


  const [items, setItems] = useState([]);
  const [videoItems, setVideoItems] = useState([]);
  const [currentVideo, setCurrentVideo] = useState(null);

  const [tagItems, setTagItems] = useState([]);
  const attachmentItems = useRef(null);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [areAttachmentsVisible, setAttachmentsVisible] = useState(true);
  const [isCaptionsPanelVisible, setCaptionsPanelVisible] = useState(false);

  const [currentVideoPosition, setCurrentVideoPosition] = useState({ currentIndex: 0, currentVideoOffset: 0 })

  const playerRef = useRef(null);
  const playerMustRestartRef = useRef(false);
  const ignoreHandleProgress = useRef(false);
  const lastPlayedSeconds = useRef(null);
  const currentPositionDateRef = useRef(null);
  // container da renderizzare all'occorrenza in fullscreen
  const playerContainerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [subtitlesTracks, setSubtitleTracks] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(SubtitlesMode.EN);
  const [showAllAttachments, setShowAllAttachments] = useState(false);
  const [timelineLoaded, setTimelineLoaded] = useState(false)

  const getAbsolutePositionInSeconds = (videoIndex, videoPosition) => {
    let pos = 0
    for (let i = 0; i < videoIndex; i++) {
      console.log(`AVP: index:${i} duration: ${videoItems[i]["duration"]} `)
      pos += videoItems[i]["duration"]
    }
    console.log(`AVP: index:${videoIndex} pos:${videoPosition} VideoPosition:`, pos + videoPosition);
    return pos + videoPosition
  }

  const updateLayout = useCallback(() => {
    setTimeout(() => {
      // Logica per aggiornare il layout dei componenti
      if (playerContainerRef.current) {
        playerContainerRef.current.style.flex = `0 0 ${(isFullscreen || deviceType == "mobile") ? 100 : 70}%`;
        playerContainerRef.current.stylealignContent = "center";
        playerContainerRef.current.justifyContent = "flex-start";
      }
    }
      , 0)
  }, [isFullscreen, deviceType]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        // Operazioni da svolgere quando l'utente passa a un'altra scheda
        console.log("TLW: utente ha cambiato scheda. Arresto il player e mando un evento");
        setIsPlaying(false);
        onEventToWatch(TraceEvent.SESSION_VISIBILITY_CHANGE,
          {
            "visible": false,
          }
        )
      } else if (document.visibilityState === "visible") {
        // Operazioni da svolgere quando l'utente torna alla scheda
        console.log("TLW: utente è tornato alla scheda currentVideo:", currentVideo);
        onEventToWatch(TraceEvent.SESSION_VISIBILITY_CHANGE,
          {
            "visible": true,
          })
      }
      else {
        console.log("TLW: utente è nel seguente stato di visibility:", document.visibilityState);
      }
    };

    // Aggiungi l'evento 'visibilitychange'
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Rimuovi l'evento quando il componente viene smontato
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [currentVideo]);

  useEffect(() => {
    console.log("PLT: PLAY_CHANGE:", isPlaying)
    if (videoItems?.length > 0) {
      onEventToWatch(TraceEvent.PLAY_CHANGE,
        {
          "isPlaying": isPlaying
        })
    }

  }, [isPlaying])

  useEffect(() => {
    const onFullscreenChange = () => {
      if (screenfull.isEnabled && !screenfull.isFullscreen) {
        // Verifica se l'uscita dalla modalità a schermo intero è stata attivata con ESC
        if (document.visibilityState === 'visible') {
          console.log("FULLSCREEN EXIT")
        }
      }
      updateLayout();
    };

    if (screenfull.isEnabled) {
      screenfull.on('change', onFullscreenChange);
    }

    // Funzione per gestire la pressione dei tasti
    const handleKeyDown = (event) => {
      console.log("KEY DOWN ESCAPE FULLSCREEN:", event);
      if (event.key === 'Escape' && screenfull.isFullscreen) {
        setFullScreenDomNode(null);

        //screenfull.exit();
      }
    };

    // Aggiungi il listener per la pressione dei tasti
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      if (screenfull.isEnabled) {
        screenfull.off('change', onFullscreenChange);
        window.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, []);

  useEffect(() => {
    const onFullscreenChange = () => {
      if (screenfull.isEnabled) {
        if (screenfull.isFullscreen) {
          // Entrando in modalità a schermo intero
          updateLayout();
        } else {
          // Uscendo dalla modalità a schermo intero
          updateLayout();
        }
      }
    };

    const onResize = () => {
      // Aggiorna il layout quando la finestra viene ridimensionata
      updateLayout();
    };

    if (screenfull.isEnabled) {
      screenfull.on('change', onFullscreenChange);
    }

    window.addEventListener('resize', onResize);

    return () => {
      if (screenfull.isEnabled) {
        screenfull.off('change', onFullscreenChange);
      }
      window.removeEventListener('resize', onResize);
    };
  }, [updateLayout]);

  useEffect(() => {
    updateLayout();
  }, [isFullscreen, updateLayout]);

  useEffect(() => {
    console.log("LTD: change language" );
    if (mobileSection != null) {
      const newItems = getTimelineItems(localizedItems, initialLockedGroups, false, false)
        .filter((item) => item.type != TrackType.IOT)
        .sort((item1, item2) => { return moment(item1.start_time) - moment(item2.start_time) });

      // aggiunta tagIndex agli item di tipo tag
      let currentTagIndex = 0
      for (let i = 0; i < newItems.length; i++) {

        //console.log("newItems[i]:", newItems[i])
        if (newItems[i].type == TrackType.TAG) {
          newItems[i]["tagIndex"] = currentTagIndex;
          currentTagIndex++
        }
      }

      setItems(newItems);
      setIsPlaying(false);

    }
    //console.log("Mobile section:", mobileSection)
  }, [mobileSection, i18n.language])


  useEffect(() => {
    if (items != null)
      setupMobileTimeline();
      console.log("LTD: items changed" );
  }, [items])


  useEffect(() => {
    console.log("New VideoItems:", videoItems)
    if (attachmentItems.current != null) setupSubtitleTracks();
    if (videoItems.length > 0 && attachmentItems.current != null && currentVideo!=null) {
      setTimelineLoaded(true)
    }
  }, [videoItems, attachmentItems.current, currentVideo])


  const isContest2024studentLogged = () => {
    console.log(`TLW: currentRegistrationProfile: ${currentRegistrationProfile?.contest2024} , user:${userAttributes?.sub}`)
    const res = (currentRegistrationProfile?.type == "student" &&
      currentRegistrationProfile?.contest2024 == true && userAttributes?.sub != null)
    console.log("TLW: isStudentContest:", res)
    return res
  }

  useEffect(() => {
    if (timelineLoaded && isContest2024studentLogged()) {
      /* Ci pensa la apposita saga a salvare periodicamente nel backend
       il trace del LocalStaorage
      intervalIdRef.current = setInterval(() => {
        if (isContest2024studentLogged()) {
          console.log("TLW: Utente loggato dispatch")
          // Dispatcha l'azione Redux per salvare i dati
          dispatch(PassCodeAction.willSaveContest2024StudentSessions(
              {
                  "userId": userAttributes?.sub 
              }
          ));
        }
        else {console.log("TLW: Utente non loggato")}
      }, 10000); // Ogni 10 secondi
     */

      onEventToWatch(TraceEvent.TIMELINE_LITE_OPEN,
        {
          "timelineId": props.id,
          "deviceType": deviceType,
          "videoItems": videoItems.map((video, index) => {
            return pick(video, ["id", "title", "source", "description",
              "start_time", "start_offset", "duration", "end_offset", "end_time"])
          }),
          "attachmentItems": attachmentItems.current.map((attachment) => {
            return pick(attachment, ["id", "title", "description",
              "start_time", "end_time"])
          })
        }
      )
    }

    // Pulisci l'intervallo quando le dipendenze cambiano o il componente viene smontato
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, [timelineLoaded, userAttributes, currentRegistrationProfile])

  /* adesso ci pensa una apposita saga a salvare periodicamente i dati del contest dal local storage
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isContest2024studentLogged()) {
        console.log("TLW: Utente loggato dispatch")
        // Dispatcha l'azione Redux per salvare i dati
        dispatch(PassCodeAction.willSaveContest2024StudentSessions(
            {
                "userId": userAttributes?.sub 
            }
        ));
      }
      else {console.log("TLW: Utente non loggato")}
    }, 10000); // Ogni 10 secondi
  
    // Pulisci l'intervallo quando il componente viene smontato
    return () => clearInterval(intervalId);
  }, []);
*/

  // al cambiare della richiesta di cambio di posizione del video, effettua il seek reale
  useEffect(() => {
    if (playerRef.current) {
      console.log(`SEEK TO->currentIndex:${currentVideoPosition.currentIndex}  offset:${currentVideoPosition.currentVideoOffset}`)
      //console.log("playerRef instanziato. getCurrentTime:", playerRef.current.getCurrentTime());
      playerRef.current.seekTo(videoItems[currentVideoPosition.currentIndex].start_offset + currentVideoPosition.currentVideoOffset, 'seconds');

      const { start_time } = videoItems[currentVideoPosition.currentIndex];
      const newPositionDate = moment(start_time).add(currentVideoPosition.currentVideoOffset, "seconds");
      setCurrentPositionDate(newPositionDate);
    }
    else {
      console.log("playerRef non ancora istanziato");
    }
  }, [currentVideoPosition]);


  useEffect(() => {
    // console.log("USE_EFFECT currentPositionDate:", moment(currentPositionDate).format(DATE_TIME_FORMAT))
    updateItemCollisions(currentPositionDate);
  }, [currentPositionDate, showAllAttachments])



  useEffect(() => {
    //console.log(`DEBSUB: CAMBIATO INDICE VIDEO to index ${currentVideoPosition.currentIndex} and offset: ${currentVideoPosition.currentVideoOffset}:`, videoItems[currentVideoPosition.currentIndex]);
  }, [currentVideoPosition.currentIndex])


  useEffect(() => {
    console.log("Cambio di caption lang (useEffect):", selectedLanguage);
    setupSubtitleTracks();
  }, [selectedLanguage])

  useEffect(() => {
    //console.log("DEBSUB: TIMELINE SUBTITLES TRACKS:", subtitlesTracks)
    // piccolo trucco per forzare il rendering del react-player al cambio delle tracce
    // 
    if (volume == 0.5)
      setVolume(0.51) // cambio di volume impercettibile
    else setVolume(0.50)
    // --------------------- //
  }, [subtitlesTracks])

  useEffect(() => {
    if (isPlayerReady) {
      if (props.startDateTime != null) {
        console.log("props.startDateTime:", moment(props.startDateTime).format(DATE_TIME_FORMAT));
        currentPositionDateRef.current = moment(props.startDateTime);
        handleUserPositionChange(props.startDateTime, TraceChangePosition.GOTO_POSITION_BY_PLAYER);
      }
      else {
        console.log("props.startDateTime: null");
        handleUserPositionChange(videoItems[currentVideoPosition.currentIndex].start_time, TraceChangePosition.GOTO_POSITION_BY_PLAYER)
      }

    }
  }, [isPlayerReady, props.startDateTime])

  useEffect(() => {
    if (screenfull.isEnabled) {
      if (fullScreenDomNode != null)
        screenfull.request(fullScreenDomNode);
      else screenfull.exit();
    }
  }, [fullScreenDomNode])


  const setupMobileTimeline = () => {
    console.log("setup mobile timeline");
    if (items == null) {
      setVideoItems([])
      setTagItems([])
      attachmentItems.current = null;
    }
    else {
      setVideoItems(items.filter((item) => item.type == TrackType.VIDEO).sort(
        (v1, v2) => { return moment(v1.start_time) - moment(v2.start_time) }))
      setTagItems(items.filter((item) => item.type == TrackType.TAG).sort(
        (t1, t2) => { return moment(t1.start_time) - moment(t2.start_time) }))
      attachmentItems.current = items.filter((item) => item.type == TrackType.DOC)
      console.log("ATTACHMENTS:", attachmentItems.current)
    }
  }



  const setupSubtitleTracks = () => {
    const timelineSubtitles = [];
    for (let i = 0; i < videoItems.length; i++) {
      const videoItem = videoItems[i];
      const subTracks = []
      if (videoItem != null) {
        const subtitles_it = videoItem.subtitlesUrl || videoItem.subtitlesUrl_IT
        const subtitles_en = videoItem.subtitlesUrl_EN
        if (selectedLanguage == "it" && subtitles_it != null && subtitles_it.length > 0) {
          subTracks.push({
            kind: "subtitles",
            src: subtitles_it,
            srcLang: 'it',
            default: selectedLanguage == "it",
            mode: `${(selectedLanguage == "it") ? "showing" : "hidden"}`
          })
        }
        if (selectedLanguage == "en" && subtitles_en != null && subtitles_en.length > 0) {
          subTracks.push({
            kind: "subtitles",
            src: subtitles_en,
            srcLang: 'en',
            default: selectedLanguage == "en",
            mode: `${(selectedLanguage == "en") ? "showing" : "hidden"}`
          })
        }
      }
      timelineSubtitles.push({ "source": videoItem.source, "tracks": subTracks })
    }
    //console.log("nuove subtitlesTracks:", timelineSubtitles)
    setSubtitleTracks(timelineSubtitles)
  }


  const toggleFullScreen = () => {
    const domNode = findDOMNode(playerContainerRef.current)
    if (fullScreenDomNode != null) {
      setFullScreenDomNode(null);
    }
    else {
      setFullScreenDomNode(domNode);
    }
  }


  const onEventToWatch = (traceEvent, payload) => {
    if (props.onEventToWatch == null || props.watcherIsPaused) return;
    let newPayload = { ...payload }

    /*
    const { collidingItems, currentPositionDate } = this.state;
    newPayload["collidingItems"] = collidingItems.map(item => {
      return {
        "id": item["id"],
        "title": item["title"], 
        "type": item["type"],
        "start_time" : item["start_time"],
        "end_time" : item["end_time"],
        "duration" : item["duration"]
      }
    });
    */
    console.log("PLT: onEventToWatch:", traceEvent)
    const videoPos = ((playerRef.current?.getCurrentTime() || currentVideo.start_offset) - currentVideo.start_offset)
    console.log("PLT: VIDEO POS post")

    newPayload["timelineId"] = props.id;
    newPayload["activeTab"] = props.activeTab;
    newPayload["currentTimelinePosition"] = moment(currentPositionDate).valueOf();
    newPayload["playing"] = isPlaying;
    newPayload["currentVideo"] = pick(videoItems[currentVideoPosition.currentIndex],
      ["id", "title", "source", "description",
        "start_time", "start_offset", "duration", "end_offset", "end_time"]);
    newPayload["videoIndex"] = currentVideoPosition.currentIndex
    newPayload["videoPositionInSecs"] = videoPos;
    newPayload["absVideoPositionInSecs"] = getAbsolutePositionInSeconds(currentVideoPosition.currentIndex, videoPos)
    //newPayload["attachmentsPanelVisible"] = this.state.attachmentsPanelVisible;
    //newPayload["navigatorPanelVisible"] = this.state.itemsNavigatorPanelVisible;
    props.onEventToWatch(traceEvent, newPayload);
  }

  /** 
     * Verifica le intersezioni tra la posizione corrente (o quella specificata)
     * della timeline e i vari items e aggiorna la list
     * Nel caso la posizione corrente non intersechi alcun video la posizione viene aggiornata al video più vicino
     * @param positionDate posizione della timeline di cui verificare le intersezioni
    */
  const updateItemCollisions = (positionDate) => {
    let videoFound = false;
    let newCollidingItems = { [TrackType.TAG]: tagItems[0], [TrackType.DOC]: [], tagFound: false }
    const positionDateCmp = (positionDate == null) ? moment(currentPositionDate) : moment(positionDate);
    for (let index = 0; index < items.length; index++) {
      if (moment(positionDateCmp).isBetween(items[index].start_time, items[index].end_time, null, '[)')) {
        if (items[index].type == TrackType.TAG) {
          newCollidingItems[TrackType.TAG] = items[index]
          newCollidingItems.tagFound = true;
        }

        else if (items[index].type == TrackType.DOC) newCollidingItems[TrackType.DOC].push(items[index])
        else if (items[index].type == TrackType.VIDEO) {
          videoFound = true;
        }
      }
    }
    if (videoFound) {
      if (!newCollidingItems.tagFound) {
        newCollidingItems[TrackType.TAG] = getCloserTag(positionDateCmp);
      }
      //console.log(`new colliding items for pos ${moment(positionDate).format(DATE_TIME_FORMAT)}:`, newCollidingItems);
      if (showAllAttachments) {
        const allItems = { ...newCollidingItems, [TrackType.DOC]: items.filter((item) => { return item.type == TrackType.DOC }) }
        setCollidingItems(allItems)
      }
      else {
        setCollidingItems(newCollidingItems);
      }
    }
    else {
      //console.log("video non trovato, cerco il più vicino");
      gotoClosestNextVideoItem(positionDateCmp);
    }
  }


  const detectVideoIndex = (currentPositionDate) => {
    for (let i = 0; i < videoItems.length; i++) {
      //console.log(`Richiesta video ${i} pos ${moment(currentPositionDate).format(DATE_TIME_FORMAT)} : ${moment(videoItems[i].start_time).format(DATE_TIME_FORMAT)} - ${moment(videoItems[i].end_time).format(DATE_TIME_FORMAT)}`)
      if ((moment(currentPositionDate).isBetween(videoItems[i].start_time,
        videoItems[i].end_time, null, '[)'))) {
        return i;
      }
    }

    return -1;
  }

  const getCloserTag = (currentPositionDate) => {
    let currentTagIndex = 0;
    for (let i = 0; i < tagItems.length; i++) {
      //console.log(`Richiesta video ${i} pos ${moment(currentPositionDate).format(DATE_TIME_FORMAT)} : ${moment(videoItems[i].start_time).format(DATE_TIME_FORMAT)} - ${moment(videoItems[i].end_time).format(DATE_TIME_FORMAT)}`)
      //console.log(`getCloserTag: tag:${i}`, currentPositionDate, tagItems[i])
      if (moment(currentPositionDate).isSameOrAfter(moment(tagItems[i]["start_time"]))) {
        currentTagIndex = i;
      }
    }
    console.log("getCloserTag: currentIndex:", currentTagIndex);
    return tagItems[currentTagIndex];
  }

  const getClosestNextVideoItemIndex = (currentPositionDate) => {
    //console.log("Richiesta video più vicino a currentPositionDate:", moment(currentPositionDate).format(DATE_TIME_FORMAT))
    for (let i = 0; i < videoItems.length; i++)
      if (moment(videoItems[i].start_time).isSameOrAfter(moment(currentPositionDate))) {
        return i;
      }
    return -1;
  }

  const gotoClosestNextVideoItem = (newPositionDate) => {
    const newIndex = getClosestNextVideoItemIndex(newPositionDate);
    //console.log("LOOP_DEB:Indice video più vicino:", newIndex);
    if (newIndex >= 0) {
      //console.log("LOOP_DEB: valore del video;", videoItems[newIndex]["start_time"]);
      //console.log("LOOP_DEB: positionDate di rif;", moment(newPositionDate).format(DATE_TIME_FORMAT));
      // ci si deve posizionare alla posizione iniziale del video corrispondente allo start_offset
      const { start_time } = videoItems[newIndex];
      seekToPositionDate(newIndex, 0); // PATCH ->0.001  mettere 0 provoca loop continuo!

    }
  }
  //  posiziona il video nell'indice specificato alla posizione specificata
  const seekToPositionDate = (index, startOffset) => {
    setCurrentVideoPosition({ currentIndex: index, currentVideoOffset: startOffset });
  }

  // funzione richiamata quando l'utente si sposta su un punto arbitrario della timeline
  const handleUserPositionChange = (newPosition, traceAction) => {
    const newIndex = detectVideoIndex(newPosition);
    //console.log(`HPD: handleUserPositionChange new video index: ${newIndex} in pos: ${moment(newPosition).format(DATE_TIME_FORMAT)}`);
    if (newIndex >= 0) {
      const { start_time, start_offset } = videoItems[newIndex];
      const video_start_offset = moment(newPosition).diff(moment(start_time), "seconds")
      console.log(`Chiesto posizionamento in player ${playerRef.current} video ${newIndex}, START_OFFSET: (${video_start_offset}):`, moment(newPosition).format(DATE_TIME_FORMAT));
      //console.log(`posizionamento con player`, playerRef);
      //console.log(`video_start_offset  CP:${moment(newPosition).format(DATE_TIME_FORMAT)} startTime: ${moment(start_time).format(DATE_TIME_FORMAT)}:`, video_start_offset);
      onEventToWatch(TraceEvent.JUMP_TO_POSITION, {
        "action": traceAction || "",
        "to": {
          "videoIndex": newIndex,
          "videoPositionInSecs": video_start_offset
        }
      }
      )
      seekToPositionDate(newIndex, video_start_offset);
    }
    else gotoClosestNextVideoItem(newPosition);
  }

  const handleProgress = (state) => {
    const { playedSeconds, loadedSeconds } = state;
    // se handleProgress viene chiamato solo per notificare che sta caricando nuove porzioni di video
    // non devo fare nulla
    if (lastPlayedSeconds.current == playedSeconds) {
      // console.log("HPD:lastPlayedSeconds RIPETUTA:", lastPlayedSeconds.current);
      return;
    }
    const { start_offset, end_offset, start_time } = videoItems[currentVideoPosition.currentIndex];
    //console.log(`HPD:HANDLE_PROGRESS RICHIESTO: playedSeconds ${playedSeconds}: loadedSeconds:${loadedSeconds}`);

    if (playedSeconds >= end_offset) {
      handleEnded();
    }
    else {
      if (ignoreHandleProgress.current == false) {
        const newPositionDate = moment(start_time).add(playedSeconds, "seconds").add(-start_offset, "seconds");
        //console.log(`HPD:HANDLE_PROGRESS_GESTITO SU newPositionDate:${newPositionDate.format(DATE_TIME_FORMAT)}`)
        setCurrentPositionDate(newPositionDate);

      }
      else {
        //console.log(`HPD:HANDLE_PROGRESS IGNORATO.. riforzo il setCurrentVideoPosition ${currentPositionDate} VS ${moment(currentPositionDateRef.current).format(DATE_TIME_FORMAT)}`);
        //playerRef.current.seekTo(videoItems[currentVideoPosition.currentIndex].start_offset + currentVideoPosition.currentVideoOffset, 'seconds');
        //handleUserPositionChange(currentPositionDateRef.current)
        //ignoreHandleProgress.current= true;
        //lastPlayedSeconds.current = null;

        // le seguenti istruzioni fanno funzionare correttamente il seek sul secondo video, 
        // ma lo fanno fallire sul primo: (e viceversa se si commentano le 2 righe sequenti!) 
        // [TODO] le 2 righe seguenti sono una PATCH io attesa di trovare una soluzione più pulita e affidabile!
        if (currentPositionDate == null && currentVideoPosition.currentIndex > 0)
          setCurrentVideoPosition({ ...currentVideoPosition });// -> mette il video nella posizione corretta ma crea un ciclo infinito di seek
      }
      ignoreHandleProgress.current = false;
      lastPlayedSeconds.current = playedSeconds;
    }

  };

  const handleEnded = () => {
    console.log("handleEnded");
    if (currentVideoPosition.currentIndex < videoItems.length - 1) {
      // mi posiziono all'inizio del prossimo video
      // n.b: ci pensa playerRef.current.seekTo a tener conto di un eventuale start_offset del video!
      seekToPositionDate(currentVideoPosition.currentIndex + 1, 0);
    } else {
      // metto in pausa se ho terminato tutti i video
      setIsPlaying(false); // necessario per il tracking
      //setCurrentIndex(0); // Loop back to the first video or handle end of playlist
    }
  };

  const gotoPreviousVideo = () => {
    console.log("handleEnded");
    if (currentVideoPosition.currentIndex > 0) {
      // mi posiziono all'inizio del prossimo video
      // n.b: ci pensa playerRef.current.seekTo a tener conto di un eventuale start_offset del video!
      seekToPositionDate(currentVideoPosition.currentIndex - 1, 0);
    }
  }

  const getInitialStartTime = (items) => {
    if (items == null || items.length < 1)
      return moment().startOf("day");

    let firstItem = items[0];
    let firstDateTime = moment(items[0].start_time)
    for (let i = 1; i < items.length; i++) {
      if (moment(items[i].start_time).isBefore(firstDateTime))
        firstDateTime = moment(items[i].start_time)
      firstItem = items[i]
    }
    firstDateTime.subtract(firstDateTime.seconds(), "seconds")
    //console.log(`GIST-> Data iniziale della timeline:${firstDateTime}`);
    //console.log(`Primo item: ${firstItem.id}`);
    return firstDateTime;
  }

  const getFinalEndTime = (items) => {
    if (items == null || items.length < 1)
      return moment().startOf("day");

    let lastItem = items[items.length - 1];
    let lastDateTime = moment(items[items.length - 1].end_time)
    for (let i = 0; i < items.length - 1; i++) {
      if (moment(items[i].end_time).isAfter(lastDateTime))
        lastDateTime = moment(items[i].end_time)
      lastItem = items[i]
    }
    //console.log(`Data finale della timeline:${lastDateTime}`);
    //console.log(`Ultimo item: ${lastItem.id}`);
    return lastDateTime;
  }

  useEffect(() => {
    if (videoItems && currentVideoPosition.currentIndex!=null) {
      console.log("LT: Aggiornamento currentVideo index:", currentVideoPosition.currentIndex)
      setCurrentVideo(videoItems[currentVideoPosition.currentIndex])
    }
  }
    , [videoItems, currentVideoPosition.currentIndex])
  //const currentVideo = videoItems[currentVideoPosition.currentIndex];

  const renderTagsMenu = () => {
    return (
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}

        style={{
          display: "flex",
          border: '2px solid #007bff',
          zIndex: 10,
          flexDirection: "column",
          overflowY: `${isHovered ? "auto" : "hidden"}`,
          justifyContent: "space-between",
          position: 'absolute',
          maxWidth: "30%",
          maxHeight: "100%",
          bottom: 6,
          left: 0,
          backgroundColor: 'black',
          color: 'black',
          padding: '0px',
          borderRadius: '0px',
          cursor: "pointer"
        }}>

        <div style={{
          display: "flex", background: "black",
          marginTop: "10px", marginLeft: "5px", justifyContent: "space-between"
        }}>
          <span style={{ fontSize: `${deviceType == "mobile" ? "1.0em" : "1.4em"}`, marginLeft: "5px", color: "white" }}>
            {t("tl:index_of_contents")}</span>
          <IoMdCloseCircleOutline onClick={(ev) => { setMenuVisible(false) }} size={"1.8em"}
            style={{ marginLeft: "10px", marginRight: "10px", color: "white", cursor: "pointer" }}
          />
        </div>
        <hr style={{ borderTop: '2px solid #007bff', margin: '5px 0 20px 0' }} />
        {tagItems.map((tag, index) => (
          <Card key={`tag_menu_${index}`} onClick={() => {
            handleUserPositionChange(tag.start_time,
              TraceChangePosition.GOTO_POSITION_BY_TAG_MENU)
          }}
            style={{ background: `${collidingItems[TrackType.TAG].id == tag.id ? "#EEEEEE" : "white"}` }}>
            <CardBody className="label-hover-effect">
              <CardTitle tag="h3" key={`tag_${index}_${i18n.language}`} href="#" >
                {`${index + 1} - ${tag.title}`}
              </CardTitle>
              <CardSubtitle>
                {tag.description}
              </CardSubtitle>
            </CardBody>
          </Card>

        ))}
      </div>)
  }

  const renderBufferingSpinner = () => {
    return (<div className="spinner-overlay">
      <Spinner style={{ width: '3rem', height: '3rem' }} />
    </div>)
  }

  const renderAttachmentsMenuInFS = () => {
    if (collidingItems[TrackType.DOC].length < 1) return null;

    return (<div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        display: "flex",
        border: '2px solid #007bff',
        zIndex: 10,
        flexDirection: "column",
        justifyContent: "space-between",
        position: 'absolute',
        maxWidth: "30%",
        background: "black",
        maxHeight: "100%",
        bottom: 6,
        right: 0,
        overflowY: `${isHovered ? "auto" : "hidden"}`,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        color: 'black',
        padding: '20px',
        border: '1px',
        borderColor: "black",
        cursor: "pointer",
        backgroundColor: "black"
      }}>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span style={{ fontSize: `${deviceType == "mobile" ? "1.0em" : "1.4em"}`, color: "white" }}>{t("tl:attachments")}</span>
        <IoMdCloseCircleOutline onClick={(ev) => { setAttachmentsVisible(false) }} size={"1.8em"}
          style={{ marginLeft: "10px", color: "white", cursor: "pointer" }}
        />
      </div>
      <hr style={{ borderTop: '2px solid #007bff', margin: '5px 0 20px 0' }} />
      {collidingItems[TrackType.DOC].map((item, index) => (
        <div key={`Prev_item_div_${index}`}>
          <ItemPreview key={`Prev_item_${index}`} item={item} onEventToWatch={onEventToWatch} />
          <span style={{ marginBottom: "10px" }}></span>
        </div>
      ))}
    </div>)
  }

  const renderAttachmentsMenu = () => {
    return (<div style={{
      background: "black",
      maxHeight: "74vh",
      display: "flex", flex: '0 0 30%', margin: "5px 10px 5px 0px",
      padding: "10px", flexDirection: "column"
    }}>
      <div style={{ display: "flex" }} width="100%">
        <span style={{
          paddingLeft: "10px",
          fontSize: `${deviceType == "mobile" ? "1.0em" : "1.4em"}`, color: "white"
        }}>{t("tl:attachments")}{`  (${collidingItems[TrackType.DOC].length})`}</span>
      </div>
      <div style={{
        display: "flex", justifyContent: "flex-start",
        marginLeft: "30px",
        fontSize: `${deviceType == "mobile" ? "0.5em" : "0.8em"}`, color: "white"
      }}>

        <Input type="checkbox" style={{ marginTop: "2px", color: "white" }} onClick={(event) => {
          //alert(event.target.checked)
          setShowAllAttachments(event.target.checked);
        }} />
        <Label check>
          {t("tl:show_all_attachments")}
        </Label>
      </div>
      <hr style={{ borderTop: '2px solid #007bff', margin: '5px 0 20px 0' }} />
      <div style={{ display: "flex", flexDirection: "column", overflowY: "auto", maxHeight: "70vh" }}>
        {collidingItems[TrackType.DOC].map((item, index) => (
          <div key={`Prev_item_div_${index}`}>
            <ItemPreview key={`Prev_item_${index}`} onEventToWatch={onEventToWatch} item={item} />
            <span style={{ marginBottom: "10px" }}></span>
          </div>
        ))}

      </div>

    </div>)
  }

  const renderCaptionsPanel = () => {
    return (<div style={{
      display: "flex",
      border: '2px solid #007bff',
      zIndex: 10,
      flexDirection: "column",
      justifyContent: "space-between",
      position: 'absolute',
      maxWidth: "30%",
      maxHeight: "100%",
      bottom: 6,
      paddingTop: 10,
      right: 0,
      overflowY: "auto",
      backgroundColor: 'rgba(0, 0, 0, 1)',
      color: 'black',
      padding: '10px',
      borderRadius: '0px',
      cursor: "pointer"
    }}>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span style={{ fontSize: `${deviceType == "mobile" ? "1.0em" : "1.4em"}`, color: "white" }}>{t("tl:captions")}</span>
        <IoMdCloseCircleOutline onClick={(ev) => { setCaptionsPanelVisible(false) }} size={"1.8em"}
          style={{ marginLeft: "10px", color: "white", cursor: "pointer" }}
        />
      </div>
      <hr style={{ borderTop: '2px solid #007bff', margin: '5px 0' }} />
      <CaptionOptionsPanel onChange={(value) => {
        setSelectedLanguage(value)
      }} selectedLanguage={selectedLanguage} />
    </div>)
  }


  if (currentVideo == null || subtitlesTracks.length < 1) return (<p>Loading...</p>)
  else
    return (
      <div className={`${deviceType == "mobile" ? "react-player" : ""}`} style={{ display: "flex", width: "100%", height: "90vh", flexDirection: "row" }}>

        <div ref={playerContainerRef} style={{
          display: "flex", flexDirection: "column", margin: "5px 5px 5px 5px",
          flex: `0 0 ${isFullscreen ? 100 : 70}%`,
          alignContent: "center",
          justifyContent: "flex-start",
        }}>
          <table>
            <tbody>
              <tr>
                <td>
                  <div className="video-container">
                    <ReactPlayer
                      id={`videoplayer_${currentVideoPosition.currentIndex}`}
                      key={`videoplayer_${currentVideoPosition.currentIndex}${playerCaptionskey}`}
                      width={deviceType == "mobile" ? "80vw" : '100%'}
                      height={deviceType == "mobile" ? null :
                        (fullScreenDomNode == null ? "100%" : '85vh')

                      }
                      controls={false}
                      ref={playerRef}
                      volume={volume}
                      muted={muted}
                      pip={pip}
                      url={`${subtitlesTracks[currentVideoPosition.currentIndex]["source"]}`}
                      config={{
                        file: {
                          attributes: {
                            //poster: "https://www.crs4.it/wp-content/uploads/2022/02/logoRiale-1024x247.png",
                            crossOrigin: "true",
                          },
                          tracks: subtitlesTracks[currentVideoPosition.currentIndex]["tracks"]
                        },
                      }}
                      onEnablePIP={() => setPip(true)}
                      onDisablePIP={() => setPip(false)}
                      onReady={(ev) => {
                        //console.log("HPD:VST: ON READY:", ev)
                        ignoreHandleProgress.current = true;
                        lastPlayedSeconds.current = null;
                        setPlayerReady(true);
                      }}
                      onBuffer={(ev) => {
                        setVideoBuffering(true);
                        //console.log("VST: ON BUFFER:", ev)
                      }}
                      onBufferEnd={(ev) => {
                        setVideoBuffering(false);
                        //console.log("VST: ON BUFFER END:", ev)
                      }}
                      onSeek={(seconds) => { console.log("HPD: VST ON SEEK to sec::", seconds); }}
                      playing={isPlaying}
                      onProgress={handleProgress}
                      onEnded={handleEnded}
                      onContextMenu={(event) => {
                        event.preventDefault()
                      }}
                    />
                    <img src="/logo RIALE EU_tr.png" alt="Logo" className="video-logo" />
                    {isMenuVisible && renderTagsMenu()}
                    {areAttachmentsVisible && (isFullscreen || deviceType == "mobile") && renderAttachmentsMenuInFS()}
                    {(isVideoBuffering || !isPlayerReady) && renderBufferingSpinner()}
                    {isCaptionsPanelVisible && renderCaptionsPanel()}
                  </div>
                </td>
              </tr>
              {deviceType == "mobile" ?
                (<tr>
                  <td>
                    <div style={{ marginTop: "-7px", width: "100%" }} className="controls-row">
                      MOBILE VERSION NOT AVAILABLE
                    </div>
                  </td>
                </tr>)
                :
                <>
                  <tr>
                    <td>
                      {
                        <div style={{ marginTop: "-7px", width: "100%" }} className="controls-row">
                          <ControlsPanel
                            key = {`ControlsPanel_${i18n.language}`}
                            deviceType={deviceType}
                            selectedLanguage={selectedLanguage}
                            fullScreenDomNode={fullScreenDomNode}
                            item={currentVideo}
                            collidingItems={collidingItems}
                            volume={volume}
                            isFullscreen={fullScreenDomNode != null}
                            timelineIsPlaying={isPlaying}
                            isVideoLoading={isVideoBuffering}
                            playedSeconds={(playerRef.current?.getCurrentTime() || currentVideo.start_offset) - currentVideo.start_offset}
                            duration={currentVideo.end_offset - currentVideo.start_offset}
                            muted={muted}
                            audioSolo={null}
                            showPipButton={deviceType != "mobile"}
                            showAttachmentsButton={isFullscreen || (deviceType == "mobile")}
                            onToggleMute={() => setMuted(!muted)}
                            onToggleAudioSolo={() => { }}
                            onTogglePip={() => setPip(!pip)}
                            onToggleSubtitlesPanel={() => {
                              if (!isCaptionsPanelVisible) {
                                setAttachmentsVisible(false)
                                setCaptionsPanelVisible(true)
                              }
                              else setCaptionsPanelVisible(false)
                            }}
                            onToggleAttachments={() => {
                              if (!areAttachmentsVisible) {
                                setCaptionsPanelVisible(false)
                                setAttachmentsVisible(true)
                              }
                              else setAttachmentsVisible(false)
                            }}

                            onFullScreenRequest={() => toggleFullScreen()}
                            onPauseTimeline={() => {
                              setIsPlaying(false)
                            }}
                            onPlayTimeline={() => {
                              setIsPlaying(true);
                            }}
                            onGotoPreviousVideo = {()=>{
                              gotoPreviousVideo();
                            }}
                            onGotoNextVideo = {()=>{
                              handleEnded();
                            }}
                            isFirstVideo = {currentVideoPosition.currentIndex==0}
                            isLastVideo = {currentVideoPosition.currentIndex == videoItems.length - 1}
                            
                            onDatetimeChangeRequest={(newPos) => handleUserPositionChange(newPos,
                              TraceChangePosition.GOTO_POSITION_BY_REWIND)}
                            onStartSliderChange={(value) => {
                              if (isPlaying) {
                                playerMustRestartRef.current = true;
                                setIsPlaying(false)
                              }
                            }}
                            onSliderChange={(newPosition) => {
                              console.log("ON SLIDER CHANGE");
                              updateItemCollisions(newPosition)
                            }}
                            onEndSliderChange={(newPosition) => {
                              handleUserPositionChange(newPosition, TraceChangePosition.GOTO_POSITION_BY_SLIDER);
                              if (playerMustRestartRef.current == true) { setIsPlaying(true) }
                              playerMustRestartRef.current = false;

                            }}
                            subtitlesAvailable={true}
                          />
                        </div>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {
                        tagItems.length > 0 &&
                        <TagsButtonGroup
                          key = {`TagsButtonGroup_${i18n.language}`}
                          deviceType={deviceType}
                          style={{ width: `${deviceType == "mobile" ? "80vw" : '100%'}` }}
                          isMenuVisible={isMenuVisible}
                          currentTag={collidingItems[TrackType.TAG] || { tagIndex: 0, "title": "N.A" }}
                          tagFound={collidingItems.tagFound}
                          tags={tagItems}
                          currentPositionDate={currentPositionDate}
                          onToggleMenu={() => { setMenuVisible(!isMenuVisible) }}
                          onPositionChange={(newPosition) => {
                            console.log("Richiesto cambio di posizione:", moment(newPosition).format(DATE_TIME_FORMAT))
                            handleUserPositionChange(newPosition, TraceChangePosition.GOTO_POSITION_BY_TAG_INDEX)
                          }
                          } />
                      }
                    </td>
                  </tr>
                </>

              }

            </tbody>
          </table>
        </div>
        {deviceType != "mobile" && renderAttachmentsMenu()}
      </div>




    )
}
const LightTimeline = withWatcher(InnerLightTimeline)
export default LightTimeline;