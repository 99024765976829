import { createSlice, PayloadAction} from "@reduxjs/toolkit";

export const passCodeUsersSlice = createSlice({
    name: 'passCodeUsers',
    initialState: {
      passCode:null,
      currentAnalyticsSessions:null,
      contest2024Sessions: null,
      contest2024Schools : null,
      contest2024sessionTimestamps : {},
      sessionSavingError: null,
      currentSessionRecordingPath: null
    }
  ,
    reducers: {
     willLogoutWithPassCode: (state, action:PayloadAction<any>) => state,
     willLoadAnalyticsSessions: (state, action:PayloadAction<any>) => state,
     willLoadContest2024Sessions: (state, action:PayloadAction<any>) => state,
     willBuildContest2024Schools: (state, action:PayloadAction<any>) => state,
     willSaveContest2024StudentSessions: (state, action:PayloadAction<any>) => state,
     setCurrentAnalyticsSessions: (state, action:PayloadAction<any>) =>{
       state.currentAnalyticsSessions = action.payload;
     },
     setContest2024Sessions: (state, action:PayloadAction<any>) =>{
      state.contest2024Sessions = action.payload;
    },
     setCurrentSessionRecordingPath: (state, action:PayloadAction<any>) =>{
      state.currentSessionRecordingPath = action.payload;
    },

    setContest2024Schools: (state, action:PayloadAction<any>) =>{
      state.contest2024Schools = action.payload;
    },

    setContest2024SessionTimestamps: (state, action:PayloadAction<any>) =>{
      state.contest2024sessionTimestamps = action.payload;
    },

     resetSessionSavingError: (state) => { 
      state.sessionSavingError = null;
     },

     didSessionSavingError: (state, action:PayloadAction<any>) => { 
      state.sessionSavingError = action.payload;
     },

      didLoginWithPassCode: (state, action:PayloadAction<any>) => { 
        console.log("TLW: didLoginWithPassCode:", action.payload);
        state.passCode = action.payload;
        state.sessionSavingError = null;
      },
      didLogoutWithPassCode: (state) => { 
        state.passCode = null;
      },
      // esporta la sessione su db
      willSaveContest2024Session: (state, action:PayloadAction<any>) => state,
    }
});

export const { actions, reducer } = passCodeUsersSlice

export const selectors = {
  isLoggedWithPassCode: (state:any) => {
    return state.passCodeUsers.passCode!=null;
  },

  getCurrentAnalyticsSessions: (state:any) => {
    return state.passCodeUsers.currentAnalyticsSessions;
  },

  getContest2024Sessions: (state:any) => {
    return state.passCodeUsers.contest2024Sessions;
  },

  getCurrentPassCode: (state:any) => {
    return state.passCodeUsers.passCode;
  },

  getCurrentSessionRecordingPath: (state:any) => {
    return state.passCodeUsers.currentSessionRecordingPath;
  },

  getSessionSavingError: (state:any) => {
    return state.passCodeUsers.sessionSavingError;
  },

  getContest2024Schools: (state:any) => {
    return state.passCodeUsers.contest2024Schools
  },
  getContest2024SessionTimestamps: (state:any) => {
    return state.passCodeUsers.contest2024sessionTimestamps
  }
}
