import { useEffect, useState, useRef } from "react"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ContestStudentsAnalyzer } from "./ContestStudentsAnalyzer";


export const ContestTimelinesAnalyzer = (props) => {
    const { t } = useTranslation('frontend', { useSuspense: false });
    const [timelineRows, setTimelineRows] = useState([])

    useEffect(() => {
        //const timelinesData = props.schema[props.schoolData["id"]]
        console.log("schoolData:::: (contest)", props.schoolData)
        setTimelineRows(props.schoolData["timelines"])
    }, [])

    const expandRow = {
        renderer: row => (
            <div style={{ paddingLeft: "20px" }}>
                <ContestStudentsAnalyzer timelineData={row} cskey={`csan_${props.ctkey}`}
                    schoolData={props.schoolData}
                    schema={props.schema} />
            </div>

        ),
        showExpandColumn: true,
        expandColumnPosition: 'left', // Posizione della colonna
        expandColumnRenderer: ({ expanded }) => (expanded ? '-' : '+'),
        expandHeaderColumnRenderer: ({ isAnyExpands }) => (isAnyExpands ? '-' : '+'),
    };

    const timelineColumns = [
        {
            dataField: 'id',
            text: t("id"),
            sort: true,
            hidden: true
        },
        {
            dataField: 'title',
            text: t("Timeline"),
            sort: true
        },
        {
            dataField: 'catalogExperiment',
            text: t("Esperimento"),
            sort: true
        },
        {
            dataField: 'overview',
            text: t("Riepilogo"),
            sort: false,
            formatter: (cell, row) => {
                return <span>
                    {`${t("Tempo medio di visualizzazione")}: ${(props.schoolData["tracesOverview"][row["id"]]["score"]["mediaSecondiVisti"]/60.0).toFixed(2)} min. (${props.schoolData["tracesOverview"][row["id"]]["score"]["coperturaMediaVideoPerc"].toFixed(2)}%)`}
                    <br></br>
                    {`${t("Media di copertura allegati")}: ${props.schoolData["tracesOverview"][row["id"]]["score"]["coperturaMediaAllegatiPerc"].toFixed(2)}%`}

                </span>
            }
        }
    ]

    return <BootstrapTable bootstrap4 key={props.ctkey}
        keyField='id' data={timelineRows || []}
        columns={timelineColumns}
        expandRow={expandRow}
    />
}