import { useState, useEffect } from 'react';
import { Rnd } from 'react-rnd';
import { useTranslation } from 'react-i18next';
import { VictoryChart, VictoryLine, VictoryAxis, VictoryTooltip, VictoryVoronoiContainer } from 'victory';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IconContext } from "react-icons";
import IconButton from '@material-ui/core/IconButton';
import { FaChartLine } from "react-icons/fa6";
import { IoIosCloseCircleOutline } from "react-icons/io"
import ReactTooltip from "react-tooltip";
import {
    Card, CardBody, CardTitle, Alert,
    CardHeader, CardFooter
} from "reactstrap";

// Funzione per elaborare i dati
const processData = (registrations) => {
    const groupedData = registrations.reduce((acc, curr) => {
        acc[curr.date] = (acc[curr.date] || 0) + 1;
        return acc;
    }, {});

    let cumulativeTotal = 0;
    const cumulativeData = Object.keys(groupedData)
        .sort()
        .map(date => {
            cumulativeTotal += groupedData[date];
            return { date: new Date(date), total: cumulativeTotal };
        });

    return cumulativeData;
};

const CumulativeRegistrationsChart = (props) => {

    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.data.current) {
            const dataArray = props.data.current.map(item => ({
                date: item.create_date.split('T')[0]
            }))

            setData(processData(dataArray))
        }
        else setData([]);

    }, [props.data.current])


    // Stato per la modale
    const [modalIsOpen, setModalIsOpen] = useState(false);

    // Stato per dimensioni e posizione della finestra
    const [size, setSize] = useState({ width: 800, height: 380 });
    const [position, setPosition] = useState({ x: -10, y: -10 });
    const { t, i18n } = useTranslation('frontend', { useSuspense: false });
    // Funzione per aprire e chiudere la finestra
    const toggleModal = () => setModalIsOpen(!modalIsOpen);

    return (
        <div>
            <div>
                <IconButton style={{ ...props.style }} onClick={() => { toggleModal() }}

                >
                    <IconContext.Provider
                        value={{ color: "green", size: "1em" }}>
                        <FaChartLine data-place="top"
                            data-for="chartTooltip" data-tip={t("Mostra grafico")}
                        />
                    </IconContext.Provider>
                </IconButton>
                <ReactTooltip id="chartTooltip" />
            </div>
            {modalIsOpen && (
                <Rnd
                    size={{ width: size.width, height: size.height }}
                    position={{ x: position.x, y: position.y }}
                    onDragStop={(e, d) => setPosition({ x: d.x, y: d.y })}
                    onResizeStop={(e, direction, ref, delta, position) => {
                        setSize({
                            width: parseInt(ref.style.width, 10),
                            height: parseInt(ref.style.height, 10)
                        });
                        setPosition(position);
                    }}
                    minWidth={400}
                    minHeight={300}
                    bounds="window"
                    style={{
                        zIndex: 9999,
                        background: 'white',
                        borderRadius: '8px',
                        boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
                        overflow: 'hidden',
                        border: '1px solid #ddd'
                    }}
                    dragHandleClassName="custom-drag-handle"
                >
                    <Card className="mb-4" style={{ cursor: "move", border: "none", padding: "5px", borderColor: "#007bff" }}>
                        <CardHeader className="custom-drag-handle" style={{
                            backgroundColor: "#007bff", borderColor: "#007bff",
                            paddingBottom: 0, color: 'white',
                            cursor: "move", paddingBottom: "0px",
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                            <CardTitle style={{verticalAlign:"center"}} tag="h5">{`${t("Andamento registrazioni")}`}</CardTitle>
                           
                                    <IoIosCloseCircleOutline size={"1.5em"}
                                    style={{cursor:"pointer", marginRight:"-10px"}}
                                             onClick={()=>toggleModal()}
                                    />
                               
                                
                        </CardHeader>
                        <CardBody className="custom-drag-handle" style={{ cursor: "move", display: "flex", flexDirection: "column" }}>

                    {data.length>0 ? ( <VictoryChart
                                padding={{ top: 45, bottom: 45, left: 45, right: 45 }}
                                width={Math.max(size.width - 60, 400)}   // Evita valori negativi
                                height={Math.max(size.height - 160, 300)} // Evita valori negativi
                                scale={{ x: "time" }}
                                containerComponent={
                                    <VictoryVoronoiContainer
                                        labels={({ datum }) => `Data: ${datum.date.toLocaleDateString()}\nTotale: ${datum.total}`}
                                        labelComponent={<VictoryTooltip style={{ fontSize: 10 }} />}
                                    />
                                }
                            >
                                <VictoryAxis
                                    tickFormat={(x) => new Date(x).toLocaleDateString()}
                                    style={{
                                        tickLabels: { angle: -20, dy: 30, fontWeight: 'bold', 
                                                     fontSize: 14 }
                                    }}
                                />
                                <VictoryAxis dependentAxis />
                                <VictoryLine
                                    data={data}
                                    x="date"
                                    y="total"
                                    style={{
                                        data: { stroke: "#ff7300", strokeWidth: 3 }
                                    }}
                                />
                            </VictoryChart>): <Alert>{t("Nessun risultato trovato")}</Alert>}
                        
                           

                        </CardBody>
                    </Card>
                </Rnd>
            )}
        </div>
    );
};

export default CumulativeRegistrationsChart;
