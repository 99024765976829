// traceAnalyzerUtils.js

import { getVideoDurationFromVideoFile } from "@rajesh896/video-thumbnails-generator";
import moment from "moment";

export const analyzeTrace = (trace, sessionTimestampsNO) => {
    if (!trace) return null;
    //const sessionTimestamps = sessionTimestampsNO.map((item) => moment(item)).sort((a, b) => { return moment(a, "YYYY-MM-DD").toDate() - moment(b, "YYYY-MM-DD").toDate(); })
    let intervalliUniti = [];

    const buildTimelineSegments = (duration) => {
        if (duration === 0 || duration === null) return null;
        return calculateSegments(duration, intervalliUniti);
    };

    const calculateSegments = (duration, intervals) => {
        intervals.sort((a, b) => a.from - b.from);
        const segments = [];
        let previousEnd = 0;

        intervals.forEach(interval => {
            if (interval.from > previousEnd) {
                segments.push({ percentage: ((interval.from - previousEnd) / duration) * 100, color: 'danger' });
            }
            segments.push({ percentage: ((interval.to - interval.from) / duration) * 100, color: 'success' });
            previousEnd = interval.to;
        });

        if (previousEnd < duration) {
            segments.push({ percentage: ((duration - previousEnd) / duration) * 100, color: 'danger' });
        }

        return segments;
    };

    const unisciIntervalli = (intervalli) => {
        if (intervalli.length === 0) return [];

        intervalli.sort((a, b) => a.from - b.from);

        const intervalliUnitiLocal = [intervalli[0]];
        intervalliUniti = intervalliUnitiLocal;

        for (let i = 1; i < intervalli.length; i++) {
            const ultimoIntervallo = intervalliUnitiLocal[intervalliUnitiLocal.length - 1];
            const intervalloCorrente = intervalli[i];

            if (intervalloCorrente.from <= ultimoIntervallo.to) {
                ultimoIntervallo.to = Math.max(ultimoIntervallo.to, intervalloCorrente.to);
            } else {
                intervalliUnitiLocal.push(intervalloCorrente);
            }
        }

        return intervalliUnitiLocal;
    };

    const getClosestStopTimestamp = (actions, ts1, ts2) => {

        const stopDates = actions.filter(action => {

            const result = action.stopDate != null &&
                action.stopDate > ts1 && action.stopDate < ts2
            if (result) console.log(`DC2024: action.stopDate:${action.stopDate} ts1:${ts1} ts2:${ts2}`)
            return result;
        }
        )
        return stopDates && stopDates[0] && stopDates[0]["stopDate"]
    }

    const getCoperturaVideo2 = (videoLength) => {
        const actions = trace.actions;
        let msVisti = 0;
        console.log("DBC:: Actions:", actions)

        // debug TIMELINE_LITE_OPEN con payload["playing"]==true
        const tl_open_playing = actions.filter((action) => {
            return action["type"] == "TIMELINE_LITE_OPEN" && action["payload"]["playing"] == true
        });
        let transformedActions = actions;
        if (tl_open_playing.length > 0) {
            transformedActions = actions.map(event => {
                if (event.type === "TIMELINE_LITE_OPEN" && event.payload.playing) {
                    return {
                        ...event,
                        type: "PLAY_CHANGE",
                        payload: {
                            ...event.payload,
                            isPlaying: true
                        }
                    };
                }
                return event;
            });
            console.log("DBG Watcher TIMELINE_LITE_OPEN playing:", transformedActions)
        }

        /*
        const visibilityChengeEvents = actions.filter((action) => {
            return action["type"]!="PLAY_CHANGE";
        });
        console.log("Contest2024 visibilityChengeEvents:", visibilityChengeEvents);
        */
        const playChangeEvents = transformedActions.filter((action) => {
            return action["type"] === 'PLAY_CHANGE';
        });
        let i = 0;
        let playEventStartTime = null;
        while (i < playChangeEvents.length - 1) {  // PLAY_-> STOP
            if (playChangeEvents[i]["payload"]["playing"] === true &&
                playChangeEvents[i + 1]["payload"]["playing"] === false) {
                msVisti += playChangeEvents[i + 1]["timestamp"] - playChangeEvents[i]["timestamp"]
                i += 2
                playEventStartTime = null;
                continue
            }
            else {
                console.log(`DC2024:Caso anomalo con evento PLAYCHANGE isPlaying:${playChangeEvents[i]["payload"]["playing"]}:`, playChangeEvents[i]["formattedDatetime"])
                // PLAY->PLAY
                if (playChangeEvents[i]["payload"]["playing"] === true) {
                    const nextStopTimestamp = getClosestStopTimestamp(actions, playChangeEvents[i]["timestamp"], playChangeEvents[i + 1]["timestamp"])
                    if (nextStopTimestamp != null && !isNaN(nextStopTimestamp)) {
                        console.log("DC2024: Trovato stop timestamp:", nextStopTimestamp)
                        msVisti += nextStopTimestamp - (playEventStartTime || playChangeEvents[i]["timestamp"])
                    }
                    else{
                        console.log("DC2024: Attenzione, ci sono 2 play senza traccia di uno stop prima..da gestire")
                        playEventStartTime = playEventStartTime || playChangeEvents[i]["timestamp"]
                    }
                }

                //console.log("DC2024:Timestamps disponibili:", sessionTimestamps)
                i += 1
            }
        }
        const secondiVisti = msVisti / 1000.0
        const percentualeVista = (secondiVisti / videoLength) * 100;

        return {
            "coperturaVideoPercentuale": percentualeVista,
            "secondiVisti": secondiVisti,
            "info": `${secondiVisti.toFixed(2)} secs. (${percentualeVista.toFixed(2)}%)`
        };
    }

    const getCoperturaVideo = (videoLength) => {
        const actions = trace.actions;
        /*
        // erano stati disabilitati....
        const gotoEvents = actions.filter((action) => {
            return action["type"].startsWith('JUMP');
        });
        if (gotoEvents.length>0)
        {
            console.log("GOTO:: TROVATI GOTOEVENTS:", gotoEvents);
        }
        else console.log("GOTO:: NON TROVATO")
        */

        const playChangeEvents = actions.filter((action) => {
            return action["type"] === 'PLAY_CHANGE';
        });

        const intervalliVisti = [];

        try {

            let i = 0;
            while (i < playChangeEvents.length - 1) {
                // devo assicurarmi di trovare delle coppie consegutive playing true -> false
                // se trovo 2 volte "true" vado avanti di una posizione fino a che
                // non trovo una coppia valida
                if (playChangeEvents[i]["payload"]["playing"] === true &&
                    playChangeEvents[i + 1]["payload"]["playing"] === false) {

                    const newFrom = playChangeEvents[i]["payload"]["absVideoPositionInSecs"]
                    const newTo = playChangeEvents[i + 1]["payload"]["absVideoPositionInSecs"]
                    const timestampDiff = (playChangeEvents[i + 1]["timestamp"] - playChangeEvents[i]["timestamp"]) / 1000.0
                    if (timestampDiff > (newFrom - newTo)) {
                        console.log(`WARNING:: Rilevata anomalia: timestampDiff:${timestampDiff} videoTimeDiff:${newFrom - newTo} `);
                    }
                    if (newFrom > newTo) {
                        intervalliVisti.push({
                            from: newFrom,
                            to: newTo
                        });
                    }
                    else {
                        // in questo caso si è raggiunti la fine del video "from"
                        // e va considerato un intervallo che va dal from fino alla fine
                        // del video! Il nuovo "to" sarà relativo al prossimo intervallo 
                        // absVideoPositionInSecs - videoPositionInSecs + duration
                        const fixedTo = (playChangeEvents[i]["payload"]["absVideoPositionInSecs"] -
                            playChangeEvents[i]["payload"]["videoPositionInSecs"]) +
                            (playChangeEvents[i]["payload"]["currentVideo"]["end_offset"] -
                                playChangeEvents[i]["payload"]["currentVideo"]["start_offset"])

                        if (isNaN(fixedTo)) {
                            console.log("ERROR: invalid fixedTo:", playChangeEvents[i]["payload"])
                        }
                        intervalliVisti.push({
                            from: newFrom,
                            to: fixedTo
                        });
                    }

                    i += 2
                    continue
                }
                else
                    i += 1;
            }
            console.log("Intervalli visti:", intervalliVisti)
            const intervalliUnitiLocal = unisciIntervalli(intervalliVisti);

            const secondiVisti = intervalliUnitiLocal.reduce((totale, intervallo) => {
                return totale + (intervallo.to - intervallo.from);
            }, 0);

            const percentualeVista = (secondiVisti / videoLength) * 100;

            return {
                "coperturaVideoPercentuale": percentualeVista,
                "secondiVisti": secondiVisti,
                "info": `${secondiVisti.toFixed(2)} secs. (${percentualeVista.toFixed(2)}%)`
            };

        } catch (ex) {
            console.error("Impossibile calcolare la copertura video:", ex);
            return "n.a";
        }
    };

    const processTrace = () => {
        const actions = trace.actions;

        if (actions != null) {
            const info = actions.find((action) => {
                return action["type"] === 'TIMELINE_LITE_OPEN';
            });

            if (info == null) {
                // se non trovo informazioni sulla timeline aperta significa che ho filtrato
                // le azioni della sessione corrente, quindi ignoro direttamente la traccia corrente
                return null;
            }

            const totaleAllegati = (info["payload"] && info["payload"]["attachmentItems"]?.length) || 0;
            const durataTotaleVideo = info["payload"]["videoItems"].reduce(
                (acc, video) => acc + video["duration"], 0
            );

            const attachmentClicks = actions.filter((action) => {
                return action["type"] === 'CLICK_ATTACHMENT';
            });

            const totaleClickAllegati = attachmentClicks?.length || 0;
            const resocontoClickAllegati = attachmentClicks.reduce((resoconto, attachment) => {
                const id = attachment.payload.item.id;
                resoconto[id] = (resoconto[id] || 0) + 1;
                return resoconto;
            }, {});

            const numeroAllegatiAperti = Object.keys(resocontoClickAllegati).length;
            const percentualeAllegatiAperti = (totaleAllegati == 0 ? 0 : 100 * (numeroAllegatiAperti / totaleAllegati));
            //const coperturaVideo = getCoperturaVideo(durataTotaleVideo);
            const coperturaVideo = getCoperturaVideo2(durataTotaleVideo);
            //const segmentsAll = buildTimelineSegments(durataTotaleVideo);

            const segments = [{ percentage: coperturaVideo["coperturaVideoPercentuale"], color: 'success' }]
            return {
                id: info["payload"]["timelineId"],
                durata: durataTotaleVideo,
                numeroVideo: info["payload"]["videoItems"].length,
                numeroAllegati: totaleAllegati,
                numeroAllegatiAperti: numeroAllegatiAperti,
                percentualeAllegatiAperti: percentualeAllegatiAperti,
                clickAllegati: totaleClickAllegati,
                coperturaVideo: coperturaVideo,
                videoIntervals: segments
            };
        }

        return null;
    };

    // Calcola i dati della traccia e restituiscili
    return processTrace();
};
