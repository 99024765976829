
// react export excel
//https://www.npmjs.com/package/react-export-excel
//https://github.com/rdcalle/react-export-excel#readme

import { useEffect, useState } from 'react';
import sardiniaFlag from '../assets/sardinia_flag.png'
import { useTranslation } from 'react-i18next';
import { Header } from '../header'
import { Content } from '../components/Content';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectors as AppointmentsSelector, actions as AppointmentActions, getAppointmentById,
  Appointmentstate, isNoLongerAvailable
} from '../store/slices/appointments'
import { actions as CatalogActions, selectors as CatalogSelector } from '../store/slices/catalog'
import { actions as UsersActions } from '../store/slices/users'
import { selectors as UiSelector, actions as UiActions } from '../store/slices/ui'
import { selectors as ProfileSelectors } from '../store/slices/profile'
import moment from 'moment';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import {
  Container, Row, Label, TabContent, TabPane, Nav, NavItem, NavLink,
  Alert, Card, CardBody, CardLink, Button,
  CardHeader, CardFooter, CardTitle, CardSubtitle, Badge, Input, CardText
} from 'reactstrap'

import { DirectorAppointmentEditor } from '../components/directorAppointmentsEditor';
import { DirectorRemoteDevices } from '../components/DirectorRemoteDevices';

import ReportDownloader from "./SessionExcelReport"
import { RegisteredUsersViewer } from '../components/usersViewer'
import {
  BOOKED_WITHOUT_DIRECTION_COLOR,
  BOOKED_WITH_DIRECTION_COLOR,
  UNBOOKED_COLOR,
  NO_LONGER_AVAILABLE_COLOR,
  RESERVED_COLOR
} from '../consts/colors'

import { SYNCHRONOUS_SESSION_TYPE,INTERACTIVE_SESSION_TYPE } from '../store/sagas/remotesessions';

export const DirectionDashboardPage = () => {

  const localizer = momentLocalizer(moment);
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });


  const dispatch = useDispatch();
  const userAttributes: any = useSelector(ProfileSelectors.getProfile);
  const appointments = useSelector(AppointmentsSelector.getAppointments);
  const interactiveSessionAppointments = useSelector(AppointmentsSelector.getInteractiveSessions);
  const experiments = useSelector(CatalogSelector.getExperiments);
  const sessionDirectors = useSelector(AppointmentsSelector.getAllSessionDirectors);
  const isLoadingDirectorDashboard = useSelector(AppointmentsSelector.isLoadingDirectorDashboard);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const isPageVisible = () => {
    return userAttributes != null && userAttributes.groups != null && userAttributes.groups.includes("director")
  };
  const directors = useSelector(AppointmentsSelector.getDirectors);
  const [activeTab, setActiveTab] = useState("0");

  useEffect(() => {
    /* NON NECESSARIO: dati già caricati dopo il recupero del profilo utente nel caso di 'director'
    dispatch(AppointmentActions.willLoadDirectors());
    dispatch( AppointmentActions.willLoadAppointmentsAndDirectors(
      {"start" : "1900-01-20T10:00:00.000Z","end" : "2050-01-20T10:00:00.000Z"}
    ));
    */
    dispatch(CatalogActions.willLoadExperiments());
    dispatch(UsersActions.willGetRegisteredUsers());
  }, []);


  const getDirectorsInitialNames = (appDirectors: any) => {
    if (appDirectors == null || appDirectors.length < 1 || directors == null) return ""
    let msg = "["

    const sessionDirectors = directors.filter((sessionDir: any) => {
      return appDirectors.some((sd: any) => sd["director"] == sessionDir["director"]);
    })

    for (let i = 0; i < sessionDirectors.length; i++) {
      //console.log("Valore di appDirectors:", sessionDirectors[i]);
      if (i > 0) msg += ","
      msg += sessionDirectors[i]["initials"]
      //const initialNames = sessionDirectors[i]["name"].split(" ");
      //initialNames.forEach((element:any) => {msg+=element[0].toUpperCase()}); 
    }

    msg += "]"
    return msg;
  }


  const openDirectorAppointmentEditor = (event: any, type:any) => {
    const appointment = getAppointmentById(type==SYNCHRONOUS_SESSION_TYPE ? appointments : interactiveSessionAppointments, event.id);
    //console.log("(editAppointment) Passo il payload : (da director)", appointment);
    setSelectedAppointment(appointment);
    dispatch(UiActions.openDirectorAppointmentEditor(appointment));
  }

  const renderTitle = (ev: any) => {
    //console.log("Calendar Event:", ev);
    const eventId = ev["title"];
    const experiment = experiments && experiments[eventId];
    //console.log("richiamato render title con experiments:::", experiments);

    const appointmentDirectors = sessionDirectors[ev["id"]]
    //console.log("Appointment_directors:", appointmentDirectors)
    const initials = getDirectorsInitialNames(appointmentDirectors);
    let title = "";
    if (experiment != null) {
      //console.log("rendo ", experiment["titolo"]);
      title = `${initials} ${experiment["titolo"]}`;
    }

    else
      title = ev["title"];
    return ev.reservedToSardinianTeachers ? (
      <div style={{ display: "flex" }}>
        <img src={sardiniaFlag} height="16px"
          style={{
            "border": "1px solid black", "marginTop": "4px", "marginBottom": "2px",
            "marginLeft": "4px", "marginRight": "4px"
          }} />{title}
      </div> as any) :
      `${title}`
  }

  const renderTooltip = (ev: any) => {
    //console.log("Calendar Event:", ev);
    const eventId = ev["title"];
    const experiment = experiments && experiments[eventId];
    //console.log("richiamato render title con experiments:::", experiments);

    const appointmentDirectors = sessionDirectors[ev["id"]]
    //console.log("Appointment_directors:", appointmentDirectors)
    const initials = getDirectorsInitialNames(appointmentDirectors);

    if (experiment != null) {
      //console.log("rendo ", experiment["titolo"]);
      return `${initials} ${experiment["titolo"]}`;
    }

    else
      return ev["title"];

  }



  const handleEvent = (type:any)=> (
    event: any,
    start: any,
    end: any,
    isSelected: any
  ) => {
    const appointmentDirectors = sessionDirectors == null ? null : sessionDirectors[event.id]
    const currentAppointment = getAppointmentById(type==SYNCHRONOUS_SESSION_TYPE ? 
      appointments : interactiveSessionAppointments, event.id);
    //console.log("appointmentDirectors:", appointmentDirectors);
    const eventColor = (currentAppointment != null &&
      currentAppointment.state == Appointmentstate.BOOKED || currentAppointment.state == Appointmentstate.PARTIALLY_BOOKED) ?
      ((appointmentDirectors == null || appointmentDirectors.length < 1) ?
        BOOKED_WITHOUT_DIRECTION_COLOR :
        BOOKED_WITH_DIRECTION_COLOR) :
      (isNoLongerAvailable(currentAppointment) ? NO_LONGER_AVAILABLE_COLOR :
        (currentAppointment.reservedTo == null ? UNBOOKED_COLOR : RESERVED_COLOR));

    ////console.log(`Event color:${eventColor} state:${currentAppointment.state}`);
    return { style: { color: "white", backgroundColor: eventColor } }
  }

  const loadingMsg = (type:any) => {return (isLoadingDirectorDashboard && type==SYNCHRONOUS_SESSION_TYPE) ?
    `(${t("Attendere il caricamento dei dati della regia")}...)` : ""}

  const renderDirectorAppointmentsPane = (paneAppointments:any, type:any) =>
  {
    return (
<Card className="m-4" style={{
                borderColor: "#007bff",
              }}>
                <CardHeader data-tip={t("Elenco delle prenotazioni")} style={{
                  backgroundColor: "#007bff",
                  borderColor: "#007bff",
                  paddingBottom: 0,
                  color: 'white'

                }}>
                  <CardTitle tag="h5">
                    {`${type==SYNCHRONOUS_SESSION_TYPE ?  
                    t("Elenco delle prenotazioni alle sessioni sincrone"):
                    t("Elenco delle prenotazioni alle sessioni pratiche")
                    } ${loadingMsg(type)}`}
                    {!isLoadingDirectorDashboard && paneAppointments && experiments && sessionDirectors &&

                      <ReportDownloader appointments={paneAppointments} experiments={experiments}
                        type={type}
                        directors={directors}
                        sessionDirectors={sessionDirectors} />

                    }
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Container fluid>
                    <Calendar
                      culture={i18n.language}
                      selectable={true}
                      popup={true}
                      localizer={localizer}
                      events={paneAppointments}
                      startAccessor="startDate"
                      endAccessor="stopDate"
                      titleAccessor={(ev) => renderTitle(ev)}
                      tooltipAccessor={(ev) => renderTooltip(ev)}
                      onSelectEvent={(event) => { 
                        if (!isLoadingDirectorDashboard || type==INTERACTIVE_SESSION_TYPE) openDirectorAppointmentEditor(event, type) }}
                      onSelectSlot={(event) => {  }}
                      style={{ height: "calc(100vh - 200px)" }}
                      eventPropGetter={handleEvent(type)}
                      messages={{ month: t("Month"), week: t("Week"), day: t("Day"), agenda: t("Agenda"), previous: t("Back"), today: t("Today"), next: t("Next"), showMore: (count: any) => `${t("Show more", { count })}` }}
                    //views={{ month: true, week: MyWeek }}
                    />
                    <DirectorAppointmentEditor type={type} appointment={selectedAppointment} />
                    <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {
                        type==SYNCHRONOUS_SESSION_TYPE ? (
                          <>
                          <Badge style={{ margin: '5px', padding: '5px', color: 'white', backgroundColor: BOOKED_WITH_DIRECTION_COLOR }}>{t("Prenotato con Regia")}</Badge>
                          <Badge style={{ margin: '5px', padding: '5px', color: 'white', backgroundColor: BOOKED_WITHOUT_DIRECTION_COLOR }}>{t("Prenotato senza Regia")}</Badge>
                          </>

                        ) : (
                          <>
                          <Badge style={{ margin: '5px', padding: '5px', color: 'white', backgroundColor: BOOKED_WITHOUT_DIRECTION_COLOR }}>{t("Prenotato")}</Badge>
                          </>
                        )
                      }
                      
                      
                      <Badge style={{ margin: '5px', padding: '5px', color: 'white', backgroundColor: UNBOOKED_COLOR }}>{t("Non prenotato")}</Badge>
                      <Badge style={{ margin: '5px', padding: '5px', color: 'white', backgroundColor: RESERVED_COLOR }}>{t("Riservato")}</Badge>

                      <Badge style={{ margin: '5px', padding: '5px', color: 'white', backgroundColor: NO_LONGER_AVAILABLE_COLOR }}>{t("Non disponibile")}</Badge>
                    </Row>
                  </Container>

                </CardBody>

              </Card>
    )
  }

  return (<>

    <Header className="mb-0 text-white" section={t("Regia della piattaforma")} showMenu={false} />
    <Content active="direction" className="pt-3">
      {isPageVisible() ?
        <>
          <Nav tabs>
            <NavItem>
              <NavLink style={activeTab === '0' ?
                { cursor: "arrow", fontWeight: "bold", background: "#EEEEEE" } : { cursor: "pointer", fontWeight: "normal" }}

                onClick={() => { setActiveTab('0'); }}
              >
                {t("Sessioni sincrone")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={activeTab === '1' ?
                  { cursor: "arrow", fontWeight: "bold", background: "#EEEEEE" } : { cursor: "pointer", fontWeight: "normal" }}

                onClick={() => { setActiveTab("1"); }}
              >
                {t("Sessioni pratiche")}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={activeTab === '2' ?
                  { cursor: "arrow", fontWeight: "bold", background: "#EEEEEE" } : { cursor: "pointer", fontWeight: "normal" }}

                onClick={() => { setActiveTab("2"); }}
              >
                {t("Dispositivi remotizzati")}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={activeTab === '3' ?
                  { cursor: "arrow", fontWeight: "bold", background: "#EEEEEE" } : { cursor: "pointer", fontWeight: "normal" }}

                onClick={() => { setActiveTab("3"); }}
              >
                {t("Utenti")}
              </NavLink>
            </NavItem>


          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="0">
              {renderDirectorAppointmentsPane(appointments,SYNCHRONOUS_SESSION_TYPE)}
              </TabPane>
              <TabPane tabId="1">
              {renderDirectorAppointmentsPane(interactiveSessionAppointments, INTERACTIVE_SESSION_TYPE)}
              </TabPane>
            <TabPane tabId="2">
              <DirectorRemoteDevices />
            </TabPane>
            <TabPane tabId="3">
              <RegisteredUsersViewer />
            </TabPane>
          </TabContent>
        </>
        :
        <Alert style={{ "marginTop": "10px" }} className="text-center" color="danger">{t("Questa sezione è accessibile solo agli amministratori della piattaforma")}</Alert>
      }
    </Content>
  </>)
}