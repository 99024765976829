import StudentsListManager from "./StudentsManager"
import { actions as UsersActions, selectors as UsersSelectors } from '../../store/slices/users'
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useTranslation } from 'react-i18next';
import { Spinner } from "reactstrap";
import { configuration } from '../../config'
import {getStudentsByTeacherCode} from '../../api/registered_users'
const Contest2024Partecipants = (props) => {

    const contest2024EnabledTeachers = configuration[process.env.REACT_APP_STAGE || "beta"]["contest2024teachers"]
    
    const registeredUsers = useSelector(UsersSelectors.getRegisteredUsers);
    const dispatch = useDispatch();
    const [ contest2024Teachers, setContest2024Teachers] = useState(null)
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const { t } = useTranslation('frontend', { useSuspense: false });
    const [registeredStudentsDict, setRegisteredUsersDict] = useState({});
    const paginationOptions = {
        sizePerPageList: [
            {
                text: '5th', value: 5
            },
            {
            text: '10th', value: 10
        },
        {
            text: '20th', value: 20
        }
        ], pageStartIndex: 1
    }

    useEffect(() => {
        dispatch(UsersActions.willGetRegisteredUsers());
    }, [])

    useEffect( ()=>{
        const loadStudents = async (selectedTeacher) => {
            const myStudents = await getStudentsByTeacherCode(selectedTeacher.id);
            console.log("TEACHER STUDENTS:", myStudents)
            setRegisteredUsersDict({...registeredStudentsDict, [selectedTeacher.id]: myStudents})
        }
        if (selectedTeacher!=null && registeredStudentsDict[selectedTeacher.id]==null)
            loadStudents(selectedTeacher);
    }, [selectedTeacher])


    useEffect(() => {
        if (registeredUsers != null  && registeredUsers.length>0)
            setContest2024Teachers(registeredUsers.filter((user) => 
               { return contest2024EnabledTeachers.includes(user.email)})
            )
    }, [registeredUsers])

    useEffect(() => {
        console.log("Contest2024 teachers:", contest2024Teachers)
    }, [contest2024Teachers])

    const columns = [
        {
            dataField: 'id',
            text: 'id',
            hidden: true
        },

        {
            dataField: 'family_name',
            text: t('Surname'),
            sort: true
        },
        {
            dataField: 'given_name',
            text: t('Name'),
            sort: true
        },
        {
            dataField: 'school',
            text: t("school"),
            sort: true
        },
        {
            dataField: 'email',
            text: t('Email'),
            sort: true
        }
    ]


    const selectRow = {
        mode: 'radio', bgColor: "#DAF994",
        onSelect: (row, isSelect, rowIndexy, e) => {
            //console.log("Selezionato studente:", row);
            setSelectedTeacher(row)
        }
    };

    const renderLoading = () => {
        return (<div style={{ display: "flex", marginTop:"10px", flex: "center", flexDirection: "column", justifyContent: "center" }}>
            <center>
                <div style={{ marginBottom: "5px", fontSize: "1.3em", fontWeight: "bold" }}>{t("Caricamento in corso...")}</div>
                <Spinner />
            </center>

        </div>)
    }

    if (contest2024Teachers == null) return (renderLoading())
    else
        return (<div style={{ display: "flex", flexDirection: "column" }}>

            <BootstrapTable bootstrap4 selectRow={selectRow} keyField='id' data={
                contest2024Teachers
            }
                columns={columns} pagination={paginationFactory(paginationOptions)} />
            {
                selectedTeacher && (
                    registeredStudentsDict[selectedTeacher.id]!=null ?

                (<StudentsListManager 
                key={`slm_${selectedTeacher.id}`} 
                teacherId={selectedTeacher.id}
                students={registeredStudentsDict[selectedTeacher.id]} 
                readOnly />) : renderLoading()
                )
            }

        </div>)
}


export default Contest2024Partecipants