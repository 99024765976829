// hooks/useLocalizedItems.js
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useLocalizedItems = (items) => {
  const { i18n } = useTranslation();
  console.log("Richiamata useLocalizedItems")
  const localizedItems = useMemo(() => {
    const newItems =  items.map(item => ({
      ...item, // Copia tutte le proprietà esistenti
      title: i18n.language === 'it-IT' 
        ? (item.title_IT || item.title || "(Nessun titolo disponibile)") 
        : (item.title_EN || item.title || "(No title available)"),
        description: i18n.language === 'it-IT' 
        ? (item.description_IT || item.description || "") 
        : (item.description_EN || "")
    }));
    console.log("USI: useLocalizedItems ->", newItems)
    return newItems;
  }, [items, i18n.language]); // Si aggiorna solo quando cambia la lingua o gli items

  return localizedItems;
};

export default useLocalizedItems;
