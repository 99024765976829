import {createSlice, PayloadAction } from "@reduxjs/toolkit";
//import update from 'immutability-helper';
import moment from 'moment';
export const experimentsSlice = createSlice({
    name: 'experiments',
    initialState: {
      experiments: null,
      publicExperiments: {} as any,
      currentPublicExperiment:null as any,
      currentExperiment:null as any,
      uploadedResourceUrl:null as any,
      currentIDeviceParams: null as any,
      publicLiteTimelines: {} as any 
    }
    ,
    reducers: {
      willLoadExperiments: (state, action: PayloadAction<any>) => state,
      willLoadPublicExperiments: (state, action: PayloadAction<any>) => state,
      willAddExperiment: (state, action: PayloadAction<any>) => state,
      willUpdateExperiment: (state, action: PayloadAction<any>) => state,
      willDeleteExperiment: (state, action: PayloadAction<any>) => state,
      willCloneExperiment: (state, action: PayloadAction<any>) => state,
      willPublishExperiment: (state, action: PayloadAction<any>) => state,
      willRestoreExperiment: (state, action: PayloadAction<any>) => state,
      willClonePublicExperiment: (state, action: PayloadAction<any>) => state,
      willProposePublicExperiment: (state, action: PayloadAction<any>) => state,
      willSharePublicExperiment: (state, action: PayloadAction<any>) => state,
      willGetExperiment: (state, action: PayloadAction<any>) => state,
      willGetPublicExperiment: (state, action: PayloadAction<any>) => state,
      willUploadFile: (state, action: PayloadAction<any>) => state,
      willSendCommand: (state, action: PayloadAction<any>) => state,
      willGetIDeviceConnectionParams: (state, action: PayloadAction<any>) => state,
      setCurrentIDeviceParams: (state, action: PayloadAction<any>) =>   {
        state.currentIDeviceParams = action.payload;
        },
      setUploadedResorceUrl:  (state, action: PayloadAction<any>) =>   {
      state.uploadedResourceUrl = action.payload;
      },
      
      setPublicLiteTimeline : (state, action: PayloadAction<any>) => {
        //console.log("setPublicLiteTimeline payload:", action.payload)
        const {experiment, isLite, title} = action.payload;
        if (experiment!=null)
        state.publicLiteTimelines[experiment] = { "title" : title, "isLite" : isLite};
      },
      setExperiments :  (state, action: PayloadAction<any>) => 
      { 

        ////console.log("set Experiments:", action.payload);
        const newExperiments = action.payload;

        // Ritrasformo le sezioni JSON di ciascun esperimento in oggetti Javascript
        for(let i=0;i<newExperiments.length;i++)
         { 
          ////console.log("set Experiments: Analyizing:", newExperiments[i]); 
          if (newExperiments[i].sections==null) continue;
          
          for (let j=0;j<newExperiments[i].sections.length;j++)
            {
              newExperiments[i].sections[j] = JSON.parse(newExperiments[i].sections[j]);
            }
        }
        
        state.experiments = newExperiments;
      },

      setCurrentExperiment: (state, action: PayloadAction<any>) => 
      {
        const currentExperiment = action.payload;
        //console.log("setCurrentExperiment:", currentExperiment);
        if (currentExperiment!=null)
        {
          for (let j=0;j<currentExperiment.sections.length;j++)
            {
              currentExperiment.sections[j] = JSON.parse(currentExperiment.sections[j]);
            }
        }
       
        state.currentExperiment = currentExperiment;
        //console.log("setCurrentExperiment aggiornato");
      },


      setCurrentPublicExperiment: (state, action: PayloadAction<any>) => 
      {
        const currentPublicExperiment = action.payload;
        //console.log("setCurrentPublicExperiment:", currentPublicExperiment);
        if (currentPublicExperiment!=null)
        {
          for (let j=0;j<currentPublicExperiment.sections.length;j++)
          {
            currentPublicExperiment.sections[j] = JSON.parse(currentPublicExperiment.sections[j]);
          }
        }
       
        state.currentPublicExperiment = currentPublicExperiment;
        //console.log("setCurrentPublicExperiment aggiornato");
      },

      setPublicExperiments :  (state, action: PayloadAction<any>) => 
      { 
        const data = action.payload;
        //console.log("set Public Experiments:", data);
        const experimentID = data.id;
        const publicExperiments = data.experiments;

        // Ritrasformo le sezioni JSON di ciascun esperimento in oggetti Javascript
        for(let i=0;i<publicExperiments.length;i++)
         { 
         // //console.log("set Experiments: Analyizing:", i); 
          for (let j=0;j<publicExperiments[i].sections.length;j++)
            {
              publicExperiments[i].sections[j] = JSON.parse(publicExperiments[i].sections[j]);
            }
        }
        //console.log("PEXP: state.publicExperiments:", state.publicExperiments);
        state.publicExperiments[experimentID] = publicExperiments.sort((a:any,b:any) =>
        {
          const t1 = moment( (a as any).creationDate);
          const t2 = moment( (b as any).creationDate);
          return (t1 as any)- (t2 as any);
        });
      },

      didAddSection :  (state, action: PayloadAction<any>) => {
        
        const data = action.payload;
        //console.log("Aggiunta di una sezione di un esperimento:", data);
        const experiment = getExperimentById(state.experiments,data.id);
        //console.log("Esperimento da aggiornare:", experiment.sections.length);
        experiment.sections.push(data.section);
        //console.log("Sezione aggiunta:", experiment);
      },

      didUpdateSection :  (state, action: PayloadAction<any>) => {
        // Aggiornamento sezione di un esperimento
        const data = action.payload;
        const experiment = getExperimentById(state.experiments,data.id);
        ////console.log("Change section of experiment:", experiment.sections[data.sectionIndex]["title"] );
        experiment.sections[data.sectionIndex]["title_IT"] = data.title_IT;
        experiment.sections[data.sectionIndex]["title_EN"] = data.title_EN;
        experiment.sections[data.sectionIndex]["description_IT"] = data.description_IT;
        experiment.sections[data.sectionIndex]["description_EN"] = data.description_EN;
        experiment.sections[data.sectionIndex]["isLite"] = data.isLite;
      },

      didSetSections :  (state, action: PayloadAction<any>) => {
        // Ripristino delle sezioni
        const data = action.payload;
        const experiment = getExperimentById(state.experiments,data.id);
        experiment.sections = data.sections;
      },

      didDeleteSection :  (state, action: PayloadAction<any>) => {
        // Rimozione di una sezione di un esperimento
        const data = action.payload;
        const experiment = getExperimentById(state.experiments,data.id);
        experiment.sections = experiment.sections.filter(
          (section:any,index:number)=>{return index!=data.sectionIndex});
      },

      didUpdateItem: (state, action: PayloadAction<any>) =>{
        const data = action.payload;
        //console.log("change experiment payload:", data);
        let experiment = getExperimentById(state.experiments, data.id);
        
        let index = getItemIndexById(experiment.sections[data.sectionIndex].items, data.item.id)
        if (data.event==="Created")
        {
          //console.log("Item create!");
          experiment.sections[data.sectionIndex].items.push(data.item);
        }
        else if (data.event==="Changed")
        { //console.log("Item change!", index);
          //console.log("change item:",experiment.sections[data.sectionIndex]);
          experiment.sections[data.sectionIndex].items[index]=data.item
        }
        else if  (data.event==="Deleted")
         {
          //console.log("Item deleted!", index);
          experiment.sections[data.sectionIndex].items.splice(index,1);
         }
      }
    }
    
  });
  
  
  export const { actions, reducer }: any = experimentsSlice


 const getExperimentById = (experiments:any, id:any) =>
  {
    if (experiments==null) return null;

    for (let i=0;i<experiments.length;i++)
    {
      if (experiments[i].experiment===id) 
      return experiments[i];
      };
    return null;
  }

  const getItemIndexById = (items:any, id:any) =>
  {
    
    for (let i=0;i<items.length;i++)
    {
      if (items[i].id===id) 
      return i;
      };
    return -1;
  }
  
  export const selectors = {

    getExperiment: (id:any) => (state: any) => {
     return getExperimentById(state.experiments.experiments,id);
    },

    getCurrentExperiment: (state: any) => {
      return state.experiments.currentExperiment;
     },

    getCurrentPublicExperiment: (state: any) => {
      return state.experiments.currentPublicExperiment;
     },

    getPublicExperimentSections: (parentId:any, id:any) => (state: any) => {
      //console.log("getPublicExperimentSections-> parentID:", parentId);
      //console.log("getPublicExperimentSections-> experID: ", id);
      //console.log("getPublicExperimentSections-> public: ", state.experiments.publicExperiments);
        //console.log("getPublicExperimentSections::", state.experiments.publicExperiments[parentId]);
        if ( state.experiments.publicExperiments[parentId]== null) return [];
        else
        return getExperimentById(state.experiments.publicExperiments[parentId],id).sections;
      },

      getPublicExperiment: (parentId:any, id:any) => (state: any) => {
        //console.log("getPublicExperiment-> parentID:", parentId);
        //console.log("getPublicExperiment-> experID: ", id);
        //console.log("getPublicExperiment-> public: ", state.experiments.publicExperiments);
        if ( state.experiments.publicExperiments[parentId]== null) return [];
          //console.log("getPublicExperiment::", state.experiments.publicExperiments[parentId]);
          return getExperimentById(state.experiments.publicExperiments[parentId],id);
        },

      getExperimentSections: (id:any) => (state: any) => {
        if (state.experiments.experiments==null) return [];

        for (let i=0;i<state.experiments.experiments.length;i++)
        {
          if (state.experiments.experiments[i].experiment==id) 
          return state.experiments.experiments[i].sections;
        }
          return [];
        },
   
    getExperiments: (state: any) => {
      return state.experiments.experiments;
    },


    getPublicClonableExperiments: (id:any) => (state: any) => {
      if (state.experiments.publicExperiments[id]==null) return [];
      return state.experiments.publicExperiments[id].filter((exp:any) => exp.clonable==true);
    },
  

    getPublicExperiments: (state: any) => {
      return state.experiments.publicExperiments;
    },

    getUploadedResourceUrl: (state:any) =>{
      return state.experiments.uploadedResourceUrl
    },

    getCurrentIDeviceParams: (state:any) => {
      return state.experiments.currentIDeviceParams
    },

    getPublicLiteTimelines: (state:any) => {
      return state.experiments.publicLiteTimelines
    }
  
  }